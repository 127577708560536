<template>
    <div>

        <div class="row justify-content-center mt-4" >
            <h1 class="tecnic-title">Coches en stock</h1>
        </div>
        <div class="row justify-content-center mt-4" >
            <button  class="bttn btn-primar"  @click="openNewCar()"> <font-awesome-icon icon="plus" class="mr-2"/>Nuevo coche</button>
        </div>
        <div class="row justify-content-center mt-4" >
            <input type="text" class="form-input mb-2" style="max-width: 300px" v-model="searchText" placeholder="Buscar..." @keyup="search" autocomplete="disable">
        </div>
        <div class="row justify-content-center">
            <div class="spinner-table"  id="spin">
                <img src="/logo-spinner.svg" alt="spinner"  id="nav-logo">
            </div>
        </div>

        <div class="row m-4" style="justify-content:right">
            <div class="col-lg-10 col-md-12 col-sm-12 text-center card card-shadow">
                <vuetable style="overflow-x: auto" ref="vuetable"
                        :css="css.table"
                        api-url="/api/cars/table/get"
                        :fields="fields"
                        data-path="data"
                        pagination-path="links.pagination"
                        :sort-order="sortOrder"
                        :show-sort-icons="true"
                        :per-page="10"
                        :append-params="moreParams"
                        :http-fetch="solveAuthIssuesOtherComponentsGet"
                        @vuetable:pagination-data="onPaginationData"
                        @vuetable:loading="onLoading"
                        @vuetable:loaded="onLoaded"
                        >
                        <div slot="chasis-slot" slot-scope="props">
                                <p>{{props.rowData.chassis_number }} </p>
                        </div>
                        <div slot="brand" slot-scope="props">
                                <p>{{props.rowData.brand }} </p>
                        </div>
                        <div slot="model" slot-scope="props">
                                <p>{{props.rowData.model }} </p>
                        </div>                          
                        <div slot="year" slot-scope="props">
                                <p>{{props.rowData.year }}</p>
                        </div>
                        
                        <div slot="edit" slot-scope="props">
                            <a class="hand" @click="getCarInfo(props.rowData.id, true)">
                                <font-awesome-icon v-if="loading_car==true && current_car_loading_id==props.rowData.id" icon="circle-notch" class="mr-2" spin />
                                <font-awesome-icon v-else icon="pen" class="mr-2" />                                
                            </a> 
                        </div>
                        <div slot="pub_web" slot-scope="props">
                            <a class="hand"  @click="switchAdvert(props.rowData)">
                              <div class="row justify-content-center">
                                <font-awesome-icon v-if="props.rowData.for_sale==false"  icon="plus" class="mr-2 green" />                           
                                <font-awesome-icon v-else  icon="trash" class="mr-2 red" />          
                                <p v-if="props.rowData.has_nft==true">
                                    <font-awesome-icon :icon="['fab', 'bitcoin']"   class=""/>
                                </p>       
                              </div>
                              
                            </a> 
                        </div>
                        <div slot="delete" slot-scope="props">
                            <a class="hand" @click="deleteCar(props.rowData.id)">
                                <font-awesome-icon v-if="deleting==true && delete_input==props.rowData.id" icon="circle-notch" class="mr-2" spin />
                                <font-awesome-icon v-else icon="trash" class="mr-2 red" />
                            </a> 
                        </div>                                                                     
                    </vuetable>
                    <vuetable-pagination-info ref="paginationInfo"
                    :info-template="pagination_info_template"
                    ></vuetable-pagination-info>

                    <vuetable-pagination ref="pagination"
                    :css="css.pagination"
                    @vuetable-pagination:change-page="onChangePage"
                    ></vuetable-pagination>
                <!--
                <div class="row justify-content-center pt-4" style="color: #2667F7;border-bottom: 1px solid #2667F7; font-weight: bold;">                  
                    <p class="col-lg-2 col-md-12 col-sm-12">Nºchasis</p>
                    <p class="col-lg-2 col-md-12 col-sm-12">Marca</p>                                    
                    <p class="col-lg-2 col-md-12 col-sm-12">Modelo</p>
                    <p class="col-lg-2 col-md-12 col-sm-12">Año</p>
                    <p class="col-lg-1 col-md-12 col-sm-12">Editar/Info</p>
                    <p class="col-lg-1 col-md-12 col-sm-12">Anunciado web</p>
                    <p class="col-lg-1 col-md-12 col-sm-12">Eliminar</p>
                    
                </div>        
                  <div v-for="car in cars" :key="car.chassis_number" class="row justify-content-center mt-2">
                        <p  class="col-lg-2 col-md-12 col-sm-12">{{car.chassis_number}}</p> 
                        <p  class="col-lg-2 col-md-12 col-sm-12">{{car.brand}}</p> 
                        <p  class="col-lg-2 col-md-12 col-sm-12">{{car.model}}</p> 
                        <p  class="col-lg-2 col-md-12 col-sm-12">{{car.year}}</p> 

                       
                        <a class="col-lg-1 col-md-12 col-sm-12 hand" @click="getCarInfo(car.id, true)">
                            <font-awesome-icon  icon="pen" class="mr-2" />                                
                        </a> 
                        <div class="col-lg-1 col-md-12 col-sm-12">
                            <a class="hand"  @click="switchAdvert(car)">
                                <font-awesome-icon v-if="car.for_sale==false"  icon="plus" class="mr-2 green" />                           
                                <font-awesome-icon v-else  icon="trash" class="mr-2 red" />                 
                            </a> 
                        </div>   
                        <a class="col-lg-1 col-md-12 col-sm-12 hand" @click="deleteCar(car.id)">
                            <font-awesome-icon v-if="deleting==true && delete_input==car.id" icon="circle-notch" class="mr-2" spin />
                            <font-awesome-icon v-else icon="trash" class="mr-2 red" />
                        </a> 
                    </div>  
                    -->
            </div>                  
        </div>        
            <b-modal id="testModal"  size="lg" hide-footer scrollable centered>
                            <template #modal-header>
                                <a class="x" @click="$bvModal.hide('testModal')"><font-awesome-icon icon="times" /></a> 
                                <div class="col text-center">
                                    <h3 class="bold m-0">Generar contrato de prueba de conducción</h3>
                                </div>                   
                            </template>
                            <div>
                                <p>Subir archivo de imágenes</p>
                                <div class="button-wrap">
                                    <input type="file" accept="*/*" id="image-file" class="mb-3"  @change="onCalculateHash">
                                    <p>Calculando... {{hash_calc_percent}}%</p>
                                    <p>{{file_hash}}</p>
                                </div>
                                <div class="row justify-content-center">
                                    <button class="bttn btn-primar ml-2"  @click="generactContract('test_drive')">
                                        <font-awesome-icon v-if="deleting==true" icon="circle-notch" class="mr-2" spin />
                                        <font-awesome-icon v-else icon="trash" class="mr-2 white" />
                                        Generar contrato
                                    </button>
                                </div>
                            </div>
            </b-modal>  
            <b-modal id="newCar"  size="lg" hide-footer scrollable centered>
                <template #modal-header>
                    <a class="x" @click="$bvModal.hide('newCar')"><font-awesome-icon icon="times" /></a> 
                    <div class="col text-center">
                        <h3 class="bold m-0">Nuevo coche</h3>
                    </div>                   
                </template>
                <div class="row">
                    <div class="col-12">
                       <p class="modal-label">Marca</p>
                        <p v-if="loading_brands==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                        <v-select v-else :options="brands" label="name" v-model="new_car.brand" @input="getModels(new_car.brand.id)">
                        </v-select> 
                       
                        <p class="modal-label">Modelo</p>
                        <p v-if="loading_models==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                        <v-select v-else :options="models" label="name"  v-model="new_car.model" @input="getEngines(new_car.model.id)">
                        </v-select> 

                        <p class="modal-label">Motor</p>
                        <p v-if="loading_engines==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                        <v-select v-else :options="engines" label="name"  v-model="new_car.engine"  @input="getVariants(new_car.engine.id)">
                        </v-select>

                        <p class="modal-label">Variante</p>
                        <p v-if="loading_variants==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                        <v-select v-else :options="variants" label="name" v-model="new_car.variant">
                        </v-select>

                        <p class="modal-label">Proveedor</p>
                        <p v-if="loading_providers==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                        <v-select v-else :options="providers" label="name" v-model="new_car.provider">
                        </v-select> 

                        <p class="modal-label">Kms</p>
                        <input class="form-input" type="text" v-model="new_car.kilometers">

                        <p class="modal-label">Año</p>
                        <input class="form-input" type="text" v-model="new_car.year">

                        <p class="modal-label">Nº chasis</p>
                        <input class="form-input" type="text" v-model="new_car.chassis_number">

                        <p class="modal-label">Precio</p>
                        <input class="form-input" type="text" v-model="new_car.price">
                        
                        <p class="modal-label">Matrícula</p>
                        <input class="form-input" type="text" v-model="new_car.plate_number">
                        
                    </div>
                </div>
                <div class="text-center mt-4">
                    <a class="bttn btn-primar ml-2"  @click="addCar()">Guardar</a>
                    <a class="bttn btn-grey ml-2"  @click="$bvModal.hide('newCar')">Cerrar</a>
                </div>
            </b-modal>
            <b-modal id="editCarModal"  size="xl" hide-footer scrollable centered>
                <template #modal-header>
                    <a class="x" @click="$bvModal.hide('editCarModal')"><font-awesome-icon icon="times" /></a> 
                    <div class="col text-center">
                    </div>    
                    <div class="col-lg-5 col-md-5 col-sm-12 text-center">
                        <h3 class="bold m-0">Información del coche</h3>
                    </div>    
                    <div class="co-lg-4 col-md-5 col-sm-12 text-center">   
                        <div  v-if="current_car.has_nft==false">
                            <button v-if="creating_crypto_add==true"  class="bttn btn-primar disabled" ><font-awesome-icon icon="circle-notch" class="mr-2" spin />Creando...</button>
                            <button v-else class="bttn btn-primar"  @click="createCryptoAd()">Crear NFT</button>
                        </div>
                        <div v-else> 
                            <div v-if="current_car.nft_info.ForSale==false">
                                <button v-if="switching_crypto_sale==true"  class="bttn btn-green disabled" ><font-awesome-icon icon="circle-notch" class="mr-2" spin />Activando...</button>
                                <button v-else class="bttn btn-green" @click="initSaleCrypto()">Activar venta NFT</button>
                            </div>
                            <div v-else>
                                <button v-if="switching_crypto_sale==true"  class="bttn btn-primar disabled" ><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cancelando...</button>
                                <button v-else class="bttn btn-red"  @click="stopSaleCrypto()">Cancelar venta NFT</button>
                            </div>    
                        </div>
                    </div>                
                </template>
                <div class="row">
                    <div class="col-lg-6 col-md-5 col-sm-12" >
                        <p class="modal-label">Asignar encargado</p>
                        <v-select :options="employees" label="name" v-model="current_car.employee_in_charge" @input="assignEmployee()">
                        </v-select> 
                    </div>
                </div>
                <div class="row" v-if="current_car.has_nft==true"  >
                    <div class="col-12">
                        <h3 class="mt-2 blue">Venta en cripto</h3>
                    </div>                                  
                    <div class="col-lg-6 col-md-8 col-sm-12">                       
                        <p class="modal-label">Precio NFT</p>
                        <input class="form-input" type="text" v-model="current_car.nft_price">
                    </div>       
                    <div class="col-lg-6 col-md-8 col-sm-12">
                        <button v-if="editing_nft_price==true"  class="bttn btn-green disabled addbtn" ><font-awesome-icon icon="circle-notch" class="mr-2" spin />Actualizando...</button>
                        <button v-else class="bttn btn-primar addbtn" @click="editNFTPrice()">Actualizar precio NFT</button>                    </div>             
                </div>     
                <div class="row">
                    <div class="col-12">
                        <h3 class="mt-2 blue">Reserva</h3>
                    </div>
                </div>
                <!--Eliminar reserva si está reservado según tenga NFT o no-->
                <div class="row"  v-if="current_car.reserved_db==true">
                    <div class="col-lg-6 col-md-5 col-sm-12" >
                        <p class="modal-label">Asignar cliente a la reserva</p>
                        <v-select :options="customers" label="name" v-model="current_car.booked_to" @input="bookCarTo()">
                        </v-select> 
                    </div>
                    <div class="col-lg-6 col-md-5 col-sm-12 text-center" v-if="current_car.has_nft==false">
                        <button v-if="reserving_car==true"  class="bttn btn-grey disabled addbtn" ><font-awesome-icon icon="circle-notch" class="mr-2" spin />Anulando reserva...</button>
                        <button v-else class="bttn btn-red addbtn" @click="switchBooked()">Eliminar reserva</button>
                    </div>
                    <div class="col-lg-6 col-md-5 col-sm-12 text-center" v-else>
                        <div  class="col-lg-4 col-md-5 col-sm-12">                        
                            <button v-if="booking_nft==true"  class="bttn btn-grey disabled addbtn" ><font-awesome-icon icon="circle-notch" class="mr-2" spin />Anulando reserva...</button>
                            <button v-else class="bttn btn-grey addbtn" @click="bookNft('0x0000000000000000000000000000000000000000')">Eliminar reserva</button>                        
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-5 col-sm-12 " >
                        <p class="modal-label">Documentos disponibles</p>
                        <button class="bttn btn-primar ml-2"  @click="openTestModal()">
                            <font-awesome-icon icon="trash" class="mr-2 white" />
                            Contrato prueba
                        </button>
                        <button class="bttn btn-primar ml-2"  @click="generactContract('pre_buy')">
                            <font-awesome-icon v-if="deleting==true" icon="circle-notch" class="mr-2" spin />
                            <font-awesome-icon v-else icon="trash" class="mr-2 white" />
                            Contrato compraventa
                        </button>                                            
                        <button class="bttn btn-primar ml-2"  @click="generactContract('govern')">
                            <font-awesome-icon v-if="deleting==true " icon="circle-notch" class="mr-2" spin />
                            <font-awesome-icon v-else icon="trash" class="mr-2 white" />
                            Contrato Govern
                        </button>
                        <button class="bttn btn-primar ml-2"  @click="genBudget(current_car)">
                            <font-awesome-icon v-if="presupuestando==true " icon="circle-notch" class="mr-2" spin />
                            <font-awesome-icon v-else icon="trash" class="mr-2 white" />
                            Presupuesto
                        </button>
                    </div>
                </div>
                <div class="row" v-else>
                    <div class="col-12" v-if="current_car.has_nft==false">
                        <button v-if="reserving_car==true"  class="bttn btn-grey disabled " ><font-awesome-icon icon="circle-notch" class="mr-2" spin />Reservando...</button>
                        <button v-else class="bttn btn-primar " @click="switchBooked()">Reservar</button>                        
                    </div>

                    <div class="col-12" v-if="current_car.has_nft==true">
                        <input class="form-input" type="text" v-model="current_car.nft_reserved">
                        <button v-if="booking_nft==true" class="bttn btn-primar ml-2 disabled addbtn"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Reservando...</button>
                        <button v-else class="bttn btn-primar ml-2 addbtn"  @click="bookNft(current_car.nft_reserved)"><font-awesome-icon icon="plus" class="mr-2"/>Reservar NFT</button>                     
                    </div>
                </div>                             
                <div class="col-12">
                    <h3 class="mt-2 blue">Información del coche</h3>
                </div>
                <div class="row">
                    <div class="col-6">
                        <p class="modal-label">Marcar como vendido</p>
                        <button v-if="setting_sold==true"  class="bttn btn-green disabled" ><font-awesome-icon icon="circle-notch" class="mr-2" spin />Vendiendo...</button>
                        <button v-else class="bttn btn-green"  @click="setAsSold(current_car)"><font-awesome-icon icon="money-bill-wave" class="mr-2"/>Vendido</button>
                                     

                        <p class="modal-label">Modelo</p>
                        <p >{{current_car.model.name}}</p>
                          <!--<p v-if="loading_models==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                       <v-select v-else :options="models" label="name" v-model="current_car.model" @input="getEngines(current_car.model.id)">
                        </v-select>-->

                        <p class="modal-label">Variante</p>
                        <p v-if="loading_variants==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                        <v-select v-else :options="variants" label="name" v-model="current_car.variant">
                        </v-select>
                    
                        <p class="modal-label">Año</p>
                        <input class="form-input" type="text" v-model="current_car.year">

                        <p class="modal-label">Precio web</p>
                        <input class="form-input" type="text" v-model="current_car.price">

                        <p class="modal-label">Matrícula</p>
                        <input class="form-input" type="text" v-model="current_car.plate_number">
                    </div>
                    <div class="col-6">
                       
                        <p class="modal-label">Marca</p>
                        <p>{{current_car.brand.name}}</p>


                        <p class="modal-label">Motor</p>
                        <p v-if="loading_engines==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                        <v-select v-else :options="engines" label="name"  v-model="current_car.engine"  @input="getVariants(current_car.engine.id)">
                        </v-select>

                        <p class="modal-label">Proveedor</p>
                        <p v-if="loading_providers==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                        <v-select v-else :options="providers" label="name" v-model="current_car.provider">
                        </v-select> 

                       

                        <p class="modal-label">Nº chasis</p>
                        <input class="form-input" type="text" v-model="current_car.chassis_number">

                        <p class="modal-label">Kilómetros</p>
                        <input class="form-input" type="text" v-model="current_car.kilometers">

                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <h3 class="mt-2 blue">Español</h3>
                        <p class="modal-label">Título anuncio</p>
                        <input class="form-input" type="text" v-model="current_car.title.es">

                        <p class="modal-label">Descripción</p>
                        <textarea class="form-input" type="text" v-model="current_car.description.es">  </textarea>
                        <button v-b-toggle.collapse-1 class="bttn btn-primar"> Otros idiomas<font-awesome-icon icon="chevron-down" class="ml-2 mt-1"/></button>
                        <button v-if="translating" class="bttn btn-second disabled"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Traduciendo</button>
                        <button v-else class="bttn btn-second ml-4"  @click="translate_from_spanish()"> Traducir descripción</button>

                            <b-collapse id="collapse-1" class="collapse  p-4"  style="border: 2px lightgrey solid;">
                            <h3 class="mt-2 blue">Inglés</h3>
                            <p class="modal-label">Nombre</p>
                            <input class="form-input" type="text" id="default_edit" name="default" v-model="current_car.title.en">
                            <p  class="modal-label">Descripción</p>
                            <textarea rows="3"  class="form-input" v-model="current_car.description.en"></textarea>

                            <h3 class="mt-2 blue">Catalán</h3>
                            <p class="modal-label">Nombre</p>
                            <input class="form-input" type="text" id="default_edit" name="default" v-model="current_car.title.cat">
                            <p  class="modal-label">Descripción</p>
                            <textarea rows="3"  class="form-input" v-model="current_car.description.cat"></textarea>

                            <h3 class="mt-2 blue">Francés</h3>
                            <p class="modal-label">Nombre</p>
                            <input class="form-input" type="text" id="default_edit" name="default" v-model="current_car.title.fr">
                            <p  class="modal-label">Descripción</p>
                            <textarea rows="3"  class="form-input" v-model="current_car.description.fr"></textarea>
                        </b-collapse>    
                    </div>
                    <div class="col-12 mt-2">                       
                        <h3 class="blue">Equipamiento</h3>
                        <p class="modal-label">De serie</p>
                        <div class="row">
                            <div class="col-lg-9 col-md-5 col-sm-12 p-0">
                                <p v-if="loading_defaults==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                                <v-select v-else :options="default_eq" label="name" v-model="selected_defualt_eq">
                                </v-select> 
                            </div>
                            <div class="col-lg-3 col-md-5 col-sm-12">
                                <button v-if="adding_default==true" class="bttn btn-green ml-2 disabled"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Añadir</button>
                                <button v-else class="bttn btn-green ml-2"  @click="addDefault(selected_defualt_eq.id)"><font-awesome-icon icon="plus" class="mr-2"/>Añadir</button>
                            </div>
                        </div>
                        <div class="row justify-content-center mt-4">                                                     
                            <div class="col-lg-4 col-md-5 col-sm-12 text-center mb-4" v-for="each in current_car.default_eq" :key="each.url">
                                <div class="card card-shadow black extra-card">
                                    <img v-if="each.pictures[0]!=undefined" class="extra-img" :src="each.pictures[0].url" alt="car">
                                    <div class="card-img-overlay overlay-extra extra-img">
                                        <h2 class="overlay-text">{{each.name[$i18n.locale]}}</h2>             
                                    </div>
                                </div>
                                <a v-if="deleting_default==true && delete_default_spin==each.id_equip" class="disabled" >
                                    <font-awesome-icon  icon="circle-notch" class="mr-2 " spin />
                                </a> 
                                <a  v-else class="hand" @click="deleteDefault(current_car.id, each.id_from_car, each.id_equip)">
                                    <font-awesome-icon icon="trash" class="mr-2 red" />
                                </a> 
                            </div>
                        </div>
                        
                        <p class="modal-label">Extra</p>

                        <div class="row">
                            <div class="col-lg-9 col-md-5 col-sm-12 p-0">
                                <p v-if="loading_extras==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                                <v-select v-else :options="extra_eq" label="name" v-model="selected_extra_eq">
                                </v-select> 
                            </div>
                            <div class="col-lg-3 col-md-5 col-sm-12">
                                <button v-if="adding_extra==true" class="bttn btn-green ml-2 disabled"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Añadir</button>
                                <button v-else class="bttn btn-green ml-2"  @click="addExtra(selected_extra_eq.id)"><font-awesome-icon icon="plus" class="mr-2"/>Añadir</button>
                            </div>
                        </div>
                        <div class="row justify-content-center mt-4" v-if="current_car.pictures.min_imgs.front!=[]">                                                     
                            <div class="col-lg-4 col-md-5 col-sm-12 text-center mb-4" v-for="each in current_car.extra_eq" :key="each.id">
                                <div class="card card-shadow black extra-card">
                                    <img class="extra-img" v-if="each.pictures[0]!=undefined" :src="each.pictures[0].url" alt="car">
                                    <div class="card-img-overlay overlay-extra extra-img">
                                        <h2 class="overlay-text">{{each.name[$i18n.locale]}}</h2>                       
                                    </div>
                                </div>
                                <a v-if="deleting_default==true && delete_extra_spin==each.id_equip" class="disabled" >
                                    <font-awesome-icon  icon="circle-notch" class="mr-2 " spin />
                                </a> 
                                <a  v-else class="hand" @click="deleteExtra(current_car.id, each.id_from_car, each.id_equip)">
                                    <font-awesome-icon icon="trash" class="mr-2 red" />
                                </a> 
                            </div>
                        </div>
                        <h3 class="blue">Imágenes del coche</h3>

                        <div class="row mt-4">
                            <!--<div class="button-wrap">
                                <input type="file" accept="image/*" id="image-file" class="mb-3"  @change="onChange">
                            </div>
                            <button v-if="this.file!='' && uploadingPic==true && uploadPercentage<=100" class="btn btn-small btn-primar disabled m-1">Subiendo...{{uploadPercentage}}%</button>
                            <button v-if="this.file!=''  && uploadingPic==false" @click="bulkuploadImagesCar()" class="btn btn-small btn-primar hand" >Subir imágenes</button> -->
                            <div class="button-wrap">
                                <input type="file" ref="fileInput" multiple >
                            </div>
                            <button v-if="!uploading" class="btn btn-small btn-primar hand" @click="bulkuploadImagesCar()">Upload</button>
                            <button v-else class="btn btn-small btn-primar disabled m-1">{{ progressPercent }}%</button>
                       
                        </div>
                        <div class="row" >
                            <div class="col-lg-4 col-md-4 col-sm-12 text-center"  v-for="image in current_car.pictures.min_imgs.front" :key="image.url">
                                <p class="modal-label">Portada</p>
                                <img :src="image.url" alt="Avatar" class="image">
                                <br>
                                <a @click="deleteImg(image.img_name)" class="hand">
                                    <font-awesome-icon v-if="deleting_img==true && img_deleting_name==image.img_name" icon="circle-notch" class="mr-2" spin />
                                    <font-awesome-icon v-else icon="trash" class="mr-2" style="color:red" />
                                </a>
                            </div> 
                        </div>
                        <p v-if="current_car.pictures.min_imgs.others!=[]" class="modal-label">Galeria de imágenes</p>
                        <div class="row" >                           
                            <div class="col-lg-4 col-md-4 col-sm-12 text-center" v-for="image in current_car.pictures.min_imgs.others" :key="image.url">                                
                                <img :src="image.url" alt="Avatar" class="image mb-2">
                                <br>
                                <a @click="deleteImg(image.img_name)" class="hand">
                                    <font-awesome-icon v-if="deleting_img==true && img_deleting_name==image.img_name" icon="circle-notch" class="mr-2" spin />
                                    <font-awesome-icon v-else icon="trash" class="mr-2" style="color:red" />
                                </a>
                                <a  @click="setFrontImg(image.img_name)" class="btn btn-primar btn-small">
                                    Portada
                                </a>
                            </div>     
                        </div>             
                        <p class="modal-label">360º exterior</p> 
                        <div class="row" >                                                      
                            <div class="button-wrap">
                                <input type="file"  id="image-file" class="mb-3"  @change="onChange">
                            </div>
                            <button v-if="this.file!='' && uploadingPic360==true && uploadPercentage360<=100" class="btn btn-small btn-primar disabled m-1">Subiendo...{{uploadPercentage}}%</button>
                            <button v-if="this.file!='' && uploadingPic360==false" @click="upload360OutFile()" class="btn btn-small btn-primar hand" >Subir archivo zip</button>     
                            <a @click="delete360Imgs()" class="hand">
                                <font-awesome-icon v-if="deleting_360_img==true" icon="circle-notch" class="mr-2" spin />
                                <font-awesome-icon v-else icon="trash" class="mr-2" style="color:red" />
                            </a>                  
                        </div>              
                        <div>
                            <vue-three-sixty ref="modal" v-if="current_car.viewer_img_out_file.num_imgs>0"
                                :amount=current_car.viewer_img_out_file.num_imgs
                                :imagePath=current_car.viewer_img_out_file.path
                                :fileName=current_car.viewer_img_out_file.img_name
                                scrollImage
                            >
                            </vue-three-sixty>
                        </div>
                    </div>
                </div>
                <div class="text-center mt-4">
                    <a class="bttn btn-primar ml-2"  @click="editCar()">Guardar</a>
                    <a class="bttn btn-grey ml-2"  @click="$bvModal.hide('editCarModal')">Cerrar</a>
                </div>
            </b-modal>    
     
    </div>
</template>

<script>
    var CryptoJS = require("crypto-js");
    import { ethers, utils } from 'ethers'
    import { serializeError } from "eth-rpc-errors";
    import axios from "axios"
    const car_collection=require('../../contracts_abis/abi_car_collection.json');

    import _ from 'underscore'
    const contracts_addresses =require('../../contracts_addresses.json')
    const AddressCarCollection=process.env.VUE_APP_ADDRESS_CAR_COLLECTION
    import Vuetable from 'vuetable-2'

    import VuetablePagination from 'vuetable-2/src/components/VuetablePagination.vue'
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue'


    
        export default {
            name: "stock",
            components: {
                'vuetable':Vuetable,
                'vuetable-pagination': VuetablePagination,
                'vuetable-pagination-info':VuetablePaginationInfo
            },
            data() {
                return {
                    translating:false,
                    progressPercent:0,
                    uploading: false,
                    presupuestando:false,
                    setting_sold:false,
                    hash_calc_percent:0,
                    file_hash:undefined,
                    images_zip:undefined,
                    calculating_imgs_hash:false,
                    editing_nft_price:false,
                    brands:[],
                    models:[],
                    engines:[],
                    variants:[],
                    providers:[],
                    default_eq:[],
                    extra_eq:[],
                    loading_brands:false,
                    loading_models:false,
                    loading_variants:false,
                    loading_engines:false,
                    loading_cars:false,
                    loading_car:false,
                    current_car_loading_id:undefined,
                    loading_providers:false,
                    loading_defaults:false,
                    loading_extras:false,
                    loading_clients:false,
                    reserving_car:false,
                    new_car:{
                        'brand':{},
                        'model':{},
                        'engine':{},
                        'variant':{},
                        'provider':'',
                        'year':'',
                        'chassis_number':'',
                        'price':'',
                        'for_sale':false
                    },
                    current_car:{
                        'brand':{},
                        'model':{},
                        'engine':{},
                        'variant':{},
                        'year':'',
                        'chassis_number':'',
                        'price':'',
                        'provider':'',
                        'for_sale':undefined,
                        'for_sale_in_crypto':false,
                        'default_eq':[],
                        'extra_eq':[],
                        'pictures':{
                            'full_imgs':[],
                            'min_imgs':[]
                        },
                        'description': {"es":"", "cat":"", "en":"","fr":"" }, 
                        'title':{"es":"", "cat":"", "en":"", "fr":""},
                        'has_nft':false,
                        'nft_info':{},
                        'nft_price':0,
                        'viewer_img_out_file':{"img_name":"","num_imgs":0, "path":""},
                        
                    },
                    selected_defualt_eq:{"name":"", "id":undefined},
                    selected_extra_eq:{"name":"", "id":undefined},
                    editing:false,
                    adding:false,
                    adding_default:false,
                    adding_extra:false,
                    //employee:undefined,
                    employees:[],
                    deleting:false,
                    deleting_360_img:false,
                    deleting_default:false,
                    deleting_extra:false,
                    delete_default_spin:undefined,
                    delete_extra_spin:undefined,
                    uploadingPic:false,
                    file:undefined,
                    deleting_img:false,
                    uploadPercentage:0,
                    img_deleting_name:undefined,
                    pictures:[],
                    status:undefined,
                    uploadingPic360:false,
                    uploadPercentage:0,
                    uploadPercentage360:0,
                    number_images:16,
                    advertise:false,
                    creating_crypto_add:false,
                    switching_crypto_sale:false,
                    booking_nft:false,
                    adding_booking:false,
                    loading_360_out:false,
                    currentScale:1,
                    activeImage:1,
                    generating_pre_contract:false,
                    pagination_info_template: "Mostrando coches del {from} a {to} de {total}",
                    moreParams: {"sold_status":false},
                    searchText: "",
                    fields: [
                        {
                            name: 'chasis-slot',
                            title: 'Nºchasis',
                            sortField: 'chasis_number'
                        },
                        {
                            name: 'brand',
                            title: 'Marca',
                            sortField: 'brand'
                        },
                        {
                            name: "model",
                            title: 'Modelo',
                            sortField: 'model'
                        },
                        {
                            name: "year",
                            title: 'Año',
                            sortField: 'year'
                        },
                        {
                            name: "edit",
                            title: 'Editar/Info',
                        },
                        {
                            name: "pub_web",
                            title: 'Anunciado web',
                        },
                        {
                            name: "delete",
                            title: 'Eliminar',
                        },
                    ],
                    sortOrder: [
                        {field: 'chassis_number', direction: 'desc'},
                        {field: 'brand', direction: 'desc'},
                        {field: 'model', direction: 'desc'},
                        {field: 'year', direction: 'desc'}
                    ],
                    css: {
                        table: {
                            tableClass: 'table table-hovered',
                            loadingClass: 'loadingContent',
                            ascendingIcon: 'fa fa-sort-up',
                            descendingIcon: 'fa fa-sort-down',
                            ascendingClass: 'sorted-asc',
                            descendingClass: 'sorted-desc',
                            sortableIcon: 'fa fa-sort',
                            handleIcon: 'fa fa-bars',
                        },
                        pagination: {
                            infoClass: 'pull-left',
                            wrapperClass: 'vue-pagination',
                            activeClass: 'active',
                            disabledClass: 'disabled',
                            pageClass: 'btn btn-page',
                            linkClass: 'btn btn-arrow ',
                            icons: {
                                first: '',
                                prev: '',
                                next: '',
                                last: '',
                            },
                        }
                    },
                    styles: {
                        backgroundColor: this.color,
                        width: this.width + 'px',
                        height: this.height + 'px',
                    },

                }
            },
            methods:{
                
                async genBudget(car){
                    console.log('car',car)
                    this.presupuestando=true
                    
                    axios.post('/api/cars/invoices/gen_budget', { 
                        "car_id": car.id,
                    })
                        .then((response) => {
                            this.status=response.data
                            if (this.status.resultado == "Ok") {
                                let b64 = response.data.Budget
                                //console.log(b64)
                                let link = document.createElement('a');
                                link.innerHTML = 'Descargar presupuesto';
                                link.download = response.data.Filename;
                                link.href = 'data:application/octet-stream;base64,' + b64;
                                //document.body.appendChild(link);
                                link.click()                                
                                this.presupuestando=false
                                //this.getAllCars()
                                this.popToast("success", this.status.mensaje, "Correcto")
                            }
                            else{
                                this.presupuestando=false
                                this.popToast("danger", this.status.mensaje, "Error")
                            }
                            }).catch((error) => {
                                this.presupuestando=false
                                console.log(error);
                        });
                },

                getBrands(){
                    this.loading_brands=true;
                    axios.get('/api/brands/get')
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.brands=result.data.brands
                            console.log("brands:",this.brands)
                            this.loading_brands=false;
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.loading_brands=false;
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.loading_brands=false;
                    });
                },
                getModels(brand_id){
                    this.loading_models = true;       
                    axios.post('/api/brands/get_models', {
                        "brand_id":brand_id
                    })
                        .then((result) => {
                            if (result.data.resultado === "Ok"){
                                this.models=result.data.models
                                console.log(result.data.models)
                                this.loading_models = false;
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                                this.loading_models = false;
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.loading_models = false;                        
                        });
                },
                getEngines(model_id){
                    this.loading_engines = true;         
                    axios.post('/api/models/get_engines', {
                        "model_id":model_id
                    })
                        .then((result) => {
                            console.log(result)
                            if (result.data.resultado === "Ok"){
                                this.engines=result.data.engines
                                console.log(result.data.engines)
                                this.loading_engines = false;
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                                this.loading_engines = false;
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.loading_engines = false;                        
                        });
                },
                getVariants(engine_id){
                    this.loading_variants = true;         
                    axios.post('/api/engines/get_variants', {
                        "engine_id":engine_id
                    })
                    .then((result) => {
                        console.log(result)
                        if (result.data.resultado === "Ok"){
                            this.variants=result.data.variants
                            console.log(result.data.variants)
                            this.loading_variants = false;
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.loading_variants = false;
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.loading_variants = false;                        
                    });
                },

                getCustomers(){
                    this.loading_customers=true
                    axios.post('/api/customers/get_all')
                        .then((result) => {
                            console.log("result",result)
                            if (result.data.resultado === "Ok"){
                                this.customers=result.data.customers
                                console.log("customers:",this.customers)
                                this.loading_customers=false
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                                this.loading_customers=false
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.loading_customers=false
                        });
                },

                getEmployees(){
                    axios.post('/api/employees/get_all')
                        .then((result) => {
                            console.log("result",result)
                            if (result.data.resultado === "Ok"){
                                this.employees=result.data.employees
                                console.log("employees:",this.employees)
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                            }
                        }).catch((error) => {
                            console.log(error);
                        });
                },
                assignEmployee(){
                    axios.post('/api/employees/assign', {
                        'employee_id':this.current_car.employee_in_charge.id,
                        'car_id':this.current_car.id,
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },
                getProviders(){
                    this.loading_providers=true
                    axios.post('/api/providers/get_all')
                        .then((result) => {
                            console.log("result",result)
                            if (result.data.resultado === "Ok"){
                                this.providers=result.data.providers
                                console.log("providers:",this.providers)
                                this.loading_providers=false
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                                this.loading_providers=false
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.loading_providers=false
                        });
                },
                getDefaultEq(brand_id){
                    this.loading_defaults=true
                    axios.post('/api/default_equipments/get_all_from_brand',{
                            'brand_id': brand_id,
                        }) 
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.default_eq=result.data.def_equipments
                            this.loading_defaults=false
                            console.log("def_equipments:",this.default_eq)
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.loading_defaults=false
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.loading_defaults=false
                    });
                },
                getExtraEq(brand_id){
                    this.loading_extras=true
                    axios.post('/api/extra_equipments/get_all_from_brand',{
                            'brand_id': brand_id,
                        }) 
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.extra_eq=result.data.extra_equipments
                            //this.filtered_extra_eq=result.data.extra_equipments
                            console.log("extra_equipments:",this.extra_eq)
                            this.loading_extras=false
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.loading_extras=false
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.loading_extras=false
                    });
                },
              
                /*getAllCarsStock(){
                    this.loading_cars=true
                    axios.get('/api/cars/get_stock')
                        .then((result) => {
                            console.log("result",result)
                            if (result.data.resultado === "Ok"){
                                this.cars=result.data.cars
                                console.log("cars:",this.cars)
                                this.loading_cars=false
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                                this.loading_cars=false
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.loading_cars=false
                        });
                },*/
                addCar(){
                    this.adding=true,
                    axios.post('/api/cars/add', {
                        'brand_id':this.new_car.brand.id,
                        'model_id':this.new_car.model.id,
                        'engine_id':this.new_car.engine.id,
                        'variant_id':this.new_car.variant.id,
                        'provider_id':this.new_car.provider.id,
                        'year':this.new_car.year,
                        'chassis_number':this.new_car.chassis_number,
                        'price':this.new_car.price,
                        'kilometers':this.new_car.kilometers,
                        'plate_number':this.new_car.plate_number
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.$nextTick(() => this.$refs.vuetable.refresh())
                            this.adding=false;
                            this.new_car={
                                'brand':{"id":undefined},
                                'model':{"id":undefined},
                                'engine':{"id":undefined},
                                'variant':{"id":undefined},
                                'provider':{"id":undefined},
                                'year':'',
                                'chassis_number':'',
                                'plate_number':'',
                                'price':''
                            }
                            this.$bvModal.hide('newCar')
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.adding=false;
                            
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },
                editCar(){
                    this.editing=true
                    axios.post('/api/cars/edit_car_stock', {
                        'car_id':this.current_car.id,
                        'engine_id':this.current_car.engine.id,
                        'variant_id':this.current_car.variant.id,
                        'provider_id':this.current_car.provider.id,
                        'year':this.current_car.year,
                        'chassis_number':this.current_car.chassis_number,
                        'price':this.current_car.price,
                        'description':this.current_car.description,
                        'title':this.current_car.title,
                        'kilometers':this.current_car.kilometers,
                        'plate_number':this.current_car.plate_number
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.$nextTick(() => this.$refs.vuetable.refresh())
                            //this.getAllCarsStock()                            
                            this.editing=false
                            this.$bvModal.hide('editCarModal')
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.editing=false;
                            
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },

                deleteCar(car_id){
                    this.deleting=true,
                    this.delete_input=car_id
                    axios.post('/api/cars/delete', {
                        "car_id": car_id,

                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.$nextTick(() => this.$refs.vuetable.refresh())
                            //this.getAllCarsStock()                            
                            this.deleting=false
                            this.delete_input=undefined
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.deleting=false;
                            
                        }
                    }).catch((error) => {
                        console.log(error);
                    });

                },
                openNewCar(){
                    //this.default_editing=extra_equip
                   this.new_car={
                        'brand':{},
                        'model':{},
                        'engine':{},
                        'variant':{},
                        'provider':'',
                        'year':'',
                        'chassis_number':'',
                        'price':'',
                        'for_sale':false
                    }
                    this.$bvModal.show('newCar')
                },

                switchBooked(){
                    axios.post('/api/cars/switch_reserved', {
                        "car_id":this.current_car.id
                    })
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.current_car.reserved_db=result.data.reserved_status
                            if (this.current_car.reserved_db==false){
                                this.current_car.booked_to={"name":null, "id":null, "id_number":null}
                            }
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },

                bookCarTo(){ 
                    axios.post('/api/cars/book_to', {
                        "car_id":this.current_car.id, 
                        "customer_id": this.current_car.booked_to.id
                    })
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            //this.getCarInfo(this.current_car.id, false)
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },

                openTestModal(){
                    this.$bvModal.show('testModal')                   
                },
                
                generactContract(type){//arturo
                    this.generating_pre_contract=true,
                    axios.post('/api/cars/contracts/get', {
                        'car_id':this.current_car.id,
                        'type': type,
                        'imgs_hash':this.file_hash
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            let b64 = result.data.Invoice
                            //console.log(b64)
                            let link = document.createElement('a');
                            link.innerHTML = 'Descargar factura';
                            link.download = result.data.Filename;
                            link.href = 'data:application/octet-stream;base64,' + b64;
                            //document.body.appendChild(link);
                            link.click()                                
                            this.downloading=false
                            this.idFacturaCargando=""
                            //this.getAllCars()
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.generating_pre_contract=false;                            
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.generating_pre_contract=false;
                            
                        }
                    }).catch((error) => {
                        console.log(error);
                    });             
                    this.generating_pre_contract=false
                },

                switchAdvert(car){
                    console.log("car", car)
                    axios.post('/api/cars/switch_ad', {
                        "car_id":car.id
                    })
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            if(car.for_sale==true){
                                car.for_sale=false
                            }
                            else{
                                car.for_sale=true
                            }
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },
                
                async getCarInfo(id, open){
                    let vm=this
                    this.loading_car=true
                    this.current_car_loading_id=id
                    console.log("this.loading_car, this.current_car_loading_id",this.loading_car, this.current_car_loading_id)
                    axios.post('/api/cars/get_car_info', {
                        "car_id":id
                    })
                    .then(async (result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            vm.current_car=result.data.car_info
                            
                            vm.getEngines(vm.current_car.model.id)
                            
                            console.log('current car',vm.current_car)
                            if (vm.current_car.has_nft==true){
                                console.log("getting info car id nft", vm.current_car.collection_id)
                                vm.current_car.nft_info=await vm.car_collection_contract_read_only.Cars(vm.current_car.collection_id)
                                vm.current_car.nft_price=vm.current_car.nft_info.Price.toNumber()
                                vm.current_car.nft_reserved=vm.current_car.nft_info.Reserved
                                console.log("nft_info nft_info:", vm.current_car.nft_info)
                            }

                            vm.loading_car=false
                            vm.current_car_loading_id=undefined

                            if (open==true){
                                vm.getDefaultEq(this.current_car.brand.id)
                                vm.getEmployees()
                                vm.getExtraEq(this.current_car.brand.id)
                                //this.get360imgs()
                                vm.$bvModal.show('editCarModal')  
                                await vm.sleep(2000)
                                vm.$refs.modal.resetPosition()
                            }
                            
                             
                        }
                        else if (result.data.resultado === "Error"){
                            vm.popToast("danger", result.data.mensaje, "Error")
                            vm.loading_car=false
                            vm.current_car_loading_id=undefined
                        }
                        this.$forceUpdate()
                    }).catch((error) => {
                        console.log(error);
                        vm.loading_car=false
                        vm.current_car_loading_id=undefined
                    });
                },
                

                addDefault(id){
                    this.adding_default=true,                   
                    axios.post('/api/cars/equipment/add_default', {
                        "car_id":this.current_car.id,
                        "equip_id":id,
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            //this.getDefaultEq(this.current_car.brand.id)
                            this.adding_default=false;                        
                            this.getCarInfo(this.current_car.id, false)
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.adding_default=false;
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.adding_default=false;
                    });
                },
                addExtra(id){
                    this.adding_extra=true,
                    axios.post('/api/cars/equipment/add_extra', {
                        "car_id":this.current_car.id,
                        "equip_id":id,
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")                           
                            this.getCarInfo(this.current_car.id, false)
                            this.adding_extra=false;
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.adding_extra=false;
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.adding_extra=false;
                    });
                },

                async setAsSold(car){
                    this.setting_sold=true
                    let vm=this
                    if(car.has_nft==true){
                        if (car.has_nft==true){
                            try{
                                //if nft is for sale, first remove nft from sale to avoid someone uys on chain an already sold car
                                //belen -> hacer incapie en que si marcan el coche como vendido ya no podrán ponerlo a la venta de nuevo con el mismo NFT
                                let result_set_as_sold = await vm.car_collection_contract.functions.removeCarFromSale(vm.current_car.collection_id, true)
                                console.log("miauuuuuuuu", result_set_as_sold)
                                let resultado= await vm.handle_tx_confirmation(result_set_as_sold)
                                console.log(resultado)
                                vm.popToast("success", "NFT marcado como vendido")
                            }catch(error){
                                const serializedError = serializeError(error);
                                vm.handle_metamask_rpc_error(serializedError)
                                vm.setting_sold=false
                                return("error")
                            }
                        }
                    }
                    axios.post('/api/cars/set_sold', {
                        "car_id":car.id
                    })
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.$nextTick(() => this.$refs.vuetable.refresh())
                            //this.getAllCarsStock()
                            this.setting_sold=false
                            this.$bvModal.hide('editCarModal')
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.setting_sold=false
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.setting_sold=false
                    });

                },
                deleteDefault(car_id,equip_id_from_car, extra_id){
                    this.deleting_default=true,
                    this.delete_default_spin=extra_id
                    axios.post('/api/cars/equipment/del_default', {
                        "car_id":car_id,
                        "equip_id_from_car": equip_id_from_car,
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.getCarInfo(this.current_car.id, false)                         
                            this.deleting_default=false
                            this.delete_default_spin=undefined
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.deleting_default=false;
                            
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.deleting_default=false;
                    });
                },
                deleteExtra(car_id,equip_id_from_car, extra_id){
                    this.deleting_extra=true,
                    this.delete_extra_spin=extra_id
                    axios.post('/api/cars/equipment/del_extra', {
                        "car_id":car_id,
                        "equip_id_from_car": equip_id_from_car,
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.getCarInfo(this.current_car.id, false)                         
                            this.deleting_extra=false
                            this.delete_extra_spin=undefined
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.deleting_extra=false
                            
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.deleting_extra=false
                    });
                },
                onChange(e) {
                    this.file = e.target.files[0]
                },
                
                startUpload() {
                    this.$refs.fileInput.click();
                },

                async bulkuploadImagesCar() {
                    this.uploadingPic = true;
                    let vm=this
                    //const URL = '/api/cars/pictures/add'; 
                    const files = this.$refs.fileInput.files;
                   
                    // Create a FormData object and append the files to it
                    const formData = new FormData();
                    formData.append('car_id', this.current_car.id)
                    for (let i = 0; i < files.length; i++) {
                        formData.append('files[]', files[i]);
                    }
                    
                    // Send the files to the server using Axios
                    this.uploading = true;
                    const config = {
                        headers: { 'Content-Type': 'multipart/form-data' },
                        timeout: 6000000,
                        onUploadProgress: (progressEvent) => {
                            if (Math.round((progressEvent.loaded * 100) / progressEvent.total)==100){
                                vm.progressPercent = "Procesando..."
                            }
                            else{
                                vm.progressPercent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                            }
                            
                        },
                    };
                    
                    try {
                        const response = await axios.post('/api/cars/pictures/add', formData, config);
                        const status=response.data.resultado                          
                        const mensaje=response.data.mensaje
                        if (status=="Ok") {
                            vm.uploadingPic = false;
                            vm.uploadPercentage=0
                            vm.popToast("success", mensaje, "Correcto")
                            vm.getCarInfo(vm.current_car.id, false)      
                        }
                        else{
                            vm.uploadingPic = false;
                            vm.uploadPercentage=0
                            vm.popToast("danger", mensaje, "Error")
                        }

                        // Handle the server response here
                        //console.log(response.data);
                        this.progressPercent=0
                    } catch (error) {
                        // Handle the error here
                        console.error(error);
                        vm.popToast("danger", "Excepción al subir archivos", "Error")
                    } finally {
                        this.uploading = false;
                        this.progressPercent=0
                    }
                },

                /*uploadImagesCar() {
                    this.uploadingPic = true;
                    let vm=this
                    const URL = '/api/cars/pictures/add'; 
                    let data = new FormData();
                   
                    data.append('file', this.file);
                    data.append('car_id', this.current_car.id)
                    let config = {
                            header : {
                                'Content-Type' : 'image/png'
                            },
                            onUploadProgress: function( progressEvent ) {
                                this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                            }.bind(this)
                        };
                    axios.post(URL,data,config)
                        .then(function(res) {
                            var status=res.data.resultado                          
                            vm.mensaje=res.data.mensaje
                            if (status=="Ok") {
                                vm.uploadingPic = false;
                                vm.uploadPercentage=0
                                vm.popToast("success", vm.mensaje, "Correcto")
                                vm.getCarInfo(vm.current_car.id, false)      
                            }
                            else{
                                vm.uploadingPic = false;
                                vm.uploadPercentage=0
                                vm.popToast("danger", vm.mensaje, "Error")
                                //vm.closeCamera()
                            }
                        }).catch(function(err) {
                            console.log(err)
                            vm.uploadingPic = false;
                            vm.uploadPercentage=0
                            vm.popToast("danger", vm.mensaje, "Error")
                            //vm.closeCamera()
                        })
                },*/
                delete360Imgs(){
                    this.deleting_360_img=true
                    axios.post('/api/cars/pictures360/delete',{
                            'car_id': this.current_car.id,
                        }) 
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.pictures=result.data.images
                            this.deleting_360_img=false
                            //console.log("getPictures:",this.pictures)
                            this.getCarInfo(this.current_car.id, false)
                            this.popToast("success", result.data.mensaje, "Correcto")
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.deleting_360_img=false
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.deleting_360_img=false
                    });
                },
               
                deleteImg(img_name){
                    this.img_deleting_name=img_name
                    this.deleting_img=true
                    axios.post('/api/cars/pictures/delete',{
                            'img_name': img_name,
                        }) 
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.pictures=result.data.images
                            this.deleting_img=false
                            this.img_deleting_name=undefined
                            //console.log("getPictures:",this.pictures)
                            this.getCarInfo(this.current_car.id, false)       
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.deleting_img=false
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.deleting_img=false
                    });
                },
                setFrontImg(img_name){
                    axios.post('/api/cars/pictures/set_front',{
                            'img_name': img_name,
                        }) 
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.pictures=result.data.images
                            this.popToast("success", result.data.mensaje, "Correcto")
                            this.getCarInfo(this.current_car.id, false)
                            //console.log("getPictures:",this.pictures)
                            //this.getPictures()
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },
                upload360OutFile(){
                    this.uploadingPic360 = true;
                    let vm=this
                    const URL = '/api/cars/pictures360/add'; 
                    let data = new FormData();
                   
                    data.append('file', this.file);
                    data.append('car_id', this.current_car.id)
                    let config = {
                            header : {
                                'Content-Type' : 'file'
                            },
                            onUploadProgress: function( progressEvent ) {
                                this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                            }.bind(this)
                        };
                    axios.post(URL,data,config)
                        .then(function(res) {
                            var status=res.data.resultado                          
                            vm.mensaje=res.data.mensaje
                            if (status=="Ok") {
                                vm.uploadingPic360 = false;
                                vm.uploadPercentage360=0
                                vm.popToast("success", vm.mensaje, "Correcto")
                                vm.getCarInfo(vm.current_car.id, false)
                                //vm.getCarInfo(vm.current_car.id, false)      
                            }
                            else{
                                vm.uploadingPic360 = false;
                                vm.uploadPercentage360=0
                                vm.popToast("danger", vm.mensaje, "Error")
                                //vm.closeCamera()
                            }
                        }).catch(function(err) {
                            console.log(err)
                            vm.uploadingPic360 = false;
                            vm.uploadPercentage360=0
                            vm.popToast("danger", vm.mensaje, "Error")
                            //vm.closeCamera()
                        })
                },          
                
                    /*vue table function */

                onPaginationData (paginationData) {
                    this.$refs.pagination.setPaginationData(paginationData)
                    this.$refs.paginationInfo.setPaginationData(paginationData)
                    let pagina_Actual= (this.$refs.paginationInfo.tablePagination.current_page)
                    //añadimos eliminamos clase active para resalta pagina actual
                    this.sleep(100).then(() => {
                        let elemento_paginacion = document.getElementsByClassName("vue-pagination")
                        let tags = elemento_paginacion[0].getElementsByTagName("a")
                        this.sleep(2000)   

                        for (let item of tags) {
                            if (item.innerHTML == pagina_Actual) {
                                //console.log(item.innerHTML)
                                item.classList.add("active");
                            }
                            else{
                                item.classList.remove("active");
                            }
                            //console.log(item.innerHTML);
                        }
                    })
                },
                onChangePage (page) {
                this.$refs.vuetable.changePage(page)
                },

                onLoading() {
                    console.log('cargando')
                    this.sleep(2000)   

                let spin=document.getElementById('spin');
                let tablebody=document.getElementsByClassName('vuetable-body')[0];
                spin.classList.remove('d-none'); //vuetable-body
                tablebody.classList.add('opacity'); //vuetable-body
                //console.log('Cargando .. show your spinner here')
                },
                onLoaded() {
                    console.log("cargado")
                    //this.loading=false
                    let spin=document.getElementById('spin');
                    let tablebody=document.getElementsByClassName('vuetable-body')[0];
                    tablebody.classList.remove('opacity');
                    spin.classList.add('d-none');
                    
                    //console.log('Cargado .. hide your spinner here')
                },
                search: _.debounce(function () {
                    this.moreParams = {
                        'filter': this.searchText,
                        "sold_status":false
                    };
                    this.$nextTick(() => this.$refs.vuetable.refresh())

                }, 400),
             


                popToast(type, message) {
                    this.$bvToast.toast(message, {
                        //title: title,
                        variant: type,
                        solid: true,
                        toaster: 'b-toaster-top-center',
                    })
                },
                handle_metamask_rpc_error(serialized_error){
                    console.log("serializedError",serialized_error );
                    if (serialized_error.data!=undefined){
                        this.popToast("danger", serialized_error.data.originalError.reason)
                    }
                    else{
                        this.popToast("danger", serialized_error.message)
                    }
                },
                async editNFTPrice(){
                    this.editing_nft_price=true
                    try{
                        let result_update_price = await this.car_collection_contract.functions.updateCarPrice(this.current_car.collection_id, this.current_car.nft_price)
                        console.log("miau", result_update_price)
                        let resultado= await this.handle_tx_confirmation(result_update_price)
                        console.log("resultado",resultado)
                        this.popToast("success", "Precio nft actualizado correctamente")
                        this.getCarInfo(this.current_car.id, false)
                        this.editing_nft_price=false

                    }catch(error){
                        const serializedError = serializeError(error);
                        this.handle_metamask_rpc_error(serializedError)
                        this.editing_nft_price=false
                    }
                },

                async initSaleCrypto(){
                    this.switching_crypto_sale=true
                    try{
                        let result_activate = await this.car_collection_contract.functions.setCarForSale(this.current_car.collection_id)
                        console.log("miau", result_activate)
                        let resultado= await this.handle_tx_confirmation(result_activate)
                        console.log("resultado",resultado)
                        this.popToast("success", "NFT puesto a la venta correctamente")
                        //await result_deposit.wait(1)
                        this.current_car.nft_info=await this.car_collection_contract_read_only.Cars(this.current_car.collection_id)
                        this.current_car.nft_price=this.current_car.nft_info.Price.toNumber()
                        //await result_deposit.wait(1)
                        this.switching_crypto_sale=false

                    }catch(error){
                        const serializedError = serializeError(error);
                        this.handle_metamask_rpc_error(serializedError)
                        this.switching_crypto_sale=false
                    }
                },

                async stopSaleCrypto(){
                    this.switching_crypto_sale=true
                    try{
                        let result_deactivate = await this.car_collection_contract.functions.removeCarFromSale(this.current_car.collection_id, false)
                        let resultado= await this.handle_tx_confirmation(result_deactivate)
                        console.log(resultado)
                        this.popToast("success", "NFT quitado de la venta correctamente")
                        this.current_car.nft_info=await this.car_collection_contract_read_only.Cars(this.current_car.collection_id)
                        this.current_car.nft_price=this.current_car.nft_info.Price.toNumber()
                        //await result_deposit.wait(1)
                        this.switching_crypto_sale=false

                    }catch(error){
                        const serializedError = serializeError(error);
                        this.handle_metamask_rpc_error(serializedError)
                        this.switching_crypto_sale=false
                    }
                },


                async bookNft(address){
                    this.booking_nft=true
                    try{
                        let result_deactivate = await this.car_collection_contract.functions.bookCar(address, this.current_car.collection_id)
                        let resultado= await this.handle_tx_confirmation(result_deactivate)
                        console.log(resultado)
                        
                        //await result_deposit.wait(1)
                        //await this.sleep(2000)
                        //this.current_car.nft_info=await this.car_collection_contract_read_only.Cars(this.current_car.collection_id)
                        //this.current_car.nft_price=this.current_car.nft_info.Price.toNumber()
                        //this.current_car.nft_reserved=this.current_car.nft_info.Reserved
                        this.getCarInfo(this.current_car.id, false)
                        this.popToast("success", "Reserva actualizada correctamente")
                        this.booking_nft=false

                    }catch(error){
                        const serializedError = serializeError(error);
                        this.handle_metamask_rpc_error(serializedError)
                        this.booking_nft=false
                    }
                },
                 
                createCryptoAd(){
                    this.creating_crypto_add=true
                    axios.post('/api/cars/adds/create_ftm',{
                            'car_id': this.current_car.id,
                        }) 
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.pictures=result.data.images
                            this.popToast("success", result.data.mensaje, "Correcto")
                            this.getCarInfo(this.current_car.id, false)
                            this.creating_crypto_add=false
                            this.$nextTick(() => this.$refs.vuetable.refresh())
                            //console.log("getPictures:",this.pictures)
                            //this.getPictures()
                        }
                        else if (result.data.resultado === "Error"){
                            this.creating_crypto_add=false
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                    }).catch((error) => {
                        this.creating_crypto_add=false
                        console.log(error);
                    });
                },
                async setup(){
                    console.log(this.signer)
                    this.load_read_only_ws_provider()
                    //console.log("this.web3_read_provider",this.web3_read_provider)
                    console.log("this.web3_read_provider",this.web3_read_provider)
                    this.car_collection_contract = new ethers.Contract(AddressCarCollection, car_collection["abi"], this.signer); 
                    console.log('contract',this.car_collection_contract)
                    //this.balance = await this.balance_update()
                    //this.get_validators()
                },


                async handle_tx_confirmation(tx_object){
                    let result=undefined
                    
                    try{
                        //console.log("handling tx")
                        result = await this.web3_read_provider.waitForTransaction(tx_object.hash)
                        //console.log("bien")
                        return(true, result)
                    }
                    catch(error){
                        this.popToast("error", error)
                        //console.log("mal")
                        return(false, error)
                    }
                },
                async connect_metamask(){ 
                    this.connecting=true
                    //let vm=this
                    try{
                        if(window.ethereum) {
                            //let accounts= await window.ethereum.enable();
                            this.web3_provider=await new ethers.providers.Web3Provider(window.ethereum)
                            let chainId= await window.ethereum.request({ method: 'eth_chainId' })
                            console.log("chainId", chainId)
                            if (chainId==0xfa){ //0xfa2 para testnet  || chainId==0x7A69
                                await window.ethereum.request({ method: 'eth_requestAccounts' });
                                this.signer=this.web3_provider.getSigner()
                                console.log("signer", this.signer)
                                this.my_eth_address= await window.ethereum.selectedAddress
                                console.log('address',this.my_eth_address)
                                this.metamask_connected=true
                                this.connecting=false
                                this.popToast("success", `Successfuly conected wallet`)
                                await this.setup()
                            }
                            else{
                                this.popToast("danger", `Please, change to correct network`)
                            }
                            
                        }
                        else{
                            this.metamask_connected=false
                            this.connecting=false
                            this.popToast("danger", `Please, install metamask`)
                        }        
                    }

                    catch(error){
                        console.log(error)
                        this.popToast("danger", `Error connecting metamask: ${error.message}`)
                        this.metamask_connected=false
                        this.connecting=false


                    }
                    
                    //return(this.web3_provider)   
                },





                
                onCalculateHash(event) {
                    let vm=this
                    this.images_zip = event.target.files[0]
                    this.calculating_imgs_hash = true;
                    var file = event.target.files[0];
                    var reader = new FileReader();
                    var SHA256 = CryptoJS.algo.SHA256.create();
                    var counter = 0;
                    var lastOffset = 0;
                    var previous = [];

                    function loading(file, callbackProgress, callbackFinal) {
                        var chunkSize  = 548*1024; // bytes
                        var offset     = 0;
                        var size=chunkSize;
                        var partial;
                        var index = 0;
                        

                        if(file.size===0){
                            callbackFinal();
                        }
                        while (offset < file.size) {
                            partial = file.slice(offset, offset+size);
                            var reader = new FileReader;
                            reader.size = chunkSize;
                            reader.offset = offset;
                            reader.index = index;
                            reader.onload = function(evt) {
                                callbackRead(this, file, evt, callbackProgress, callbackFinal);
                            };
                            reader.readAsArrayBuffer(partial);
                            offset += chunkSize;
                            index += 1;
                        }
                        }
                    


                    function callbackRead(reader, file, evt, callbackProgress, callbackFinal){

                        if(lastOffset !== reader.offset){
                            // not of order chunk: put into buffer
                            previous.push({ offset: reader.offset, size: reader.size, result: reader.result});
                            return;
                        }

                        function parseResult(offset, size, result) {
                            lastOffset = offset + size;
                            callbackProgress(result);
                            if (offset + size >= file.size) {
                                lastOffset = 0;
                                callbackFinal();
                            }
                        }

                        // in order chunk
                        parseResult(reader.offset, reader.size, reader.result);

                        // check previous buffered chunks
                        var buffered = [{}]
                        while (buffered.length > 0) {
                            buffered = previous.filter(function (item) {
                                return item.offset === lastOffset;
                            });
                            buffered.forEach(function (item) {
                                parseResult(item.offset, item.size, item.result);
                                previous.remove(item);
                            })
                        }

                    }
                    loading(file,
                        function (data) {
                            var wordBuffer = CryptoJS.lib.WordArray.create(data);
                            SHA256.update(wordBuffer);
                            counter += data.byteLength;
                            //console.log((( counter / file.size)*100).toFixed(0) + '%');
                            vm.hash_calc_percent=((counter / file.size)*100).toFixed(2)
                        }, function (data) {
                            console.log('100%'); 
                            var encrypted = SHA256.finalize().toString();
                            vm.file_hash=encrypted
                            //vm.generactContract("test_drive")
                            console.log('hash sha256: ' + encrypted);
                        });

                    Array.prototype.remove = Array.prototype.remove || function(val){
                        var i = this.length;
                        while(i--){
                            if (this[i] === val){
                                this.splice(i,1);
                            }
                        }
                    };

                        
                },

                load_read_only_ws_provider: function(){
                    this.web3_read_provider= new ethers.providers.JsonRpcProvider("https://rpcftm.kartek.ad")
                    //this.web3_read_provider._websocket.onclose= (event) => this.onWsClose(event);
                    this.car_collection_contract_read_only = new ethers.Contract(AddressCarCollection, car_collection["abi"], this.web3_read_provider); 
                    console.log("this.web3_read_provider",this.web3_read_provider)
                },
                sleep: function(milliseconds){
                    return new Promise(resolve => setTimeout(resolve, milliseconds))
                },  

                translate_from_spanish(){
                    let text=this.current_car.description.es
                    
                    this.translating=true
                    axios.post('/api/extra_equipments/translate',{
                            'text': text
                        }) 
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.current_car.description.cat=result.data.texts.cat
                            this.current_car.description.en=result.data.texts.en
                            this.current_car.description.fr=result.data.texts.fr
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                        this.translating=false
                    }).catch((error) => {
                        this.translating=false
                        console.log(error);
                    });
                },
              
        }, 
        
        async mounted(){
            await this.connect_metamask()
            this.getBrands()
            //this.getEmployees()
            this.getProviders()
            this.getCustomers()
           
            //this.getAllCarsStock()
            this.load_read_only_ws_provider()    
                   
        },
    }
    </script>
    <style >
.image{
        max-width: 300px;
        max-height: 200px;
    }

</style>