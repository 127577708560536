<template>
  <div id="app">
      <!-- <nav class="navbar navbar-expand-lg navbar-light fixed-top nav-shadow " id="navbar">
            <a href="https://kartek.ad/" class="d-none d-md-block" style="margin-right:30px" title="volver a pagina web">
                <img id="nav-logo"  alt="kartek logo" src="/logo.svg">
            </a>
            <a href="https://kartek.ad/" class="d-md-none" title="volver a pagina web">
                <img id="nav-logo" src="/logo.svg" alt="kartek logo">
            </a> 
            
            <b-navbar-toggle target="nav-collapse" ></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="text-center">
                    <li class="nav-list"><a class="navitem" href="/#stock" title="car stock">{{ $t('Stock')}}</a></li>
                    <li class="nav-list"><a class="navitem" href="/#we-are" title="about us">{{ $t('Nosotros')}}</a></li>
                    <li class="" style="padding:10px"><a class="navitem" href="/#crypto" title="buy with cryptocurrency"><font-awesome-icon :icon="['fab', 'bitcoin']" class="nav-bitcoin" /></a></li>
                    <li class="nav-list"><a class="navitem" href="/#contact">{{ $t('Contacto')}}</a></li>
                    <li class="" style="padding:10px"><a class="navitem" target="_blank" href="https://wa.me/376395375" title="contact by whatsapp"><font-awesome-icon :icon="['fab', 'whatsapp-square']" class="nav-whatsapp" /></a></li>
                </b-navbar-nav>
            </b-collapse>
        </nav>-->
        <router-view></router-view>
       
    </div>

</template>

<script>

//import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
//import ToTop from '@/components/ToTop.vue'

export default {
    name: "App",
    components: {
        //LanguageSwitcher,
        //ToTop
    },
    data() {
        return {
          
        //showCollapse: false
        }
    },
    methods:{
       /* open_nav: function(){
            console.log('miau')
            this.showCollapse==true
            console.log(showCollapse)

        },
       close_nav: function(){
            console.log('miau')
            this.showCollapse==false
            console.log(showCollapse)

        }*/
    },

 
    /* handleScroll() {
            const scrollBtn = this.$refs.scrollTopButton;

            if (window.scrollY > 300) {
                scrollBtn.classList.add("show");
                
            } else {
                scrollBtn.classList.remove("show");
            }
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
        popToast(type, message, title) {
            this.$bvToast.toast(message, {
                //title: title,
                variant: type,
                solid: true,
                toaster: 'b-toaster-top-center',
            })
        },
    }, */
    mounted(){
        /*document.getElementById("nav-collapse").addEventListener("click",this.open_nav )
        document.getElementById("app").addEventListener("click",this.close_nav )*/
    },
}
</script>


<style>

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400&display=swap');

:root{
    --main-color:#0064BF;
}
body{
    overflow-x: hidden ;
    font-family: 'Quicksand', sans-serif!important;
    align-content: center;

    }
.navbar_toggler{
    background-color: #000;
}
.navbar_toggler-icon{
    background-color: #000;
}
.blue{
  color: #0064BF;
}

.black{
    color:black;
}
.white{
  color: #fff;
}
.bold{
    font-weight: bold;
}
.jus{
    text-align: justify;
}
.disabled{
  pointer-events: none!important;
  opacity:0.5;
}
 .row{
       margin-left: 0px!important;
       margin-right: 0px!important;
    }
.hand{
    cursor: pointer;
}

a{
    color:black!important;
}
a:hover{
  text-decoration: none!important;
}


a[aria-expanded=true] .fa-chevron-down {
    display: none;
}
a[aria-expanded=false] .fa-chevron-up {
    display: none;
}
.bttn {
  display: inline-block;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0.4rem 1.2rem;
   /* height:43px;*/
    /*font-size: 1rem;*/
    line-height: 1.2;
    border-radius: 5px;
    transition: none;
    font-weight:bold;
    font-size:1.3rem;
    text-transform: uppercase;
    min-height: 35px;
 }
.btn-primar{
   border: 1px solid #000!important;
    background-color: #000!important;/*#4776e5*/
    color: white!important;
}
.btn-primar:hover{
    border: 1px solid #000!important;
    background-color: white!important;
    color: #000!important;
    
    transform: translateY(0);
}
.btn-small{
        padding: 0 4px 1px 1px;
        border-radius:4px;
    }
.btn-second{
   border: 1px solid var(--main-color);
    background-color: var(--main-color);/*#4776e5*/
    color: white;
}
.btn-second:hover{
    border: 1px solid var(--main-color);
    background-color: white;
    color: var(--main-color)!important;
    
    transform: translateY(0);
}
.btn-red{
    border: 1px solid rgb(212, 0, 0);
    background-color: rgb(212, 0, 0);/*#4776e5*/
    color: white!important;
}
.btn-red:hover{
    border: 1px solid rgb(212, 0, 0);
    background-color: white;
    color: rgb(212, 0, 0)!important;
    transform: translateY(0);
}
.btn-grey{
    border: 1px solid grey;
    background-color: grey;/*#4776e5*/
    color: white!important;
}
.btn-grey:hover{
    border: 1px solid grey;
    background-color: white;
    color: grey!important;
    transform: translateY(0);
}

.btn-green{
    border: 1px solid rgb(4, 171, 4);
    background-color: rgb(4, 171, 4);
    color: white;
}
.btn-green:hover{
    border: 1px solid rgb(4, 171, 4);
    background-color: white;
    color: rgb(4, 171, 4)!important;
    
    transform: translateY(0);
}
.btn-btc{
    /*background-image: linear-gradient(to right, #EA8C19 , #e7b06d);*/
   border: 2px solid #000;
    background-color: #EA8C19;/*#4776e5*/
    color: white;
}
.btn-btc:hover{
    border: 1px solid #EA8C19;
    background-color: white;
    color: #EA8C19!important;
    
    transform: translateY(0);
}
.btn-secondary{
    border: 1px solid #000!important;
    background-color: #000!important;/*#4776e5*/
}
.addbtn{
        margin-top: 44px;
        margin-bottom: 15px;
    }
/*
.btn-blue{
    border: 1px solid #4696FE;
    background-color: #4696FE;
    color: white;
}

.btn-blue:hover{
    border: 1px solid #4696FE;
    background-color: white;
    color: #4696FE!important;
    
    transform: translateY(0);
}
*/

.single-btn{
    min-width:50%; 
    margin-top:38px; 
}

.card-shadow{
    -webkit-box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.10);
    box-shadow: 0 0 30px 2px rgba(0, 0, 0, 0.10);
}
/*   .card-shadow{
        -webkit-box-shadow: 0 0 8px 2px rgba(0,0,0,0.08);
        -moz-box-shadow: 0 0 8px 2px rgba(0,0,0,0.08);
        box-shadow: 0 0 8px 2px rgba(0,0,0,0.08);
    } */
.card{
    border:1px solid rgba(73, 43, 43, 0.025)!important;
    border-radius:3px!important;
}

.card-blue{
    background-color:rgba(209, 229, 255, 0.5)!important;
}


.form-label {
    font-size: 1.5rem;
    /*color: #4696FE;*/
    font-weight: bold;
    /*padding-left: 5px;*/
    font-weight: bold!important;
}

.form-input{
    border: 1px solid rgb(122, 122, 122);/*#4696FE*/
    background-color: white;
    width: 100%;
    padding: 4px 8px;
    font-size: 17px;
    border-radius: 3px;
    min-height: 40px;
}

.form-input:hover{
    outline: transparent!important;
}
.form-input:focus{
    outline: transparent!important; 
}

.vs__dropdown-toggle{
    border: 1px solid rgb(122, 122, 122)!important;
    background-color: #d0e1fe6e!important;
    color: #FFFFFF!important;
    width: 100% !important;
    padding: 7px !important;
    font-size: 17px !important;
    box-shadow: 0px 10px 10px -8px rgba(0,0,0,0.1) !important;
    border-radius: 3px !important;
    height: 40px!important;
    z-index: 2;
}
.vs__dropdown-toggle:hover{
    border: 2px solid rgb(122, 122, 122)!important;
    padding: 6px!important;
}
.vs__actions{
    padding: 5px!important;
}
.vs__open-indicator{
        fill: rgb(122, 122, 122)!important;
}
.vs__search{
    margin-top: 0!important;
    padding: 0!important;
    color: rgb(122, 122, 122)!important;
    border: none!important;
      font-size: 17px !important;
}
.vs__selected-options{
    overflow:hidden!important;
    white-space: nowrap;
}
.vs__selected{
    margin: 0!important;
    padding: 0!important;
    color: rgb(122, 122, 122)!important;
    border: none!important;
}
.vs__clear{
    display: none;
}

/*toast*/

.popover{
    z-index:20!important;
}
.toast{
  border: #e2dfdd solid 1px!important;
  border-radius: 7px!important;
}
.b-toast{
  text-align: center!important;
    opacity: 0.9;
}
.toast-header {
    padding: 0 !important;
    margin: 0 !important;
    width: 0px !important;
    text-align: center;
}
.toast-body{
  padding: 10px!important;
  font-size:18px!important;
}
/* web*/

.title-index{
    font-size: 60px;
    margin: 40px 0;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    color:#4696FE;
}
.title-main{
    color: #000;
    text-transform: uppercase;
    font-size: 5.5rem;
    text-align: center;
    font-weight:bold;
    margin: 2rem;
}
.title-second{
    text-align: center;
    font-weight:bold;
    margin: 20px 0;
    font-size: 40px;
}
.modal-dialog{
    border-radius: 7px;
}
  
.modal-label{
        font-size:1.25rem;
        text-align: left;
        margin:8px 0;
    }
.icon{
    height: 50px!important;
    width: 50px!important;
}

.big{
    font-size: 20px;
}
/*
 #phonenav{
      background-color: white;
      color: black;
      padding: 0 16px 8px 16px!important;
      width:100%;
      top:0;
      z-index: 1031;
  }
#navbar{
  color: black;
  background-size: 150px;
  background-color: #FFFFFF;
  top:40px;
}*/
#navbar{
  background-color: white;
}
.nav-list{
    padding: 10px;
}
.nav-list:hover{
    background-color: #4696fe17;
    border-radius: 7px;
   

}
.navitem{
    text-decoration:none;
    font-size:30px;
    font-weight: bold;
    margin: 0 30px;

}

.navitem:hover{
    color: black;
    font-weight: bold;
}
.navbar.toggler-icon{
    color:#4696FE!important;
    border:none;
}
.nav-shadow{
    -webkit-box-shadow: 0 0 0.25rem 1rem rgba(0, 0, 0, 0.10);
    -moz-box-shadow: 0 0 0.25rem 1rem rgba(0, 0, 0, 0.10);
    box-shadow:  0 0.25rem 1rem rgba(0, 0, 0, 0.10);
}
.nav-whatsapp{
    width: 50px!important;
    height: 50px!important;
    color:#23C861;
}
.nav-bitcoin{
    width: 50px!important;
    height: 50px!important;
    color:#EA8C19;
}
  #carousel-init{
    text-shadow: 1px 1px 2px #333;
    margin-top:80px;
  }
  .carousel-item {
        text-align: center;
        width: 100%;
    }
    
    .carousel-control-prev{
        font-size: 1.5rem;
        width: 5% !important;
        color: rgb(226, 223, 205);
        opacity:1!important;
    }
    
    .carousel-control-next{
        font-size: 1.5rem;
        color: rgb(226, 223, 205);
        width: 5% !important;
        opacity:1!important;
        /*background-color: rgba(226, 223, 205, 0.246);*/
    }

hr{
  border-color: #4776e5;
   width: 60%;
}
.hr{
    margin-top: 50px!important;
    margin-bottom: 50px!important;
  /*box-shadow: 5px 5px 20px #000;*/
}


.img-init{
    width: 100%;
    max-height: 100vh;
    display: block; /* remove extra space below image */
    object-fit: cover;
}
.img-init-phone{
    max-height: 95vh;
    display: block; /* remove extra space below image */
    object-fit: cover;
}
    .dropdown-toggle{
      font-size:1.2rem!important;
    }
.dropdown-menu{
  min-width: fit-content!important;
}
.dropdown-toggle{
    padding: 5px 20px;
}
.btc{
    margin-right:1rem;
    color: #EA8C19;
    width: 8rem!important;
    height: 8rem!important;
}
.ether{
    margin-left:1rem;
    color: #6F7BBA;
    width: 8rem!important;
    height: 8rem!important;
}
    
.whatsapp{
    width: 60px!important;
    height: 60px!important;
    color:#23C861;
}
.show{
    display: block!important;
}
.red{
    color:red;
}
.green{
    color:#23C861;
}
.blue{
    color:#4776e5;
}

/*tables */

.vuetable th {
    background-color: white;
    color: #2667F7 !important;
    text-align: center;
    font-size: 13px !important;
    border-top: none!important;
}

td{
    padding: 5px;
    text-align: center;
    font-size: 1em!important;
    color: black;
    font-weight: 300;
}
.vuetable-slot{
    justify-content: center!important;
}
.vuetable-body-wrapper{
    min-height: 550px;
}
.btn-page{
    border-radius: 50%;
    margin-bottom: 8px;
    padding: 10px 18px;
    font-size: 18px;

}

.btn-arrow{
    border-radius: 50%;
    margin-bottom: 8px;
    padding: 4px 15px;
    font-size: 22px;
}

.btn-page{
    border-radius: 50%!important;
    margin-bottom: 10px;
    padding: 1px 9px!important;
}


.active{
    background-color: #aac3fa!important;
}
.vue-pagination{
    text-align: center;
    background-color: #ffffff!important;
}
.vuetable-pagination-info{
    padding-left: 10px;
}



/* Sidebar sizes when expanded and expanded */
@media only screen
and (max-device-width : 767px){
    /*.navbar{
      margin: 0 0 5px 15px;
    }*/
    .navitem{
      color: black;
      margin: 10px;
    }
    .nav-logo{
        width: 100px;
    }
  

    /*.title-main{
        color: #4776e5;
        text-transform: uppercase;
        font-size: 6rem;
        text-align: center;
        font-weight:bold;
        margin: 40px 0;
    } */    
    .img-nosotros {
        height:270px;
        width: 100%;
    }
    .addbtn{
        margin-top: 10px;
        margin-bottom: 5px;
    }
    .card-body{
        padding: 0!important;
    }

        .col-sm-8{
            flex: 0 0 66.666667%;
            max-width: 66.666667%;
        }
        .col-sm-9{
            flex: 0 0 75%;
            max-width: 75%;
        }.col-sm-10{
            flex: 0 0 83.333333%;
            max-width: 83.333333%;
        }
        .col-sm-11{
            flex: 0 0 91.666667%;
            max-width: 91.666667%;
        }
        #carousel-init{
            margin-top:70px!important;
        }
        .title-car{
            font-size: 3rem!important;
           
        }

}

@media only screen and (min-width: 767px) and (max-device-width : 992px)  {
   /* .navbar{
      margin: 5px 0px 5px 40px;
    }*/
    .navitem{
      color: black;
      margin: 10px;
    }
    .nav-logo{
        width: 140px;
    }
    #to-top{
        bottom: 50px;
        right: 50px;
    }
    .img-nosotros {
        height:500px;
        width: 100%;
    }  
     
  #carousel-init{
    margin-top:70px!important;
  }
}
@media only screen and (min-width: 993px) and (max-device-width : 1300px) {
    /*.navbar{
      margin: 5px 0px 5px 60px;
    }*/
    .navitem{
      color: black;
      margin: 20px;
    }
    .nav-logo{
        width: 170px;
    }
    #to-top{
        bottom: 50px;
        right: 50px;
    }
    .img-nosotros {
        height: 450px;
        width: 100%;
    }
    #carousel-init{
        margin-top:70px!important;
    }
}

@media only screen and (min-width: 1301px) and (max-device-width : 1599px)  {
    /*.navbar{
      margin: 5px 0px 5px 20px;
    }*/
    .navitem{
      color: black;
      margin: 30px;
    }
    .nav-logo{
        width: 140px;
    }
    #to-top{
        bottom: 50px;
        right: 50px;
    }
    .img-nosotros {
        height: 500px;
        width: 100%;
    }

    
}

@media only screen and (min-width: 1601px) {
   /* .navbar{
      margin: 5px 0px;
    }*/
    .navitem{
      color: black;
      margin: 50px;
    }
    .nav-logo{
        width: 170px;
    }
    #to-top{
        bottom: 50px;
        right: 50px;
    }

    .img-nosotros {
        height:600px;
        width: 100%;
    }
}

/* Large screens ----------- */
@media only screen
and (min-width : 1824px) {
    /*.navbar{
      margin: 5px 0px;
    }*/
    .navitem{
      color: black;
      margin: 50px;
    }
 
    .nav-logo{
        width: 170px;
    }
    .boo-fields{
        top:32%!important;
    }
  
}
</style>
