import Vue from 'vue'
import App from './App.vue'
import _ from 'underscore';
import router from './router'
import VueRouter from 'vue-router'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import Toasted from 'vue-toasted'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import i18n from './i18n'
//import { SpinViewer } from "@egjs/view360";
import VueThreeSixty from 'vue-360'
import 'vue-360/dist/css/style.css'
import axios from "axios";


axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
axios.defaults.withCredentials = true

axios.interceptors.response.use((response) => {
    return response;
  }, (error) => {
     //console.log("error", error.status, error.config._retry, error)
      const originalRequest=error.config
      // Return any error which is not due to authentication back to the calling service
      //console.log("ereror antes", error.response.status, error.config.url)
      if (error.response.status == 401 && originalRequest._retry==undefined && error.config.url != '/token/refresh') {
        originalRequest._retry = true;
        //console.log(console.log("miau", error.response.status, originalRequest))
        return Vue.refresh_token()
          .then((result) => {
            console.log("resultado promise refresh", result)
            if(result=="Ok"){
              return new Promise((resolve, reject) => {
                axios(originalRequest).then(response => {
                  resolve(response);
                }).catch((error) => {
                  reject(error);
                })
              });
            }
            
            })
      }

      else if (error.config.url == '/token/refresh' && error.response.status == 401 ) {
        console.log("Log out, sesión caducada")
        if(router.currentRoute.path!="/login"){
          router.push({ path: '/login' })
        }

      }
      else{
        console.log("guau",error)
      }
    
      return Promise.reject(error);
    }
  );
  
Vue.use(VueThreeSixty)


//Vue.use(SpinViewer)
//Vue.use(ethers)
Vue.use(BootstrapVue)
Vue.use(VueRouter)
library.add(fas)
library.add(far)
library.add(fab)

//Vue.component('spin-viewer', SpinViewer)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('v-select', vSelect)

Vue.use(Toasted)

Vue.config.productionTip = false

const TokenRefreshPlugin = {
  install() {
    Vue.refresh_token = _.throttle(function(){
      console.log("usando plugin refresh")
      //IsRefreshing=true
      return new Promise((resolve, reject) => {
          axios.post('/token/refresh', {
              })
                  .then((result) => {
                      console.log("result pedir nuevo token",result.data)
                      if (result.data.data.message == "Ok"){
                          resolve(result.data.data.message)
                      }
                      else if (result.data.data.message == "Error"){
                          console.log("error caso")
                          reject(result.data.data.message)
                          
                          if(router.currentRoute.path!="/login"){
                            router.push({ path: '/login' })
                          }
                          return("Error")
                      }
                      else{
                        console.log("tercer caso")
                      }
                  }).catch((error) => {
                      console.log("Error en:",error);
                      reject(error);
                      //return("Error")
                  });
          }
        )

      
      }, 500)
    }
}

Vue.mixin({
  methods: {
    solveAuthIssuesOtherComponentsGet(apiUrl, httpOptions) {
      return axios.get(apiUrl, httpOptions)
    },
  }
});


Vue.use(TokenRefreshPlugin)

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')