
 <template>
    <div class="main">
     <!--<div  v-if="loading" class="spinner">
            <font-awesome-icon icon="circle-notch" class="mr-2" spin  />
        </div>
        <div v-else>-->
            <nav class="navbar navbar-expand-lg navbar-light fixed-top nav-shadow " id="navbar">
                <a href="https://kartek.ad/" class="d-none d-md-block" style="margin-right:30px" title="volver a pagina web">
                    <img id="nav-logo"  alt="kartek logo" src="/logo.svg">
                </a>
                <a href="https://kartek.ad/" class="d-md-none" title="volver a pagina web">
                    <img id="nav-logo" src="/logo.svg" alt="kartek logo">
                </a>
            </nav>


            <div class="row justify-content-center m-0">
                <div class="col-lg-5 col-md-8 col-sm-12 text-center tags">
                     <div class="text-center" >
                        <a v-if="connecting==true" class="btn-metamask text-center disabled">
                            <font-awesome-icon icon="circle-notch" width="40"  class="metamask-spin" spin  />
                            Connecting Metamask
                        </a>
                        <div v-else-if="metamask_connected==true && address_registered==true">
                            <a v-if="loging_in==false" @click="login()" class="btn-metamask">Log in</a>
                            <a v-else class="btn-metamask disabled">Loging in...</a>
                        </div>
                        
                        <a v-else-if="address_registered==false && metamask_connected==true" @click="sendRegister()" class="btn-metamask">Register</a>
                        <a v-else @click="connect_metamask()" class="btn-metamask text-center">
                            <img src="metamask.svg" width="50" style="margin:-10px 15px 0 0" alt="Metamask fox">Connect Metamask
                        </a>
                    </div>
                </div>
            </div>
       
  </div>
</template>

<script >
// @ is an alias to /src
import axios from "axios";
//axios.defaults.withCredentials = true
//import ResponseData from "@/types/ResponseData";
import { ethers } from "ethers";
//const provider = new ethers.providers.Web3Provider(window.ethereum)
//import Foot from '@/components/Footer.vue'

export default {
    name: "login",
    components: {
       // Foot
    },
    data(){
        return{
            loging_in:false,
            connecting:false,
            loading:true,
            metamask_connected:false,
           
            loging:false,
            email:"",
            password:"",

            isAdmin:false,
            
            show: false,
            web3_provider: null,   
            my_eth_address:null,
            address_registered:null,
            login_message:null,
            showModal: false
        }
    },
    methods:{
        popToast(type, message, title) {
            this.$bvToast.toast(message, {
                //title: title,
                variant: type,
                solid: true,
                toaster: 'b-toaster-top-center',
            })
        },

       async connect_metamask(){ 
            this.connecting=true
            if(window.ethereum) {
                //await window.ethereum.send('eth_requestAccounts')
                await window.ethereum.enable();
            }
            this.web3_provider=new ethers.providers.Web3Provider(window.ethereum)
            //console.log("provider",this.web3_provider)
            this.signer=this.web3_provider.getSigner()
            //console.log("signer", this.signer)
            this.my_eth_address= await this.signer.getAddress()
            this.check_if_registered()
            console.log(this.my_eth_address)
            this.connecting=false
            this.metamask_connected=true
            
            //return(this.web3_provider)
            
        },
        async sign_login_message(test_data){
            let signed_message=await this.signer.signMessage(test_data)
            return(signed_message)
        },
     


        async check_if_registered(){
            let result_fetch=null
            try{
                result_fetch= await axios.post('/api/check_registered',{
                "address":this.my_eth_address,
                })
                if (result_fetch.data.resultado === "Ok"){
                    if(result_fetch.data.registered==true){
                        this.connecting=false
                        this.address_registered=true
                        console.log("is registered")
                        return(true)
                    }
                    else{
                        this.connecting=false
                        this.address_registered=false                
                        console.log("not registered")       
                        return(false)
                    }
                }
                else if (result_fetch.data.resultado === "Error"){
                    this.connecting=false
                    //this.popToast("danger", result.data.message, "Error")
                    console.log(result_fetch.data)
                    return(null)
                } 
                else{
                    this.connecting=false
                    return(null)
                }
            }
            catch(error) {
                this.connecting=false
                return(null)
            }
            
        },
        
        async get_login_message(){
            let result_fetch=null
            try{
                result_fetch= await axios.post('/api/get_login_message',{
                "address":this.my_eth_address,
                })
                if (result_fetch.data.resultado === "Ok"){
                    this.login_message=result_fetch.data.login_message
                    console.log("login_message:",this.login_message)
                    return(this.login_message)
                }
                else if (result_fetch.data.resultado === "Error"){
                            this.popToast("danger", result.data.message, "Error")
                            console.log(result_fetch.data)
                            this.login_message=null
                            return(this.login_message)

                } 
                else{
                    return(null)
                }
            }
            catch(error) {
                console.log(error);
                this.login_message=null
                //this.popToast("danger", "Error", "Error, prueba a refrescar la página y contacte con soporte si el problema persiste")
                return(this.login_message)
            }
            
        },
        
        async check_if_logged(){
            axios.post('/api/test_login',{
                "address": await this.web3_provider.getSigner().getAddress()
            })
                .then((result) => {
                    if (result.data.resultado === "Ok"){
                        console.log(result.data)
                        //this.bookingsList=result.data.reservas
                        //this.popToast("success", result.data.message)
                        this.login_message=result.data.login_message
                        return(true)
                        
                    }
                    else if (result.data.resultado === "Error"){
                        //this.popToast("danger", result.data.message, "Error")
                        console.log(result.data)
                        this.login_message=null
                        return(false)
                }
                }).catch((error) => {
                    console.log(error);
                    this.login_message=null
                    //this.popToast("danger", "Error", "Error, prueba a refrescar la página y contacte con soporte si el problema persiste")
                    return(false)
                });
                return(false)
        },

        async sendRegister(){
           let signature= await this.sign_login_message("Registering in Kartek")
           this.registering=true
            
            let result_fetch=null
            try{
                result_fetch= await axios.post('/api/register_web',{
                "email":this.email,
                "address":this.my_eth_address,
                "signature":signature
                })
                if (result_fetch.data.resultado === "Ok"){
                        console.log(result_fetch.data)
                        this.address_registered=true
                        this.registering=false
                }
                else if (result_fetch.data.resultado === "Error"){
                    this.popToast(result_fetch.data.message, "danger")
                    console.log(result_fetch.data)
                    this.registering=false
                }
            }
            catch(error) {
                console.log(error);
                this.registering=false
                //this.popToast("danger", "Error", "Error, prueba a refrescar la página y contacte con soporte si el problema persiste")
            }

            //await this.$recaptchaLoaded()
            // Execute reCAPTCHA with action "login".
            //const token = await this.$recaptcha('login')
            //console.log(token)

           
        },
     
        async login(){
            this.loging_in=true
            let login_message= await this.get_login_message()
            console.log("login_message:", login_message)

            let signature= await this.sign_login_message(login_message)
           //let signature= await this.sign_login_message("miau")
            
            let result_fetch=null
            try{
                result_fetch= await axios.post('/api/login',{
                    "address":this.my_eth_address,
                    "signature":signature
                })
                console.log(result_fetch)
                if (result_fetch.data.data.resultado === "Ok"){
                    console.log(result_fetch.data.data)
                    this.loging_in=false
                    this.adminCheck()
                }
                else if (result_fetch.data.data.resultado === "Error"){
                    //this.popToast("danger", result.data.message, "Error")
                    console.log("Error login",result_fetch.data)
                    this.loging_in=false
                }
            }
            catch(error) {
                console.log(error);
                this.loging_in=false
                //this.popToast("danger", "Error", "Error, prueba a refrescar la página y contacte con soporte si el problema persiste")
            }

        },
        adminCheck(){
            this.loading = true;
            axios.get('/api/check_admin')
            .then((result) => {
                if (result.data.resultado === "Ok"){
                    this.isAdmin=result.data.is_admin
                    console.log('is admin',result.data.is_admin)
                    this.loading = false;
                     if (this.isAdmin==true){
                        this.$router.push({ path: '/admin' })
                     }else{
                        this.$router.push({ path: '/user/coches_stock' })
                     }
                }
                else if (result.data.resultado === "Error"){
                    this.popToast("danger", result.data.mensaje, "Error")
                    this.loading = false;
                }
            }).catch((error) => {
                console.log(error);
                this.loading = false;
            });
        },
  },
  mounted(){
      //this.test()
      //this.loading=false
      //console.log(this.connect_metamask())
  }
}
</script>
<style scoped>

.tags{
    margin: 150px 0 0 0;
}
.logo{
    width: 250px;
}
.btn-metamask{
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 2rem 2.5rem;
    background-color: white;
    line-height: 2;
    border-radius: 24px;
    border: 1px solid #4776e559;
    font-weight:bold;
    font-size:30px;
    color: #424242;
    text-decoration: none;
    text-transform: uppercase;
    -webkit-box-shadow: 0 0 30px 2px #4776e51c;
    -moz-box-shadow: 0 0 30px 2px #4776e549;
    box-shadow: 0 0 30px 2px #4776e525;
    width:441.78px;
}
.btn-metamask:hover{
    background-color: rgb(202, 219, 255);
    box-shadow: 0px 7px 10px -8px rgba(0,0,0,0.1);
}

.metamask-spin{
    margin-right:10px;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}
@media only screen
and (max-device-width : 767px)
and (orientation : portrait){
    .btn-metamask{
        font-size:24px!important;
        padding: 1.5rem!important;
        width:100%;
    }
    .btn-login{
        width:100%;
        font-size:24px!important;
        padding: 1.5rem!important;
    }
    .metamask-spin{
        margin-right:5px
    }
}



.dark-mode div::v-deep .modal-content {
  border-color: #2d3748;
  background-color: #1a202c;
}


</style>
