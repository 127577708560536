<template>
    <div>
        <button ref="scrollTopButton" id="to-top" title="press to go back to top"  @click="scrollToTop" ><font-awesome-icon icon="chevron-up" /></button>
    </div>

</template>

<script>



export default {
    name: "to-top",
    components: { 
    },
    data() {
        return {
        }
    },
    computed: {
        
    },
    methods: {
        handleScroll() {
            const scrollBtn = this.$refs.scrollTopButton;
            if (window.scrollY > 300) {
                scrollBtn.classList.add("show");
            } else {
                scrollBtn.classList.remove("show");
            }
        },
        scrollToTop() {
            window.scrollTo({ top: 0, behavior: "smooth" });
        },
    },
    mounted(){
     window.addEventListener("scroll", this.handleScroll);
    }
};
</script>
<style>

    #to-top {
        display: none;
        background-color: #000;
        border:1px solid #fff;
        color: white;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        text-align: center;
        font-size:30px;
        position: fixed;
        bottom: 50px;
        right: 50px;
        transition: background-color .2s,
        opacity .3s, visibility .3s;
        opacity: 0.6;
        z-index: 1;
        -webkit-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.15);
        -moz-box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.15);
        box-shadow: 1px 1px 15px 0px rgba(0, 0, 0, 0.15);
    }

    #to-top:hover {
        background-color: #fff;
        border:1px solid #000;
        color: #000;
    }

    .show{display:block!important}

   
</style>
