<template>
    <div>
        <div  v-cloak>
            <div class="row justify-content-center mt-4" >
                <h1 class="tecnic-title">Añadir marca</h1>
            </div>
            <div class="row justify-content-center mt-4" >
                <div class="col-lg-4 col-md-6 col-sm-12 mb-1 text-center card card-shadow">
                    <div class="card-body">                
                        <h3 for="marca" class="text-left">Marca</h3>
                        <p v-if="loading_brands==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                        <v-select v-else :options="filtered_brands" label="name" v-model="new_brand" @search="filter_brands">
                        </v-select> 
                        <button v-if="adding" class="bttn btn-primar addbtn disabled"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Añadir</button>
                        <button v-else class="bttn btn-primar addbtn"  @click="addBrand()"> <font-awesome-icon icon="plus" class="mr-2"/> Añadir</button>
                    </div>   
                </div>
            </div>
            <div class="row justify-content-center mt-4" >
                <h1 class="tecnic-title">Marcas</h1>
            </div>
              
            <div class="row justify-content-center m-4" >
                <div class="col-lg-6 col-md-12 col-sm-12 text-center card card-shadow">
                    <div class="row justify-content-center pt-4" style="color: #2667F7;border-bottom: 1px solid #2667F7; font-weight: bold;">                  
                        <p class="col-lg-4 col-md-12 col-sm-12">Marca</p>
                        <p class="col-lg-2 col-md-12 col-sm-12">Editar</p>
                        <p class="col-lg-2 col-md-12 col-sm-12">Eliminar</p>
                    </div>        
                    <div v-for="brand in brands" v-bind:key="brand.name" class="row justify-content-center">                        
                        <input v-if="edit_input==brand.id && editing==0" class="col-lg-4 col-md-12 col-sm-12 form-input text-center mb-0"   type="text" v-model="new_brand_name" @keyup.enter="editBrand(brand.id)">
                        <p v-else-if="edit_input==brand.id && editing==1" class="col-lg-4 col-md-12 col-sm-12 form-input"><font-awesome-icon  icon="circle-notch" class="mr-2" spin /></p>                                                
                        <p v-else class="col-lg-4 col-md-12 col-sm-12">{{brand.name}} </p> 
                                                    
                        <a class="col-lg-2 col-md-12 col-sm-12 hand" @click="initEdit(brand)">
                            <font-awesome-icon v-if="edit_input==brand.id && editing==1" icon="circle-notch" class="mr-2" spin />
                            <font-awesome-icon v-else icon="pen" class="mr-2" />                                
                        </a> 
                        <a class="col-lg-2 col-md-12 col-sm-12 hand" @click="deleteBrand(brand.id)">
                            <font-awesome-icon v-if="deleting==true && delete_input==brand.id" icon="circle-notch" class="mr-2" spin />
                            <font-awesome-icon v-else icon="trash" class="mr-2" />
                        </a> 
                    </div>  
                </div>
            </div>  

           <!-- <div class="row justify-content-center mt-4" >
                <div class="col-lg-8 col-md-12 col-sm-12 text-center card card-shadow">
                    <div class="row justify-content-center pt-4" style="color: #2667F7;border-bottom: 1px solid #2667F7; font-weight: bold;">                  
                        <p   class="col-lg-2 col-md-12 col-sm-12" ></p>
                        <p   class="col-lg-2 col-md-12 col-sm-12" >Marca</p>
                        <p   class="col-lg-2 col-md-12 col-sm-12" >Modelo</p>
                        <p   class="col-lg-2 col-md-12 col-sm-12" >Motor</p>
                        <p   class="col-lg-2 col-md-12 col-sm-12" >Tipo combustible</p>
                        <p   class="col-lg-2 col-md-12 col-sm-12" >Variante</p>
                    </div>  
                    <div>
                        <div  v-for="brand in cars.brands" v-bind:key="brand.name">
                            <div  v-for="model in brand.models" v-bind:key="model.name">
                                <div v-for="engine in model.engines" v-bind:key="engine.name">
                                        <div class="row justify-content-center"  v-for="variant in engine.variants" v-bind:key="variant.name">
                                            <p  class="col-lg-2 col-md-12 col-sm-12">
                                                        <a @click="deleteBrand(variant.id)"><font-awesome-icon icon="trash" class="mr-2 red" /></a>
                                                    </p> 
                                            <p  class="col-lg-2 col-md-12 col-sm-12">{{brand.name}}  </p> 
                                            <p v-if="model.name!=0"  class="col-lg-2 col-md-12 col-sm-12">{{model.name}}  </p> 
                                            <p v-else class="col-lg-2 col-md-12 col-sm-12">-</p>
                                            <p v-if="engine.name!=0" class="col-lg-2 col-md-12 col-sm-12">{{engine.name}}  </p> 
                                            <p v-else class="col-lg-2 col-md-12 col-sm-12">-</p>
                                            <p v-if="engine.fuel_type!=0" class="col-lg-2 col-md-12 col-sm-12">{{engine.fuel_type}}  </p> 
                                            <p v-else class="col-lg-2 col-md-12 col-sm-12">-</p>
                                            <p v-if="variant.name!=0"  class="col-lg-2 col-md-12 col-sm-12">{{variant.name}}  </p> 
                                            <p v-else class="col-lg-2 col-md-12 col-sm-12">-</p>
                                            <p v-if="variant.horse_power!=0"  class="col-lg-2 col-md-12 col-sm-12">{{variant.horse_power}}  </p> 
                                            <p v-else class="col-lg-2 col-md-12 col-sm-12">-</p>
                                            
                                        </div>                           
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <font-awesome-icon icon="circle-notch" class="mr-2" spin />
                    </div>
                </div>
            </div>-->
        </div>
</div>
</template>

<script>
import axios from "axios"
    //import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
    //import ToTop from '@/components/ToTop.vue'
import _ from 'underscore'

    export default {
        name: "marca",
        components: {
            //LanguageSwitcher,
            //ToTop
        },
        data() {
            return {
                loading_brands:false,
                adding:false,
                deleting:false,
                editing:2,
                edit_input:undefined,
                delete_input:undefined,
                new_brand_name:'',
                new_brand:{"name":""},
                brands:[],
                filtered_brands:[]
            }
        },
        methods:{
            filter_brands: _.debounce(function(search, loading_brands){
                //let vm=this
                if (search!=""){
                    loading_brands(true)
                    axios.post('/api/brands/filter',{
                        'filter_by': search,
                    }) 
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                                this.filtered_brands=result.data.brands
                                this.filtered_brands.push({"name":search})
                                this.new_brand={"name":search}
                                console.log(result.data.brands)
                                loading_brands(false)
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                                loading_brands(false)
                            }
                    }).catch((error) => {
                        console.log(error);
                        loading_brands(false)                   
                    });
                }
            }, 500),
            addBrand(){
                this.adding=true,
                axios.post('/api/brands/add', {
                    "brand_name":this.new_brand.name
                })
                .then((result) => {
                    console.log("result",result)
                    if (result.data.resultado === "Ok"){
                        this.popToast("success", result.data.mensaje, "Ok")
                        this.getBrands()
                        this.adding=false;
                    }
                    else if (result.data.resultado === "Error"){
                        this.popToast("danger", result.data.mensaje, "Error")
                        this.adding=false;
                    }
                }).catch((error) => {
                    console.log(error);
                    this.adding=false;
                });
            },
            getBrands(){
                this.new_brand={"name":""}
                axios.get('/api/brands/get')
                .then((result) => {
                    console.log("result",result)
                    if (result.data.resultado === "Ok"){
                        this.brands=result.data.brands
                        this.filtered_brands=result.data.brands
                        console.log("brands:",this.brands)
                    }
                    else if (result.data.resultado === "Error"){
                        this.popToast("danger", result.data.mensaje, "Error")
                    }
                }).catch((error) => {
                    console.log(error);
                });
            },
            /*getTableData(){
                axios.get('/api/cars')
                    .then((result) => {
                        console.log(result.data)
                        if (result.data.result === "Ok"){
                            this.cars=result.data.cars
                            console.log(result.data.cars)
                            
                        }
                        else if (result.data.result === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                           
                        }
                    }).catch((error) => {
                        console.log(error);
                       
                    });
            },*/
            initEdit(brand){
                this.edit_input=brand.id
                this.new_brand_name=''
                this.editing=0        
                console.log('click',  brand)                           
            },
            editBrand(id){
                this.editing=1
                if (this.new_brand_name==''){
                    this.popToast("info", "Introduce el nuevo valor")
                    this.editing=2;
                }
                else{     
                    axios.post('/api/brands/edit', {
                        "new_brand_name":this.new_brand_name,
                        "brand_id":id
                    })
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.getBrands()
                            this.editing=2;
                            //this.end=true
                            this.new_brand_name=""
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.editing=2;
                            this.new_brand_name=""
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.editing=2;
                        this.new_brand_name=""
                    });
                }
            },
            deleteBrand(id){
                this.delete_input=id
                this.deleting=true,
                axios.post('/api/brands/delete', {
                    "brand_id":id
                })
                .then((result) => {
                    console.log("result",result)
                    if (result.data.resultado === "Ok"){
                        this.popToast("success", result.data.mensaje, "Ok")
                        this.getBrands()
                        this.deleting=false;
                    }
                    else if (result.data.resultado === "Error"){
                        this.popToast("danger", result.data.mensaje, "Error")
                        this.deleting=false;
                    }
                }).catch((error) => {
                    console.log(error);
                    this.deleting=false;
                });
            },
            popToast(type, message) {
                this.$bvToast.toast(message, {
                    //title: title,
                    variant: type,
                    solid: true,
                    toaster: 'b-toaster-top-center',
                })
            },
        }, 
        
        mounted(){
            this.getBrands()
        },
    }
    </script>
<style scoped>
    .table-label {
    color: #2667f7;
    font-size: 18px!important;
    min-width: 100px;
    text-align: center;
}

</style>