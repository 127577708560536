
<template>
    <div id="admin">
        <div v-cloak>
            <div class="row justify-content-center mt-4" >
                <h1 class="tecnic-title" id="contact"> Añadir modelo</h1>
            </div>
            <div class="row justify-content-center mt-4" >
                <div class="col-lg-4 col-md-6 mb-1 text-center card card-shadow">
                    <div class="card-body">                
                        <h3 for="marca" class="text-left">Marca</h3>
                        <span v-if="loading_brands==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</span>
                        <v-select v-else :options="brands" label="name" :reduce="brands => brands.id" v-model="selected_brand"  @input="getModels()">
                        </v-select> 

                        <h3 for="modelo" class="text-left">Modelo</h3>
                        <p v-if="loading_models==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                        <v-select v-else :options="filtered_models" label="name" v-model="new_model" @search="filter_models">
                        </v-select> 


                        <!--<input class="form-input" type="text" id="modelo" name="modelo" @input="getModels()" v-model="new_model">-->
                        <button v-if="adding" class="bttn btn-primar addbtn disabled"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Añadir</button>
                        <button v-else class="bttn btn-primar addbtn"  @click="addModel()"> <font-awesome-icon icon="plus" class="mr-2" /> Añadir</button>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center m-4" v-if="selected_brand!=''">
                <div class="col-lg-12 col-md-12 col-sm-12 text-center ">
                    <h1 class="tecnic-title">Modelos</h1>
                </div>
              
                <div class="col-lg-6 col-md-12 col-sm-12 text-center card card-shadow">
                    <div class="row justify-content-center pt-4" style="color: #2667F7;border-bottom: 1px solid #2667F7; font-weight: bold;">                  
                        <p class="col-lg-4 col-md-12 col-sm-12">Modelos</p>
                        <p class="col-lg-2 col-md-12 col-sm-12">Editar</p>
                        <p class="col-lg-2 col-md-12 col-sm-12">Eliminar</p>
                    </div>  
                    <div v-for="model in models" v-bind:key="model.name" class="row justify-content-center">
                        <input v-if="edit_input==model.id && editing==0" class="col-lg-4 col-md-12 col-sm-12 form-input text-center mb-0"   type="text" v-model="new_model_name" @keyup.enter="editModel(model.id)">
                        <p v-else-if="edit_input==model.id && editing==1" class="col-lg-4 col-md-12 col-sm-12 form-input"><font-awesome-icon  icon="circle-notch" class="mr-2" spin /></p>                        
                        <p v-else class="col-lg-4 col-md-12 col-sm-12">{{model.name}}</p> 

                        <a class="col-lg-2 col-md-12 col-sm-12 hand" @click="initEdit(model)">
                            <font-awesome-icon v-if="edit_input==model.id && editing==1" icon="circle-notch" class="mr-2" spin />
                            <font-awesome-icon v-else icon="pen" class="mr-2" />                                
                        </a> 
                        <a class="col-lg-2 col-md-12 col-sm-12 hand" @click="deleteModel(model.id)">
                            <font-awesome-icon v-if="deleting==true && delete_input==model.id" icon="circle-notch" class="mr-2" spin />
                            <font-awesome-icon v-else icon="trash" class="mr-2" />
                        </a> 
                    </div>  
                </div>  
            </div>  
        </div>
    </div>
</template>

<script>
import axios from "axios"
    //import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
    //import ToTop from '@/components/ToTop.vue'
import _ from 'underscore'

    export default {
        name: "modelo",
        components: {
            //LanguageSwitcher,
            //ToTop
        },
        data() {
            return {
                loading_brands:false,
                loading_models:false,
                brands:[],
                filtered_models:[],
                models:[],
                adding:false,
                editing:2,
                edit_input:undefined,
                delete_input:undefined,
                deleting:false,
                new_model:{"name":"", "id":null},
                new_model_name:'',
                selected_brand:'',
            }
        },
        methods:{
            filter_models: _.debounce(function(search, loading_models){
                //let vm=this
                if (search!=""){
                    loading_models(true)
                    axios.post('/api/models/filter',{
                        'filter_by': search,
                        'brand_id':this.selected_brand
                    }) 
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                                this.filtered_models=result.data.models
                                this.filtered_models.push({"name":search})
                                console.log(result.data.models)
                                loading_models(false)
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                                loading_models(false)
                            }
                    }).catch((error) => {
                        console.log(error);
                        loading_models(false)                   
                    });
                }
            }, 500),


            getModels(){
                this.loading_models = true;   
                this.filtered_models=[]
                this.models=[]
                
                axios.post('/api/brands/get_models', {
                    "brand_id":this.selected_brand
                })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.models=result.data.models
                            this.filtered_models=result.data.models

                            console.log(result.data.models)
                            this.loading_models = false;
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.loading_models = false;
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.loading_models = false;                        
                    });
            },
            getBrands(){
                axios.get('/api/brands/get')
                .then((result) => {
                    console.log("result",result)
                    if (result.data.resultado === "Ok"){
                        this.brands=result.data.brands
                        console.log("brands:",this.brands)
                    }
                    else if (result.data.resultado === "Error"){
                        this.popToast("danger", result.data.mensaje, "Error")
                    }
                }).catch((error) => {
                    console.log(error);
                });
            },


            addModel(){
                this.adding=true,
                axios.post('/api/models/add', {
                    "brand_id":this.selected_brand,
                    "model_name":this.new_model.name
                })
                .then((result) => {
                    if (result.data.resultado === "Ok"){
                        this.popToast("success", result.data.mensaje, "Ok")
                        this.getModels()
                        this.adding=false;
                        this.new_model={"name":"", "id":null};
                    }
                    else if (result.data.resultado === "Error"){
                        this.popToast("danger", result.data.mensaje, "Error")
                        this.adding=false;
                    }
                }).catch((error) => {
                    console.log(error);
                });
            },
            initEdit(model){
                this.edit_input=model.id
                this.new_model_name=''
                this.editing=0        
                console.log('click',  model)               
            },
            editModel(id){
                this.editing=1
                if (this.new_model_name==''){
                    this.popToast("info", "Introduce el nuevo valor")
                    this.editing=2;
                }
                else{     
                    axios.post('/api/models/edit', {
                        "new_model_name":this.new_model_name,
                        "model_id":id
                    })
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.getModels()
                            this.editing=2;
                            this.new_brand_name=""
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.editing=2;
                            this.new_brand_name=""
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.editing=2;
                        this.new_brand_name=""
                    });
                }
            },
            deleteModel(id){
                this.delete_input=id
                this.deleting=true,
                axios.post('/api/models/delete', {
                    "model_id":id
                })
                .then((result) => {
                    console.log("result",result)
                    if (result.data.resultado === "Ok"){
                        this.popToast("success", result.data.mensaje, "Ok")
                        this.getModels()
                        this.deleting=false;
                    }
                    else if (result.data.resultado === "Error"){
                        this.popToast("danger", result.data.mensaje, "Error")
                        this.deleting=false;
                    }
                }).catch((error) => {
                    console.log(error);
                    this.deleting=false;
                });
            },
            popToast(type, message) {
                this.$bvToast.toast(message, {
                    //title: title,
                    variant: type,
                    solid: true,
                    toaster: 'b-toaster-top-center',
                })
            },
        }, 
      
        mounted:function(){
            this.getBrands()         
        },
    }
    </script>
<style scoped>

</style>