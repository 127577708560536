<template>
    <div id="serie">
        <div class="row justify-content-center mt-4" >
            <h1 class="tecnic-title" id="contact"> Añadir equipamiento extra</h1>
        </div>
        <div class="row justify-content-center mt-4" >
            <div class="col-lg-8 col-md-12 col-sm-12 mb-1 text-left card card-shadow">
                <div class="card-body">       
                    <div class="col-lg-6 col-md-12 col-sm-12 mb-1">                 
                        <h3 for="marca" class="">Marca</h3>
                        <p v-if="loading_brands==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                        <v-select v-else :options="brands" label="name" :reduce="brands => brands.id" v-model="selected_brand" @input="getExtra()">
                        </v-select> 
                    </div>
                    <h3 class="mt-4 pt-2 blue">Español</h3>
                    <div class="row justify-content-center mt-4" >
                        <div class="col-lg-9 col-md-9 col-sm-12 mb-1 ">       
                            <h3 for="nombre" class="">Nombre</h3>
                            <p v-if="loading_default==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                            <v-select v-else :options="filtered_extra_eq" label="name"  @search="filter_default">
                            </v-select> 
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12 mb-1">      
                            <button v-if="translating_names" class="bttn btn-primar addbtn disabled"><font-awesome-icon icon="circle-notch" class="" spin />Traduc</button>
                            <button v-else class="bttn btn-primar addbtn"  @click="translate_name(false)">Traducir</button>
                        </div>
                    </div>
                    <div class="row justify-content-center">
                        <div class="col-lg-9 col-md-9 col-sm-12 mb-1 ">    
                            <h3 for="motor"  class="">Descripción</h3>
                            <textarea rows="3"  class="form-input"  v-model="new_equip.equip_descriptions.es"></textarea>
                        </div>
                        <div class="col-lg-3 col-md-3 col-sm-12 mb-1 ">    
                            <button v-if="translating_descriptions" class="bttn btn-primar addbtn disabled"><font-awesome-icon icon="circle-notch" class="" spin />Traduc</button>
                            <button v-else class="bttn btn-primar addbtn"  @click="translate_descriptions(false)">Traducir</button>
                        </div>
                    </div>
                    <h3 class="mt-4 pt-2 blue">Catalán</h3>
                    <div class="pl-2">
                        <h3 for="nombre" class="">Nombre</h3>
                        <input class="form-input mb-3" type="text" v-model="new_equip.equip_names.cat">                   
                        <h3 for="motor"  class="">Descripción</h3>
                        <textarea rows="3"  class="form-input"  v-model="new_equip.equip_descriptions.cat"></textarea>
                    </div>
                    <h3 class="mt-4 pt-2 blue">Inglés</h3>
                    <div class="pl-2">
                        <h3 for="nombre" class="">Nombre</h3>
                        <input class="form-input mb-3" type="text" v-model="new_equip.equip_names.en">
                        <h3 for="motor"  class="">Descripción</h3>
                        <textarea rows="3"  class="form-input"  v-model="new_equip.equip_descriptions.en"></textarea>
                    </div>
                    <h3 class="mt-4 pt-2 blue">Francés</h3>
                    <div class="pl-2">
                        <h3 for="nombre" class="">Nombre</h3>
                        <input class="form-input mb-3" type="text" v-model="new_equip.equip_names.fr">
                        <h3 for="motor"  class="">Descripción</h3>
                        <textarea rows="3"  class="form-input"  v-model="new_equip.equip_descriptions.fr"></textarea>
                    </div>
                    <div class="text-center">
                        <button v-if="adding" class="bttn btn-primar addbtn disabled mt-4 mb-4"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Añadir</button>
                        <button v-else class="bttn btn-primar addbtn mt-4 mb-4"  @click="addExtra()"> <font-awesome-icon icon="plus" class="mr-2"/> Añadir</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center m-4" v-if="selected_brand!=''">
                <div class="col-lg-12 col-md-12 col-sm-12 text-center ">
                    <h1 class="tecnic-title">Equipamiento extra</h1>
                </div>
              
                <div class="col-lg-6 col-md-12 col-sm-12 text-center card card-shadow">
                    <div class="row justify-content-center pt-4" style="color: #2667F7;border-bottom: 1px solid #2667F7; font-weight: bold;">                  
                        <p class="col-lg-4 col-md-12 col-sm-12">Nombre</p>
                        <p class="col-lg-2 col-md-12 col-sm-12">Editar</p>
                        <p class="col-lg-2 col-md-12 col-sm-12">Eliminar</p>
                    </div>  

                    <div v-for="each in extra_eq" v-bind:key="each.name" class="row justify-content-center">
                        <p  class="col-lg-4 col-md-12 col-sm-12">{{each.name}}</p> 

                        <a class="col-lg-2 col-md-12 col-sm-12 hand" @click="openModalEdit(each)">
                            <font-awesome-icon  icon="pen" class="mr-2" />                                
                        </a> 
                        <a class="col-lg-2 col-md-12 col-sm-12 hand" @click="deleteDefault(each.id)">
                            <font-awesome-icon v-if="deleting==true && delete_input==each.id" icon="circle-notch" class="mr-2" spin />
                            <font-awesome-icon v-else icon="trash" class="mr-2" />
                        </a> 
                    </div>  
                </div>  
            </div>  
            <b-modal id="editDefault"  size="lg" hide-footer scrollable centered>
                <template #modal-header>
                    <a class="x" @click="$bvModal.hide('editDefault')"><font-awesome-icon icon="times" /></a> 
                    <div class="col text-center">
                        <h3 class="bold m-0">Editar equipamiento</h3>
                    </div>                   
                </template>
                <div class="row">
                    <div class="col-12">
                        <h5 for="marca" class="">Marca</h5>
                        <p>{{default_editing.brand.name}}</p>
                        
                        <h3 class="mt-2 blue">Español</h3>
                        <p class="modal-label">Nombre</p>
                        <input class="form-input" type="text" id="default_edit" name="default" v-model="default_editing.equip_names.es">
                        <button v-if="translating_names" class="bttn btn-primar addbtn disabled"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Traduciendo</button>
                        <button v-else class="bttn btn-primar addbtn"  @click="translate_name(true)"> <font-awesome-icon icon="plus" class="mr-2"/> Traducir</button>
                        <p  class="modal-label">Descripción</p>
                        <textarea rows="3"  class="form-input"  v-model="default_editing.equip_descriptions.es"></textarea>
                        <button v-if="translating_descriptions" class="bttn btn-primar addbtn disabled"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Traduciendo</button>
                        <button v-else class="bttn btn-primar addbtn"  @click="translate_descriptions(true)"> <font-awesome-icon icon="plus" class="mr-2"/> Traducir</button>

                        <h3 class="mt-2 blue">Inglés</h3>
                        <p class="modal-label">Nombre</p>
                        <input class="form-input" type="text" id="default_edit" name="default" v-model="default_editing.equip_names.en">
                        <p  class="modal-label">Descripción</p>
                        <textarea rows="3"  class="form-input"  v-model="default_editing.equip_descriptions.en"></textarea>

                        <h3 class="mt-2 blue">Catalán</h3>
                        <p class="modal-label">Nombre</p>
                        <input class="form-input" type="text" id="default_edit" name="default" v-model="default_editing.equip_names.cat">
                        <p  class="modal-label">Descripción</p>
                        <textarea rows="3"  class="form-input"  v-model="default_editing.equip_descriptions.cat"></textarea>

                        <h3 class="mt-2 blue">Francés</h3>
                        <p class="modal-label">Nombre</p>
                        <input class="form-input" type="text" id="default_edit" name="default" v-model="default_editing.equip_names.fr">
                        <p  class="modal-label">Descripción</p>
                        <textarea rows="3"  class="form-input"  v-model="default_editing.equip_descriptions.fr"></textarea>
                      
                        <h3 class="mt-4 blue">Imágenes</h3>
                        <div class="row m-0">
                            <div class="button-wrap">
                                <input type="file" accept="image/*" id="image-file"   @change="onChange">
                            </div>
                            <a v-if="this.file!='' && uploadingPic==true && uploadPercentage<=100" class="btn btn-small btn-primar disabled m-1">Subiendo...{{uploadPercentage}}%</a>
                            <a v-if="this.file!=''  && uploadingPic==false" @click="uploadImage()" class="btn btn-small btn-primar" >Subir imágen</a>
                       
                        </div>
                        <div class="row mt-2" >
                            <div class="col-lg-6 col-md-4 col-sm-12" v-for="image in min_pictures" :key="image.url">
                                <img :src="image.url" alt="Avatar" class="image">
                                <br>
                                <a @click="deleteImg(image.img_name)" class="delete-text">
                                    <font-awesome-icon v-if="deleting_img==true && img_deleting_name==image.img_name" icon="circle-notch" class="mr-2" spin />
                                    <font-awesome-icon v-else icon="trash" class="mr-2" style="color:red" />
                                </a>
                                <a v-if="image.portada==false" @click="setFrontImg(image.img_name)" class="btn-small btn-primar">
                                    Hacer portada
                                </a>
                            </div>
                        </div>
                       
                    </div>
                </div>
                <div class="text-center mt-4">
                    <a class="bttn btn-primar ml-2"  @click="editDefaultEquipment()">Guardar</a>
                    <a class="bttn btn-grey ml-2"  @click="$bvModal.hide('editDefault')">Cerrar</a>
                </div>
            </b-modal>   
    </div>

</template>

<script>
    import axios from "axios"
        //import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
        //import ToTop from '@/components/ToTop.vue'
    import _ from 'underscore'
    
        export default {
            name: "marca",
            components: {
                //LanguageSwitcher,
                //ToTop
            },
            data() {
                return {
                    translating_names:false,
                    translating_descriptions :false,
                    loading_brands:false,
                    loading_default:false,
                    selected_brand:'',
                    new_brand:'',
                    brands:[],
                    new_name:'',
                    new_description:'',
                    new_equip:{"brand_id":"", "equip_names":{"es":"", "cat":"", "en":"","fr":"" }, "equip_descriptions":{"es":"", "cat":"", "en":"", "fr":""}},
                    min_pictures:[],
                    extra_eq:[],
                    filtered_extra_eq:[],

                    edited_name:'',
                    edited_description:'',

                    adding:false,
                    deleting:false,
                    delete_input:undefined,
                    deleting_img:false,
                    default_id:undefined,
                    img_deleting_name:undefined,
                    default_editing:{"brand":{"id":null, "name":""},"id":null, "equip_names":{"es":"", "cat":"", "en":"","fr":"" }, "equip_descriptions":{"es":"", "cat":"", "en":"", "fr":""}},
                    file:undefined,      
                    uploadingPic:false,
                    uploadPercentage:0,

                }
            },
            methods:{
                filter_default: _.debounce(function(search, loading_default){
                    //let vm=this
                    if (search!=""){
                        loading_default(true)
                        axios.post('/api/extra_equipments/filter',{
                            'filter_by': search,
                            'brand_id':this.selected_brand
                        }) 
                        .then((result) => {
                            if (result.data.resultado === "Ok"){
                                    this.filtered_extra_eq=result.data.extra_equipments
                                    this.filtered_extra_eq.push({"name":search})
                                    this.new_equip.equip_names.es=search
                                    console.log(result.data.extra_equipments)
                                    loading_default(false)
                                }
                                else if (result.data.resultado === "Error"){
                                    this.popToast("danger", result.data.mensaje, "Error")
                                    loading_default(false)
                                }
                        }).catch((error) => {
                            console.log(error);
                            loading_default(false)                   
                        });
                    }
                }, 500),
                getBrands(){
                    axios.get('/api/brands/get')
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.brands=result.data.brands
                            console.log("brands:",this.brands)
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                }, 
                getExtra(){
                    axios.post('/api/extra_equipments/get_all_from_brand',{
                            'brand_id': this.selected_brand,
                        }) 
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.extra_eq=result.data.extra_equipments
                            this.filtered_extra_eq=result.data.extra_equipments
                            console.log("extra_equipments:",this.extra_eq)
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },
                addExtra(){
                    this.adding=true,
                    axios.post('/api/extra_equipments/add', {
                        "brand_id":this.selected_brand,
                        "equip_names":this.new_equip.equip_names,
                        "equip_descriptions":this.new_equip.equip_descriptions,
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.getExtra()
                            this.adding=false;
                            this.new_equip={"brand_id":"", "equip_names":{"es":"", "cat":"", "en":"","fr":"" }, "equip_descriptions":{"es":"", "cat":"", "en":"", "fr":""}}  
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.adding=false;
                                
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.new_description="",
                        this.adding=false;

                    });
                },
                editDefaultEquipment(){
                    this.adding=true,
                    axios.post('/api/extra_equipments/edit', {
                        "equip_id":this.default_editing.id,
                        "equip_names":this.default_editing.equip_names,
                        "equip_descriptions":this.default_editing.equip_descriptions,
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.getExtra()
                            this.adding=false;
                            //this.new_name={"name":"", "id":null};
                            //this.new_description=""
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.adding=false;
                            //this.new_name={"name":"", "id":null};
                            //this.new_description=""
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.new_name={"name":"", "id":null};
                        this.new_description=""
                    });
                },
                deleteImg(img_name){
                    this.img_deleting_name=img_name
                    this.deleting_img=true
                    axios.post('/api/extra_equipments/pictures/delete',{
                            'img_name': img_name,
                        }) 
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.min_pictures=result.data.images
                            this.deleting_img=false
                            this.img_deleting_name=undefined
                            //console.log("getPicturesMin:",this.min_pictures)
                            this.getPicturesMin()
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },
              
                setFrontImg(img_name){
                    axios.post('/api/extra_equipments/pictures/set_front',{
                            'img_name': img_name,
                        }) 
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.min_pictures=result.data.images
                            //console.log("getPicturesMin:",this.min_pictures)
                            this.getPicturesMin()
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },
                getPicturesMin(){
                    axios.post('/api/extra_equipments/pictures/get_all_min',{
                            'equip_id': this.default_editing.id,
                        }) 
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.min_pictures=result.data.images
                            console.log("getPicturesMin:",this.min_pictures)
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },

                openModalEdit(extra_equip){
                    //this.default_id=default_id
                    this.default_editing=extra_equip
                    this.getPicturesMin()
                    this.$bvModal.show('editDefault')
                },

                onChange(e) {
                    this.file = e.target.files[0]
                },

                uploadImage() {
                    this.uploadingPic = true;
                    let vm=this
                    const URL = '/api/extra_equipments/add_picture'; 
                    let data = new FormData();
                   
                    data.append('file', this.file);
                    data.append('equip_id', this.default_editing.id)
                    let config = {
                            header : {
                                'Content-Type' : 'image/png'
                            },
                            onUploadProgress: function( progressEvent ) {
                                this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                            }.bind(this)
                        };
                    axios.post(URL,data,config)
                        .then(function(res) {
                            var status=res.data.resultado
                            vm.mensaje=res.data.mensaje
                            if (status=="Ok") {
                                vm.uploadingPic = false;
                                vm.uploadPercentage=0
                                vm.popToast("success", vm.mensaje, "Correcto")
                                vm.getPicturesMin()
                            }
                            else{
                                vm.uploadingPic = false;
                                vm.uploadPercentage=0
                                vm.popToast("danger", vm.mensaje, "Error")
                                //vm.closeCamera()
                            }
                        }).catch(function(err) {
                            console.log(err)
                            vm.uploadingPic = false;
                            vm.uploadPercentage=0
                            vm.popToast("danger", vm.mensaje, "Error")
                            //vm.closeCamera()
                        })
                },
                deleteDefault(id){
                    this.delete_input=id
                    this.deleting=true,
                    axios.post('/api/extra_equipments/delete', {
                        "equip_id":id
                    })
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.getExtra()
                            this.deleting=false;
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.deleting=false;
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.deleting=false;
                    });
                },
                translate_name(is_editing){
                    let text=""
                    if (is_editing==false){
                        text=this.new_equip.equip_names.es
                    }
                    else{
                        text=this.default_editing.equip_names.es
                    }
                    this.translating_names=true
                    axios.post('/api/extra_equipments/translate',{
                            'text': text
                        }) 
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            if (is_editing==false){
                                this.new_equip.equip_names.cat=result.data.texts.cat
                                this.new_equip.equip_names.en=result.data.texts.en
                                this.new_equip.equip_names.fr=result.data.texts.fr

                            }
                            else{
                                this.default_editing.equip_names.cat=result.data.texts.cat
                                this.default_editing.equip_names.en=result.data.texts.en
                                this.default_editing.equip_names.fr=result.data.texts.fr
                                
                            }
                            
                            
                            //console.log("translated text:",result.data.texts.eng)
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                        this.translating_names=false
                    }).catch((error) => {
                        this.translating_names=false
                        console.log(error);
                    });
                },

                translate_descriptions(is_editing){
                    let text=""
                    if (is_editing==false){
                        text=this.new_equip.equip_descriptions.es
                    }
                    else{
                        text=this.default_editing.equip_descriptions.es
                    }
                    this.translating_descriptions=true
                    axios.post('/api/extra_equipments/translate',{
                            'text': text
                        }) 
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            if (is_editing==false){
                                this.new_equip.equip_descriptions.cat=result.data.texts.cat
                                this.new_equip.equip_descriptions.en=result.data.texts.en
                                this.new_equip.equip_descriptions.fr=result.data.texts.fr
                            }
                            else{
                                this.default_editing.equip_descriptions.cat=result.data.texts.cat
                                this.default_editing.equip_descriptions.en=result.data.texts.en
                                this.default_editing.equip_descriptions.fr=result.data.texts.fr
                            }
                            
                            
                            //console.log("translated text:",result.data.texts.eng)
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                        this.translating_descriptions=false
                    }).catch((error) => {
                        this.translating_descriptions=false
                        console.log(error);
                    });
                },

                popToast(type, message) {
                    this.$bvToast.toast(message, {
                        //title: title,
                        variant: type,
                        solid: true,
                        toaster: 'b-toaster-top-center',
                    })
                },
        }, 
        
        mounted(){
            this.getBrands()
        },
    }
    </script>
    <style scoped>
    .image{
        max-width: 300px;
        max-height: 200px;
    }
 
    </style>