<template>
    <div>

        <div class="row justify-content-center mt-4" >
                <h1 class="tecnic-title">Empleados</h1>
            </div>
            <div class="row justify-content-center mt-4" >
                <button  class="bttn btn-primar"  @click="openNewEmployee()"> <font-awesome-icon icon="plus" class="mr-2"/>Nuevo empleado</button>
            </div>

        <div class="row justify-content-center m-4" >
            <div class="col-lg-9 col-md-12 col-sm-12 text-center card card-shadow">
                <div class="row justify-content-center pt-4" style="color: #2667F7;border-bottom: 1px solid #2667F7; font-weight: bold;">                  
                    <p class="col-lg-2 col-md-12 col-sm-12">Nombre</p>                                    
                    <p class="col-lg-2 col-md-12 col-sm-12">ID</p>
                    <p class="col-lg-2 col-md-12 col-sm-12">Dirección</p>
                    <p class="col-lg-2 col-md-12 col-sm-12">Editar/Info</p>
                    <p class="col-lg-2 col-md-12 col-sm-12">Eliminar</p>
                </div>        
                  <div v-for="employee in employees" v-bind:key="employee.name" class="row justify-content-center">
                        <p  class="col-lg-2 col-md-12 col-sm-12">{{employee.name}}</p> 
                        <p  class="col-lg-2 col-md-12 col-sm-12">{{employee.id_number}}</p> 
                        <p  class="col-lg-2 col-md-12 col-sm-12">{{employee.address}}</p> 

                        <a class="col-lg-2 col-md-12 col-sm-12 hand" @click="openEditEmployee(employee)">
                            <font-awesome-icon  icon="pen" class="mr-2" />                                
                        </a> 
                        <a class="col-lg-2 col-md-12 col-sm-12 hand" @click="deleteEmployee(employee.id)">
                            <font-awesome-icon v-if="deleting==true && delete_input==employee.id" icon="circle-notch" class="mr-2" spin />
                            <font-awesome-icon v-else icon="trash" class="mr-2" />
                        </a> 
                    </div>  
                </div>  
            </div>
            <b-modal id="newEmployee"  size="lg" hide-footer scrollable centered>
                <template #modal-header>
                    <a class="x" @click="$bvModal.hide('newEmployee')"><font-awesome-icon icon="times" /></a> 
                    <div class="col text-center">
                        <h3 class="bold m-0">Nuevo empleado</h3>
                    </div>                   
                </template>
                <div class="row">
                    <div class="col-12">
                        <p class="modal-label">Nombre</p>
                        <input class="form-input" type="text"  v-model="new_employee.name">                     
                        <p for="default_edit" class="modal-label">Número de identidad</p>
                        <input class="form-input" type="text" v-model="new_employee.id_number">
                        <p for="default_edit" class="modal-label">Dirección</p>
                        <input class="form-input" type="text" v-model="new_employee.address">                                     
                    </div>
                </div>
                <div class="text-center mt-4">
                    <a class="bttn btn-primar ml-2"  @click="addEmployee()">Guardar</a>
                    <a class="bttn btn-grey ml-2"  @click="$bvModal.hide('newEmployee')">Cerrar</a>
                </div>
            </b-modal>
            <b-modal id="editEmployeeModal"  size="lg" hide-footer scrollable centered>
                <template #modal-header>
                    <a class="x" @click="$bvModal.hide('editEmployeeModal')"><font-awesome-icon icon="times" /></a> 
                    <div class="col text-center">
                        <h3 class="bold m-0">Información del empleado</h3>
                    </div>                   
                </template>
                <div class="row">
                    <div class="col-12">
                        <p for="default_edit" class="modal-label">Nombre</p>
                        <input class="form-input" type="text" v-model="current_employee.name">
                        <p for="default_edit" class="modal-label">Número de identidad</p>
                        <input class="form-input" type="text" v-model="current_employee.id_number">
                        <p for="default_edit" class="modal-label">Dirección</p>
                        <input class="form-input" type="text" v-model="current_employee.address">

                    </div>
                </div>
                <div class="text-center mt-4">
                    <a class="bttn btn-primar ml-2"  @click="editEmployee()">Guardar</a>
                    <a class="bttn btn-grey ml-2"  @click="$bvModal.hide('editEmployeeModal')">Cerrar</a>
                </div>
            </b-modal>    
</div>
</template>

<script>
    import axios from "axios"
        //import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
        //import ToTop from '@/components/ToTop.vue'
    import _ from 'underscore'
    
        export default {
            name: "empleados",
            components: {
                //LanguageSwitcher,
                //ToTop
            },
            data() {
                return {
                    new_employee:{
                        'name':'',
                        'id_number':'',
                        'address':'',
                    },
                    current_employee:{
                        'name':'',
                        'id_number':'',
                        'address':'',
                        'id':undefined,
                    },
                    employees:[],                   
                    loading_employees:false,
                    editing:false,
                    adding:false,
                    
                    deleting:false,
                    delete_input:undefined
                }
            },
            methods:{
                getEmployees(){
                    this.loading_employees=true
                    axios.post('/api/employees/get_all')
                        .then((result) => {
                            console.log("result",result)
                            if (result.data.resultado === "Ok"){
                                this.employees=result.data.employees
                                console.log("employees:",this.employees)
                                this.loading_employees=false
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                                this.loading_employees=false
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.loading_employees=false
                        });
                },
              
                addEmployee(){
                    this.adding=true,
                    axios.post('/api/employees/add', {
                        "name":this.new_employee.name,
                        "id_number":this.new_employee.id_number,
                        "address":this.new_employee.address,
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.getEmployees()
                            this.adding=false;
                            this.new_employee={
                                'name':'',
                                'id_number':'',
                                'address':'',
                            }
                            this.$bvModal.hide('newEmployee')
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.adding=false;
                            
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },


                editEmployee(){
                    this.editing=true,
                    axios.post('/api/employees/edit', {
                        "employee_id": this.current_employee.id,
                        "new_name":this.current_employee.name,
                        "new_id_number":this.current_employee.id_number,
                        "new_address":this.current_employee.address,
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.getEmployees()                            
                            this.editing=false
                            this.$bvModal.hide('editEmployeeModal')
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.editing=false;
                            
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },

                deleteEmployee(employee_id){
                    this.deleting=true,
                    this.delete_input=employee_id
                    axios.post('/api/employees/delete', {
                        "employee_id": employee_id,
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.getEmployees()                            
                            this.deleting=false
                            this.delete_input=undefined
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.deleting=false;
                            
                        }
                    }).catch((error) => {
                        console.log(error);
                    });

                },
                openNewEmployee(){
                   this.new_employee={
                        'name':'',
                        'id_number':'',
                        'address':'',
                    }
                    this.$bvModal.show('newEmployee')
                },

                openEditEmployee(employee){
                   this.loading_employee=true
                   axios.post('/api/employees/get', {
                        "employee_id":employee.id
                    })
                        .then((result) => {
                            console.log("result",result)
                            if (result.data.resultado === "Ok"){
                                this.current_employee=result.data.employee_info
                                this.loading_employee=false
                                console.log('current_employee',this.current_employee)
                                this.$bvModal.show('editEmployeeModal')
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                                this.loading_employee=false
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.loading_employee=false
                        });
                    
                    
                },
             
                popToast(type, message) {
                    this.$bvToast.toast(message, {
                        //title: title,
                        variant: type,
                        solid: true,
                        toaster: 'b-toaster-top-center',
                    })
                },
        }, 
        
        mounted(){
            this.getEmployees()
        },
    }
    </script>
    <style scoped>

    </style>