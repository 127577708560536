<template>
  <div id="admin">
    <SideBar/>
    <router-view ></router-view>
    </div>

</template>

<script>
/* eslint-disable */ 
const ethers=require('ethers')
import axios from "axios"

//const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
//var Web3 = require('web3');

//var web3 = new Web3(Web3.givenProvider);
// const Notary=require('./contracts/NotaryContract.json');
/*let description={
    "equip_serie":[
        {
        "nombre":"",
        "descripcion":""
        }
    ],
    "extras":[
        {
            "nombre":"",
            "fotos":[],
            "descripcion":""
        }
    ],
    "descripcion":"texto",
    "fotos":[]
}*/
import SideBar from '@/components/SidebarAdmin.vue'

export default {
    name: "Admin",
    components: { 
        SideBar
    },
    data() {
        return {
            w3: web3,
            address_owner_end:'',
            address_owner_init:'',
            //notary_contract:undefined,
            wallet_manager:undefined,
            connected:false,
            loading:false,
            isAdmin:false
       
        }        
    },
    computed: {
        check_account_change: function(){
          console.log("checking change")
          window.ethereum.on('accountsChanged', function (accounts) {
              console.log("account changed")
        // Time to reload your interface with accounts[0]!
        })
      }
    },
    methods: {
        adminCheck(){
            this.loading = true;
            axios.get('/api/check_admin')
            .then((result) => {
                if (result.data.resultado === "Ok"){
                    this.isAdmin=result.data.is_admin
                    console.log(result.data.is_admin)
                    this.loading = false;
                    if (this.isAdmin!=true){
                        this.$router.push({ path: '/user/coches_stock' })
                    }
                }
                else if (result.data.resultado === "Error"){
                    this.popToast("danger", result.data.mensaje, "Error")
                    this.loading = false;
                }
            }).catch((error) => {
                console.log(error);
                this.loading = false;
            });
        },
       
    
  },
  mounted: function(){
    if (typeof window.ethereum !== 'undefined') {
        console.log('MetaMask is installed!');
        //this.connect_metamask()
    }
    else{
        console.log("Metamask not installed")
    }
    this.adminCheck()
  }
};
</script>
<style>
   
.title-car{
    color: #000;
    text-transform: uppercase;
    font-size: 4rem;
    text-align: center;
    font-weight:bold;
    margin: 1rem 0;
}
.title-price{
    color: #0064BF;
    text-transform: uppercase;
    font-size: 6rem;
    text-align: center;
    font-weight:bold;
    margin: 1rem 0;
}
.tecnic-title{
  font-size:2.6rem!important;
  margin-bottom: 20px;
  font-weight: bold;
  margin-top: 40px;
}


</style>

