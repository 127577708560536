<template>
    <div>

        <div class="row justify-content-center mt-4" >
            <h1 class="tecnic-title">Coches vendidos</h1>
        </div>
        <div class="row justify-content-center mt-4" >
            <a v-if="metamask_connected==false" class="bttn btn-primar" @click="connect_metamask()">Conectar metamask</a>
        </div>
        <div class="row justify-content-center mt-4" >
                <input type="text" class="form-input mb-2" style="max-width: 300px" v-model="searchText" placeholder="Buscar..." @keyup="search" autocomplete="disable">
        </div>
        <div class="row justify-content-center">
            <div class="spinner-table"  id="spin">
                <img src="/logo-spinner.svg" alt="spinner"  id="nav-logo">
            </div>
        </div>
        <div class="row m-4" style="justify-content:right">
            <div class="col-lg-10 col-md-12 col-sm-12 text-center card card-shadow">
                <vuetable style="overflow-x: auto" ref="vuetable"
                            :css="css.table"
                            api-url="/api/cars/table/get"
                            :fields="fields"
                            data-path="data"
                            pagination-path="links.pagination"
                            :sort-order="sortOrder"
                            :show-sort-icons="true"
                            :per-page="10"
                            :append-params="moreParams"
                            :http-fetch="solveAuthIssuesOtherComponentsGet"
                            @vuetable:pagination-data="onPaginationData"
                            @vuetable:loading="onLoading"
                            @vuetable:loaded="onLoaded"
                            >
                            <div slot="chasis-slot" slot-scope="props">
                                    <p>{{props.rowData.chassis_number }} </p>
                            </div>
                            <div slot="model" slot-scope="props">
                                    <p>{{props.rowData.model }} </p>
                            </div>                          
                            <div slot="buyer" slot-scope="props">
                                    <p>{{props.rowData.buyer }}</p>
                            </div>
                            <div slot="sold_date" slot-scope="props">
                                    <p>{{props.rowData.sold_date }}</p>
                            </div>
                          
                            <div slot="edit" slot-scope="props">
                                <a class="hand" @click="openEditCar(props.rowData.id)">
                                    <font-awesome-icon  icon="pen" class="mr-2" />                                
                                </a> 
                            </div>
                            <div slot="generate_contract" slot-scope="props">
                                <a class="hand" @click="openGenerateContract(props.rowData)">
                                    <font-awesome-icon  icon="file-contract" class="mr-2 black" />                                
                                </a> 
                            </div>
                            <div slot="warranty" slot-scope="props">
                               <a v-if="props.rowData.warranty_available==true" class="hand" @click="openWarrantyModal(props.rowData)">                           
                                    <font-awesome-icon  icon="wrench" class="mr-2 green" />                            
                                </a> 
                                <a v-else class="hand" @click="openWarrantyModal(props.rowData)">                           
                                    <font-awesome-icon v-if="getting_warranty==true && warranty_spinner==props.rowData.id" icon="circle-notch" class="mr-2" spin />                           
                                    <font-awesome-icon v-else icon="wrench" class="mr-2 black" />                             
                                </a> 
                            </div>   
                            <div slot="invoice" slot-scope="props">
                                <a class="hand" @click="openInvoiceModal(props.rowData)">
                                    <font-awesome-icon v-if="props.rowData.has_invoice==true" icon="file-invoice-dollar" class="mr-2 green" />  
                                    <font-awesome-icon v-else icon="file-invoice-dollar" class="mr-2" />      
                                </a> 
                            </div>         
                            <div slot="delete" slot-scope="props">
                                <a class="hand" @click="openDeleteCar(props.rowData.id)">
                                    <font-awesome-icon icon="trash" class="mr-2 red" />
                                </a> 
                            </div>                                                                     
                </vuetable>
                <vuetable-pagination-info ref="paginationInfo"
                :info-template="pagination_info_template"
                ></vuetable-pagination-info>

                <vuetable-pagination ref="pagination"
                :css="css.pagination"
                @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
            </div>
        </div>
        <!--
        <div class="row m-4" style="justify-content:right">
            <div class="col-lg-10 col-md-12 col-sm-12 text-center card card-shadow">
                <div class="row justify-content-center pt-4" style="color: #2667F7;border-bottom: 1px solid #2667F7; font-weight: bold;">                  
                    <p class="col-lg-1 col-md-12 col-sm-12">Nºchasis</p>
                    <p class="col-lg-1 col-md-12 col-sm-12">Modelo</p>                                 
                    <p class="col-lg-3 col-md-12 col-sm-12">Comprador</p>
                    <p class="col-lg-1 col-md-12 col-sm-12">Fecha compra</p>
                    <p class="col-lg-1 col-md-12 col-sm-12">Editar/Info</p>                    
                    <p class="col-lg-1 col-md-12 col-sm-12">Contrato</p>
                    <p class="col-lg-1 col-md-12 col-sm-12">Garantia</p>
                    <p class="col-lg-1 col-md-12 col-sm-12">Factura</p>
                    <p class="col-lg-1 col-md-12 col-sm-12">Eliminar</p>
                </div>        
                <div v-for="car in cars" v-bind:key="car.id" class="row justify-content-center mt-2">
                        <p  class="col-lg-1 col-md-12 col-sm-12">{{car.chassis_number}}</p> 
                        <p  class="col-lg-1 col-md-12 col-sm-12">{{car.model}}</p> 
                        <p  class="col-lg-3 col-md-12 col-sm-12">{{car.buyer.name}}</p> 
                        <p  class="col-lg-1 col-md-12 col-sm-12">{{car.sold_date}}</p> 
                      
                        <a class="col-lg-1 col-md-12 col-sm-12 hand" @click="openEditCar(car.id)">
                            <font-awesome-icon  icon="pen" class="mr-2" />                                
                        </a> 
                        <a class="col-lg-1 col-md-12 col-sm-12 hand" @click="openGenerateContract(car)">
                            <font-awesome-icon  icon="file-contract" class="mr-2 black" />                                
                        </a> 
                        <a v-if="car.warranty_available==true" class="col-lg-1 col-md-12 col-sm-12 hand" @click="openWarrantyModal(car)">                           
                            <font-awesome-icon  icon="wrench" class="mr-2 green" />                            
                        </a> 
                        <a v-else class="col-lg-1 col-md-12 col-sm-12 hand" @click="openWarrantyModal(car)">                           
                            <font-awesome-icon v-if="getting_warranty==true && warranty_spinner==car.id" icon="circle-notch" class="mr-2" spin />                           
                            <font-awesome-icon v-else icon="wrench" class="mr-2 black" />                             
                        </a> 
                        <a class="col-lg-1 col-md-12 col-sm-12 hand" @click="openInvoiceModal(car)">
                            <font-awesome-icon v-if="car.has_invoice==true" icon="file-invoice-dollar" class="mr-2 green" />  
                            <font-awesome-icon v-else icon="file-invoice-dollar" class="mr-2" />      
                        </a> 
                        <a class="col-lg-1 col-md-12 col-sm-12 hand" @click="openDeleteCar(car.id)">
                            <font-awesome-icon icon="trash" class="mr-2 red" />
                        </a> 
                    </div>  
                </div>  
            </div>-->

            <b-modal id="modalGenerateContract"  size="md" hide-footer scrollable centered>
                <template #modal-header>
                    <a class="x" @click="$bvModal.hide('modalGenerateContract')"><font-awesome-icon icon="times" /></a>              
                    <div class="col text-center">
                        <h3 class="bold m-0">Contratos</h3>
                    </div>                
                </template>
                <div class="text-center">
                    <a class="bttn btn-primar ml-2"  @click="generactContract('test_drive')">
                        <font-awesome-icon v-if="deleting==true" icon="circle-notch" class="mr-2" spin />
                        <font-awesome-icon v-else icon="trash" class="mr-2 white" />
                        Contrato prueba
                    </a>
                    <a class="bttn btn-primar ml-2  mt-4"  @click="generactContract('buy')">
                        <font-awesome-icon v-if="deleting==true" icon="circle-notch" class="mr-2" spin />
                        <font-awesome-icon v-else icon="trash" class="mr-2 white" />
                        Contrato compraventa
                    </a>
                    
                    <a class="bttn btn-primar ml-2 mt-4"  @click="generactContract('govern')">
                        <font-awesome-icon v-if="deleting==true " icon="circle-notch" class="mr-2" spin />
                        <font-awesome-icon v-else icon="trash" class="mr-2 white" />
                        Contrato Govern
                    </a>
                    <a class="bttn btn-primar ml-2 mt-4"  @click="generactContract('collect')">
                        <font-awesome-icon v-if="deleting==true " icon="circle-notch" class="mr-2" spin />
                        <font-awesome-icon v-else icon="trash" class="mr-2 white" />
                        Contrato Recogida
                    </a>
  
                </div>  
            </b-modal>    
            <b-modal id="invoiceModal"  size="lg" hide-footer scrollable centered>
                <template #modal-header>
                    <a class="x" @click="$bvModal.hide('invoiceModal')"><font-awesome-icon icon="times" /></a> 
                    <div class="col text-center">
                        <h3 class="bold m-0">Gestionar factura</h3>
                    </div>                   
                </template>
                <div class="row justify-content-center">
                    <div class="col-4">
                        <p class="modal-label bold">Nºchasis</p>
                        <p>{{selected_car.chassis_number}}</p>
                        <p class="modal-label bold">Fecha de compra</p>
                        <p>{{selected_car.sold_date}}</p>     
                    </div>
                    <div class="col-4">
                        <p class="modal-label bold">Comprador</p>
                        <p>{{selected_car.buyer.name}}</p>    
                        <span  v-if="selected_car.has_invoice==true">
                            <p class="modal-label bold">Método de pago</p>                        
                            <v-select @input="savePaymentMethod(current_invoice_info.payment_method.id, selected_car.id)" :options="payment_methods" label="name" v-model="current_invoice_info.payment_method">
                            </v-select> 
                        </span>
                        
                    </div>                            
                    <div class="col-4">
                        <p class="modal-label bold">Modelo</p>
                        <p>{{selected_car.model}}</p> 
                        <span  v-if="selected_car.has_invoice==true">                            
                            <p class="modal-label bold">Estado de la factura</p>                        
                            <button v-if="changing_state==true"  class="bttn btn-primar disabled" ><font-awesome-icon icon="circle-notch" class="mr-2" spin />Emitiendo...</button>
                            <button v-else class="bttn btn-primar"  @click="changeInvoiceState(selected_car.id)">
                                <span v-if="changing_state==true && current_invoice_info.completed==false"> <font-awesome-icon  icon="circle-notch" class="mr-2" spin />Cambiando</span>                           
                                <span v-if="current_invoice_info.completed==true "><font-awesome-icon icon="unlock" class="mr-2"/>Abrir</span>
                                <span v-else><font-awesome-icon icon="lock" class="mr-2"/>Cerrar</span>
                            </button>
                        </span>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-12">
                        <p class="modal-label bold">Notas de la factura</p>
                        <textarea style="width:100%" rows="10" v-model="current_invoice_info.invoice_notes"></textarea>
                        
                    </div>
                </div>
             
                <div  class="row justify-content-center mt-4">
                    <a class="bttn btn-primar ml-2"  @click="downloadInvoice(selected_car,current_invoice_info)">
                        <font-awesome-icon v-if="downloading==true" icon="circle-notch" class="mr-2" spin />
                        <font-awesome-icon v-else icon="download" class="mr-2" />
                        Guardar y descargar
                    </a>        
                    <span v-if="selected_car.has_invoice==true">                                                                        
                        <a class="bttn btn-red ml-2"  @click="deleteInvoice(selected_car)">
                            <font-awesome-icon v-if="deleting==true" icon="circle-notch" class="mr-2" spin />
                            <font-awesome-icon v-else icon="trash" class="mr-2 " />
                            Eliminar
                        </a>
                    </span>
                </div>            
            </b-modal>  

            <b-modal id="modalDeleteCar"  size="md" hide-footer scrollable centered>
                <template #modal-header>
                    <a class="x" @click="$bvModal.hide('modalDeleteCar')"><font-awesome-icon icon="times" /></a>              
                </template>
                <div class="text-center">
                    <h3>¿Estás seguro?</h3>
                    <a class="bttn btn-red ml-2"  @click="deleteCar(id)">
                        <font-awesome-icon v-if="deleting==true " icon="circle-notch" class="mr-2" spin />
                        <font-awesome-icon v-else icon="trash" class="mr-2 white" />
                        Eliminar
                    </a>
                    <a class="bttn btn-grey ml-2"  @click="$bvModal.hide('modalDeleteCar')">Cerrar</a>
  
                </div>  
            </b-modal>    
            <b-modal id="warrantyModal"  size="md" hide-footer scrollable centered>
                <template #modal-header>
                    <a class="x" @click="$bvModal.hide('warrantyModal')"><font-awesome-icon icon="times" /></a> 
                    <div class="col text-center">
                        <h3 class="bold m-0">Garantia del vehículo</h3>
                    </div>                   
                </template>
                <div v-if="selected_car.warranty_available==false" class="row justify-content-center">                
                    <div>
                        <p class="modal-label blue">Subir documento:</p>                   
                    </div>
                    <div class="col text-center">
                        <div class="button-wrap mb-4">
                            <input type="file" accept="pdf" id="image-file" class="mb-3"  @change="onChange">
                        </div>                    
                        <button v-if="this.file!='' && uploadingWarranty==true && uploadPercentage<=100" class="btn btn-small btn-primar disabled m-1">Subiendo...{{uploadPercentage}}%</button>
                        <button v-if="this.file!=''  && uploadingWarranty==false" @click="uploadWarranty()" class="btn btn-small btn-primar hand" >Subir archivo</button>                       
                    </div>
                </div>  
                <div v-else class="">
                    <div class="row justify-content-center">
                        <div class="col-6">
                            <p class="modal-label bold">Nº chasis</p>
                            <p>{{selected_car.chassis_number}}</p>
                            <p class="modal-label bold">Modelo</p>
                            <p>{{selected_car.model}}</p>
                        </div>
                        <div class="col-6">
                            <p class="modal-label bold">Comprador</p>
                            <p>{{selected_car.buyer.name}}</p>                    
                            <p class="modal-label bold">Fecha de compra</p>
                            <p>{{selected_car.sold_date}}</p>               
                        </div>
                    </div>
                    <div class="row justify-content-center mt-4">

                        <a class="bttn btn-primar ml-2" v-bind:href="warranty_info.url" target="_blank">
                            <font-awesome-icon v-if="downloading==true" icon="circle-notch" class="mr-2" spin />
                            <font-awesome-icon v-else icon="download" class="mr-2" />
                            Descargar
                        </a>                                                   
                        <a class="bttn btn-red ml-2"  @click="deleteWarranty()">
                            <font-awesome-icon v-if="deleting==true" icon="circle-notch" class="mr-2" spin />
                            <font-awesome-icon v-else icon="trash" class="mr-2 " />
                            Eliminar
                        </a>
                    </div>
                </div>         
            </b-modal>    

            <b-modal id="editCarModal"  size="xl" hide-footer scrollable centered>
                <template #modal-header>
                    <a class="x" @click="$bvModal.hide('editCarModal')"><font-awesome-icon icon="times" /></a> 
                    <div class="col text-center">
                        <h3 class="bold m-0">Información de la venta</h3>
                    </div>                   
                </template>
                <h3 class="blue">Detalles de la venta</h3>
               
                <div class="row">
                    <div class="col-lg-3 col-md-5 col-sm-12">
                        <p class="modal-label">Fecha de compra</p>
                        <input class="form-input" type="date" v-model="current_car.sold_date">
                    </div> 
                    <div class="col-lg-6 col-md-5 col-sm-12 p-0">
                        <p class="modal-label">Comprador</p>
                        <p v-if="loading_buyers==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                        <v-select v-else :options="customers" label="name" v-model="current_car.buyer">
                        </v-select> 
                    </div>
                   
                    <div class="col-lg-3 col-md-5 col-sm-12">
                        <button v-if="adding_buyer==true" class="bttn btn-green ml-2 disabled addbtn"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Actualizar</button>
                        <button v-else class="bttn btn-green ml-2 addbtn"  @click="editCar(current_car)"><font-awesome-icon icon="plus" class="mr-2"/>Actualizar</button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-5 col-sm-12" >
                        <p class="modal-label">Asignar empleado</p>
                        <v-select :options="employees" label="name" v-model="current_car.employee_in_charge" @input="assignEmployee()">
                        </v-select> 
                    </div>
                </div>   
                <div v-if="current_car.has_nft==true">
                    <h3 class="blue mt-4">Venta cripto</h3>
                
                    <div class="row" >
                        <div class="col-lg-6 col-md-5 col-sm-12">
                            <p class="modal-label">Comprador</p>
                            <p  type="text" >{{current_car.nft_info.BuyerAddress}}</p><!--{{current_car.crypto_sell.wallet}}-->
                        </div> 
                        <div class="col-lg-6 col-md-5 col-sm-12">
                            <p class="modal-label">Token usado</p>
                            <p type="text" >{{current_car.nft_info.PaymentToken}}</p><!--{{current_car.crypto_sell.token}}-->
                        </div> 
                        <div class="col-lg-6 col-md-5 col-sm-12">
                            <p class="modal-label">Reclamar pago</p>
                            <div v-if="current_car.nft_info.PaymentClaimed==false">
                                <button v-if="claiming_payment==true"  class="bttn btn-green disabled" ><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cobrando...</button>
                                <button v-else class="bttn btn-green"  @click="claimCarPayment()"><font-awesome-icon icon="money-bill-wave" class="mr-2"/>Cobrar</button>
                            </div>
                            <div v-else>
                                <p>Pago ya reclamado</p>
                            </div>
                            
                        </div> 
                        <div class="col-lg-6 col-md-5 col-sm-12">
                            <p class="modal-label">Reembolso</p>                    
                            <span v-if="current_car.nft_info.RefundEmmited==false && current_car.nft_info.RefundClaimed==false">
                                <div v-if="current_car.nft_info.payment_token_approved==true">
                                    <button v-if="emiting==true"  class="bttn btn-primar disabled" ><font-awesome-icon icon="circle-notch" class="mr-2" spin />Emitiendo...</button>
                                    <button v-else class="bttn btn-primar"  @click="createPaymentRefund()"><font-awesome-icon icon="money-bill" class="mr-2"/>Emitir</button>
                                </div>
                                <div v-else>
                                    <button v-if="approving==true"  class="bttn btn-primar disabled" ><font-awesome-icon icon="circle-notch" class="mr-2" spin />Aprobando...</button>
                                    <button v-else class="bttn btn-primar"  @click="approveToken(1)"><font-awesome-icon icon="money-bill" class="mr-2"/>Aprobar reembolso</button>
                                </div>
                                
                            </span>
                            <span v-else-if="current_car.nft_info.RefundEmmited==true && current_car.nft_info.RefundClaimed==false">
                                <p class="modal-label bold">Porcentaje comisión gastos (100=1%)</p>
                                <input class="form-input" type="text" v-model="reimbursement_fee">
                                <button v-if="sending_refund==true" class="bttn btn-green disabled" ><font-awesome-icon icon="circle-notch" class="mr-2" spin />Enviando...</button>
                                <button v-else class="bttn btn-green"  @click="sendRefund()"><font-awesome-icon icon="money-bill" class="mr-2"/>Enviar</button>
                            </span>
                            <span v-else-if="current_car.nft_info.RefundEmmited==true && current_car.nft_info.RefundClaimed==true">
                                <p>Reembolsa ya creado y enviado</p>
                            </span>
                        </div>                  
                        <div class="col-lg-12 col-md-5 col-sm-12 mb-4">
                            <p class="modal-label bold">Firma cliente</p>
                            <input class="form-input" type="text" v-model="customer_signature">
                        </div>    

                        <div class="col-lg-6 col-md-5 col-sm-12">
                            <div v-if="current_car.nft_info.Claimed==false">
                                <button v-if="claiming_car==true"  class="bttn btn-primar disabled" ><font-awesome-icon icon="circle-notch" class="mr-2" spin />Validando...</button>
                                <button v-else class="bttn btn-primar"  @click="setCarClaimed()"><font-awesome-icon icon="check" class="mr-2"/>Validar firma coche</button>
                            </div>
                            <div v-else><p>Ya reclamado</p></div>
                        </div>
                        <div class="col-lg-6 col-md-5 col-sm-12" >
                            <div v-if="current_car.nft_info.DetailingSessions>0"> 
                                <button v-if="claiming_detailing==true"  class="bttn btn-primar disabled" ><font-awesome-icon icon="circle-notch" class="mr-2" spin />Validando...</button>
                                <button v-else class="bttn btn-primar"  @click="claimDetailingSession()"><font-awesome-icon icon="check" class="mr-2"/>Validar firma detailing</button>
                            </div>
                            <div v-else>
                                <p>Sin sensiones</p>
                            </div> 
                        </div> 
                    </div>  
                    <h3 class="blue mt-4">Detailing</h3>
                    <div class="row" >
                         
                        <div class="col-lg-6 col-md-5 col-sm-12 mt-4" >
                            <div class="row">
                                <button v-if="adding_detailing==true"  class="bttn btn-primar disabled" ><font-awesome-icon icon="circle-notch" class="mr-2" spin />Añadiendo...</button>
                                <button v-else class="bttn btn-green"  @click="addDetailing()"><font-awesome-icon icon="plus" class="mr-2"/>Añadir sesión</button>
                                <button v-if="removing_detailing==true"  class="bttn btn-primar disabled" ><font-awesome-icon icon="circle-notch" class="mr-2" spin />Quitando...</button>
                                <button v-else class="bttn btn-red"  @click="subtractDetailing()"><font-awesome-icon icon="trash" class="mr-2"/>Quitar sesión</button>
                            </div>
                        </div>  
                        <div class="col-lg-6 col-md-5 col-sm-12">
                            <p class="modal-label">Sesiones restantes</p>
                            <p type="text" >{{current_car.nft_info.DetailingSessions}}</p>
                        </div>   
                      
                            
                    </div>   
                </div>
                <h3 class="blue mt-4">Información del vehículo</h3>
                <div class="row">
                    <div class="col-4">
                        <p class="modal-label">Nº chasis</p>
                        <p>{{current_car.chassis_number}}</p>
                        <p>miau</p>
                        <p class="modal-label">Motor</p>
                        <p>{{current_car.engine.name}}</p>   

                        <p class="modal-label">Proveedor</p>
                        <p>{{current_car.provider.name}}</p>                         
                    </div>
                    <div class="col-4">
                        <p class="modal-label">Marca</p>
                        <p>{{current_car.brand.name}}</p>
                        <p class="modal-label">Variante</p>
                        <p>{{current_car.variant.name}}</p>     
                          
                        <p class="modal-label">Precio</p>
                        <p>{{current_car.price}} €</p>
                    </div>
                    <div class="col-4">
                        <p class="modal-label">Modelo</p>
                        <p>{{current_car.model.name}}</p>    
                        <p class="modal-label">Año</p>
                        <p>{{current_car.year}}</p>    
                    </div>
                    <h3 class="blue mt-4">Equipamiento</h3>
                    <div class="col-12">                       
                        <p class="modal-label">De serie</p>
                        <div class="row justify-content-center mt-4">
                            <div class="col-lg-4 col-md-5 col-sm-12 text-center mb-4" v-for="each in current_car.default_eq" :key="each.id_equip">
                                <div class="card card-shadow black extra-card">
                                    <img class="extra-img" :src="each.pictures[0].url" alt="car">
                                    <div class="card-img-overlay overlay-extra extra-img">
                                        <h2 class="overlay-text">{{each.name.es}}</h2>             
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p class="modal-label">Extra</p>
                        <div class="row justify-content-center mt-4">
                            <div class="col-lg-4 col-md-5 col-sm-12 text-center mb-4" v-for="each in current_car.extra_eq" :key="each.id_equip">
                                <div class="card card-shadow black extra-card">
                                    <img class="extra-img" :src="each.pictures[0].url" alt="car">
                                    <div class="card-img-overlay overlay-extra extra-img">
                                        <h2 class="overlay-text">{{each.name.es}}</h2>             
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h3 class="blue mt-4">Galeria</h3>
                    <div class="row" >  
                        <div class="col-lg-4 col-md-4 col-sm-12 text-center" v-for="image in current_car.pictures.min_imgs.front" :key="image.url">                                
                            <img :src="image.url" alt="Avatar" class="image MB-2">
                        </div> 
                        <div class="col-lg-4 col-md-4 col-sm-12 text-center" v-for="image in current_car.pictures.min_imgs.others" :key="image.url">                                
                            <img :src="image.url" alt="Avatar" class="image MB-2">
                        </div>
                    </div>
                </div>
                <div class="text-center mt-4">
                    <a class="bttn btn-grey ml-2"  @click="$bvModal.hide('editCarModal')">Cerrar</a>
                </div>
            </b-modal>    
            
</div>
</template>

<script>
    import { ethers, utils, BigNumber} from 'ethers'
    import { serializeError } from "eth-rpc-errors";
    import axios from "axios"
    const car_collection_abi=require('../../contracts_abis/abi_car_collection.json');
    const erc_20_abi=require('../../contracts_abis/abi_erc20.json');

    import _ from 'underscore'
    const contracts_addresses =require('../../contracts_addresses.json')
    const AddressCarCollection=process.env.VUE_APP_ADDRESS_CAR_COLLECTION
    
    import Vuetable from 'vuetable-2'

    import VuetablePagination from 'vuetable-2/src/components/VuetablePagination.vue'
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue'


        export default {
            name: "sold",
            components: {
                'vuetable':Vuetable,
                'vuetable-pagination': VuetablePagination,
                'vuetable-pagination-info':VuetablePaginationInfo
            },
            data() {
                return {    
                    sending_refund:false,
                    reimbursement_fee:100,
                    approving:false,
                    adding_detailing:false,
                    removing_detailing:false,       
                    tokens:[],
                    metamask_connected:false,         
                    cars:[],
                    clients:[],
                    customers:[],
                    loading_clients:false,   
                    loading_cars:false,     
                    id:0,
                    current_car:{
                        'brand':{},
                        'model':{},
                        'engine':{},
                        'variant':{},
                        'year':'',
                        'chassis_number':'',
                        'price':'',
                        'provider':'',
                        'default_eq':[],
                        'extra_eq':[],
                        'buyer':{},
                        'purchase_date':'',
                        'pictures':{
                            'full_imgs':[],
                            'min_imgs':[]
                        },
                        'nft_info':{'allowance':BigNumber.from(0)}
                    },                 
                    selected_car:{
                        'brand':{},
                        'buyer':{
                            'name':''
                        },
                        'sold_date':'',
                        'model':{},
                        'engine':{},
                        'variant':{},
                        'year':'',
                        'chassis_number':'',
                        'price':'',
                        'provider':'',
                        'default_eq':[],
                        'extra_eq':[],
                        'id':''
                    },                 
                    file:'',
                    uploadingWarranty:false,
                    uploadPercentage:0,
                    adding_buyer:false,
                    deleting:false,
                
                    generating_invoice:false,
                    generating_contract:false,

                    loading_buyers:false,

                    emiting:false,
                    refund_emited:false,
                    sending:false,
                    selling:false,
                    claiming_payment:false,
                    claiming_detailing:false,
                    claiming_car:false,
                    customer_signature:"",
                   // invoice_available:false,
                    payment_methods:[],
                    changing_state:false,
                    current_invoice_info:{
                        "invoice_notes":""
                    },
                    downloading:false,
                    editign:false,
                    warranty_info:{},
                    getting_warranty:false,
                    warranty_spinner:0,
                    employees:[],

                    pagination_info_template: "Mostrando coches del {from} a {to} de {total}",
                    moreParams: {"sold_status":true},
                    searchText: "",
                    fields: [
                        {
                            name: 'chasis-slot',
                            title: 'Nºchasis',
                            sortField: 'chassis_number'
                        },
                        {
                            name: "model",
                            title: 'Modelo',
                            sortField: 'model'
                        },
                        {
                            name: "buyer",
                            title: 'Comprador',
                            sortField: 'buyer'
                        },
                        {
                            name: "sold_date",
                            title: 'Fecha de venta',
                            sortField: 'sold_date'
                        },
                        {
                            name: "edit",
                            title: 'Editar/Info',
                        },
                        {
                            name: "generate_contract",
                            title: 'Generar contrato',
                        },
                        {
                            name: "warranty",
                            title: 'Garantia',
                        },
                        {
                            name: "invoice",
                            title: 'Factura',
                        },
                        {
                            name: "delete",
                            title: 'Borrar',
                        },
                    ],
                    sortOrder: [
                        {field: 'chassis_number', direction: 'desc'},
                        {field: 'model', direction: 'desc'},
                        {field: 'buyer', direction: 'desc'},
                        {field: 'sold_date', direction: 'desc'}
                    ],
                    css: {
                        table: {
                            tableClass: 'table table-hovered',
                            loadingClass: 'loadingContent',
                            ascendingIcon: 'fa fa-sort-up',
                            descendingIcon: 'fa fa-sort-down',
                            ascendingClass: 'sorted-asc',
                            descendingClass: 'sorted-desc',
                            sortableIcon: 'fa fa-sort',
                            handleIcon: 'fa fa-bars',
                        },
                        pagination: {
                            infoClass: 'pull-left',
                            wrapperClass: 'vue-pagination',
                            activeClass: 'active',
                            disabledClass: 'disabled',
                            pageClass: 'btn btn-page',
                            linkClass: 'btn btn-arrow ',
                            icons: {
                                first: '',
                                prev: '',
                                next: '',
                                last: '',
                            },
                        }
                    },
                    styles: {
                        backgroundColor: this.color,
                        width: this.width + 'px',
                        height: this.height + 'px',
                    },

                
                }
            },
            methods:{
                
                getProviders(){
                    this.loading_providers=true
                    axios.post('/api/providers/get_all')
                        .then((result) => {
                            console.log("result",result)
                            if (result.data.resultado === "Ok"){
                                this.providers=result.data.providers
                                console.log("providers:",this.providers)
                                this.loading_providers=false
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                                this.loading_providers=false
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.loading_providers=false
                        });
                },
                
                /*getAllCars(){
                    this.loading_cars=true
                    axios.get('/api/cars/get_sold')
                        .then((result) => {
                            console.log("result",result)
                            if (result.data.resultado === "Ok"){
                                this.cars=result.data.cars
                                console.log("cars:",this.cars)
                                this.loading_cars=false
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                                this.loading_cars=false
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.loading_cars=false
                        });
                },*/
                addBuyer(car){
                    this.adding_buyer=true,
                    console.log('adding',car.buyer.id,car.id)
                    axios.post('/api/cars/set_buyer', {
                        'client_id':car.buyer.id,
                        'car_id':car.id
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")                           
                            this.adding_buyer=false;                            
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.adding_buyer=false;                            
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.adding_buyer=false;
                    });
                },
                async setCarClaimed(){
                    this.claiming_car=true
                    try{
                        let tx=await this.car_collection_contract.redeemWithSig(this.current_car.collection_id, this.customer_signature, Math.floor(Math.random() * 100000000), "car")
                        let correct, resultado= await this.handle_tx_confirmation(tx)
                        this.getCarContractInfo(this.current_car.collection_id)
                        this.popToast("success", "Firma correcta y coche marcado como canjeado")
                        this.claiming_car=false
                    }catch(error){
                        const serializedError = serializeError(error);
                        this.handle_metamask_rpc_error(serializedError)
                        this.claiming_car=false
                    }
                },

                async addDetailing(){
                    this.adding_detailing=true
                    try{
                        let tx=await this.car_collection_contract.addDetailingSession(this.current_car.collection_id)
                        let correct, resultado= await this.handle_tx_confirmation(tx)
                        this.getCarContractInfo(this.current_car.collection_id)
                        this.popToast("success", "Sesión añadida correctamente")
                        this.adding_detailing=false
                    }catch(error){
                        const serializedError = serializeError(error);
                        this.handle_metamask_rpc_error(serializedError)
                        this.adding_detailing=false
                    }
                },

                async subtractDetailing(){
                    this.removing_detailing=true
                    try{
                        let tx=await this.car_collection_contract.removeDetailingSession(this.current_car.collection_id)
                        let correct, resultado= await this.handle_tx_confirmation(tx)
                        this.getCarContractInfo(this.current_car.collection_id)
                        this.popToast("success", "Sesión eliminada correctamente")
                        this.removing_detailing=false
                    }catch(error){
                        const serializedError = serializeError(error);
                        this.handle_metamask_rpc_error(serializedError)
                        this.removing_detailing=false
                    }
                },

                async claimDetailingSession(){
                    this.claiming_detailing=true
                    try{
                        let tx=await this.car_collection_contract.redeemWithSig(this.current_car.collection_id, this.customer_signature, Math.floor(Math.random() * 100000000), "detailing")
                        let correct, resultado= await this.handle_tx_confirmation(tx)
                        this.getCarContractInfo(this.current_car.collection_id)
                        this.popToast("success", "Sesión de detailing canjeada correctamente")
                        this.claiming_detailing=false
                    }catch(error){
                        const serializedError = serializeError(error);
                        this.handle_metamask_rpc_error(serializedError)
                        this.claiming_detailing=false
                    }
                },


                deleteCar(car_id){
                    this.deleting=true,
                    
                    axios.post('/api/cars/delete', {
                        "car_id": car_id,
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.$nextTick(() => this.$refs.vuetable.refresh())
                            this.deleting=false
                            this.$bvModal.hide('modalDeleteCar')                          
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.deleting=false;
                            
                        }
                    }).catch((error) => {
                        console.log(error);
                    });

                },
              
                openEditCar(id){
                   this.loading_car=true
                   let vm=this
                   axios.post('/api/cars/get_car_info', {
                        "car_id":id
                    })
                        .then((result) => {
                            console.log("result",result)
                            if (result.data.resultado === "Ok"){
                                this.current_car=result.data.car_info
                                this.loading_car=false
                                console.log("current_car", this.current_car)
                                if (this.current_car.has_nft==true){
                                    console.log("aquiiiiiii")
                                    this.getCarContractInfo(this.current_car.collection_id)
                                }
                                //
                                this.getEmployees()
                                this.current_car.sold_date=new Date(this.current_car.sold_date).toISOString().slice(0, 10)
                                this.$bvModal.show('editCarModal')
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                                this.loading_car=false
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.loading_car=false
                        });                                        
                },

                getCustomers(){
                    this.loading_customers=true
                    axios.post('/api/customers/get_all')
                        .then((result) => {
                            console.log("result",result)
                            if (result.data.resultado === "Ok"){
                                this.customers=result.data.customers
                                console.log("customers:",this.customers)
                                this.loading_customers=false
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                                this.loading_customers=false
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.loading_customers=false
                        });
                },
                getEmployees(){
                    axios.post('/api/employees/get_all')
                        .then((result) => {
                            console.log("result",result)
                            if (result.data.resultado === "Ok"){
                                this.employees=result.data.employees
                                console.log("employees:",this.employees)
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                            }
                        }).catch((error) => {
                            console.log(error);
                        });
                },
                assignEmployee(){
                    axios.post('/api/employees/assign', {
                        'employee_id':this.current_car.employee_in_charge.id,
                        'car_id':this.current_car.id,
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },
                openWarrantyModal(car){
                    this.getting_warranty=true
                    this.file=""
                    this.selected_car=car
                    this.warranty_spinner=car.id
                    this.$bvModal.show('warrantyModal')
                    this.getWarranty()
                },
                async getWarranty(){                
                    
                    axios.post('/api/cars/warranty/get', { 
                        "car_id": this.selected_car.id,
                    })
                    .then((result) => {
                            console.log("result",result)
                            if (result.data.resultado === "Ok"){
                                this.warranty_info=result.data.warranty_info
                                console.log("warranty_info:",this.warranty_info)
                                this.getting_warranty=false
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                                this.getting_warranty=false
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.getting_warranty=false
                        });
                },
                openDeleteCar(id){
                    this.id=id
                    this.$bvModal.show('modalDeleteCar')
                },
                uploadWarranty(){                    
                    this.uploadingWarranty = true;
                    let vm=this
                    const URL = '/api/cars/warranty/upload'; 
                    let data = new FormData();                   
                    data.append('file', this.file);
                    data.append('car_id', this.selected_car.id)
                    let config = {
                            header : {
                                'Content-Type' : 'file'
                            },
                            onUploadProgress: function( progressEvent ) {
                                this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ));
                            }.bind(this)
                        };
                    axios.post(URL,data,config)
                        .then(function(res) {
                            var status=res.data.resultado                          
                            vm.mensaje=res.data.mensaje
                            if (status=="Ok") {
                                vm.uploadingWarranty = false;
                                vm.uploadPercentage360=0
                                vm.popToast("success", vm.mensaje, "Correcto")
                                vm.selected_car.warranty_available=true
                                //vm.getAllCars()
                                vm.$nextTick(() => vm.$refs.vuetable.refresh())

                                //vm.getCarInfo(vm.current_car.id, false)      
                            }
                            else{
                                vm.uploadingWarranty = false;
                                vm.uploadPercentage360=0
                                vm.popToast("danger", vm.mensaje, "Error")
                                //vm.closeCamera()
                            }
                        }).catch(function(err) {
                            console.log(err)
                            vm.uploadingWarranty = false;
                            vm.uploadPercentage=0
                            vm.popToast("danger", vm.mensaje, "Error")
                            //vm.closeCamera()
                        })
                },
                onChange(e) {
                    this.file = e.target.files[0]
                },
                openGenerateContract(car){
                    this.selected_car=car
                    this.$bvModal.show('modalGenerateContract')
                },
                generactContract(type){//arturo
                    this.generating_contract=true,
                    axios.post('/api/cars/contracts/get', {
                        'car_id':this.selected_car.id,
                        'type': type
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            let b64 = result.data.Invoice
                            //console.log(b64)
                            let link = document.createElement('a');
                            link.innerHTML = 'Descargar factura';
                            link.download = result.data.Filename;
                            link.href = 'data:application/octet-stream;base64,' + b64;
                            //document.body.appendChild(link);
                            link.click()                                
                            this.downloading=false
                            this.idFacturaCargando=""
                            //this.getAllCars()
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.generating_contract=false;                            
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.generating_contract=false;
                            
                        }
                    }).catch((error) => {
                        console.log(error);
                    });             
                    this.generating_contract=false
                },

                
                
                popToast(type, message) {
                    this.$bvToast.toast(message, {
                        variant: type,
                        solid: true,
                        toaster: 'b-toaster-top-center',
                    })
                },
                buildTokensList(){
                    this.tokens=[]
                    this.tokens_dict={}
                    for (var i=0; i<this.stable_tokens.length; i++){
                        this.tokens.push({"name":this.stable_tokens[i]["name"],
                                        "symbol":this.stable_tokens[i]["symbol"],
                                        "address":this.stable_tokens[i]["address"],
                                        "type":this.stable_tokens[i]["type"],
                                        })
                        this.tokens_dict[this.stable_tokens[i]["address"]]={"symbol":this.stable_tokens[i]["symbol"],
                                                                            "name":this.stable_tokens[i]["name"],
                                                                            }
                        }
                    for (var i=0; i<this.variable_tokens.length; i++){
                        this.tokens.push({"name":this.variable_tokens[i]["name"],
                                        "symbol":this.variable_tokens[i]["symbol"],
                                        "address":this.variable_tokens[i]["address"],
                                        "type":this.variable_tokens[i]["type"],
                                        })
                        }
                    console.log("this.tokens", this.tokens)
                },
                async setup(){
                        console.log(this.signer)
                        this.load_read_only_ws_provider()
                        console.log("this.web3_read_provider",this.web3_read_provider)
                        if (this.metamask_connected==true){
                            this.car_collection_contract = new ethers.Contract(AddressCarCollection, car_collection_abi["abi"], this.signer)
                            console.log('contract',this.car_collection_contract)
                        }        
                    },

                async handle_tx_confirmation(tx_object){
                    let result=undefined
                    
                    try{
                        console.log("handling tx")
                        result = await this.web3_read_provider.waitForTransaction(tx_object.hash)
                        console.log("bien")
                        return(true, result)

                    }//in case read only provider nto working use web3 injected provider as backup
                    catch(error){
                        //result = await tx_object.wait(1)
                        const serializedError = serializeError(error);
                        this.handle_metamask_rpc_error(serializedError)
                        return(false, error)
                    }
                },
                handle_metamask_rpc_error(serialized_error){
                    console.log("serializedError",serialized_error );
                    if (serialized_error.data!=undefined){
                        this.popToast("danger", serialized_error.data.originalError.reason)
                    }
                    else{
                        this.popToast("danger", serialized_error.message)
                    }
                },
                async connect_metamask(){ 
                    this.connecting=true
                    //let vm=this
                    try{
                        if(window.ethereum) {
                            //let accounts= await window.ethereum.enable();
                            this.web3_provider=await new ethers.providers.Web3Provider(window.ethereum)
                            let chainId= await window.ethereum.request({ method: 'eth_chainId' })
                            console.log("chainId", chainId)
                            if (chainId==0xfa){ //0xfa2 para testnet || chainId==0x7a69
                                await window.ethereum.request({ method: 'eth_requestAccounts' });
                                this.signer=this.web3_provider.getSigner()
                                console.log("signer", this.signer)
                                this.my_eth_address= await window.ethereum.selectedAddress
                                console.log('address',this.my_eth_address)
                                this.metamask_connected=true
                                this.connecting=false
                                this.popToast("success", `Successfuly conected wallet`)
                                await this.setup()

                                
                            }
                            else{
                                this.popToast("danger", `Please, change to correct network`)
                            }
                            
                        }
                        else{
                            this.metamask_connected=false
                            this.connecting=false
                            this.popToast("danger", `Please, install metamask`)
                        }        
                    }

                    catch(error){
                        console.log(error)
                        this.popToast("danger", `Error connecting metamask: ${error.message}`)
                        this.metamask_connected=false
                        this.connecting=false


                    }
                    
                    //return(this.web3_provider)   
                },
                load_read_only_ws_provider: function(){
                    this.web3_read_provider= new ethers.providers.JsonRpcProvider("https://rpcftm.kartek.ad")
                    //this.web3_read_provider._websocket.onclose= (event) => this.onWsClose(event);
                    this.car_collection_contract_read_only = new ethers.Contract(AddressCarCollection, car_collection_abi["abi"], this.web3_read_provider); 
                    //this.mock_dai_contract_read_only = new ethers.Contract(contracts_addresses["stable_tokens"]["AddressMockDai"], erc_20_abi["abi"], this.web3_read_provider)
                    //this.mock_usdc_contract_read_only = new ethers.Contract(contracts_addresses["stable_tokens"]["AddressMockUSDC"], erc_20_abi["abi"], this.web3_read_provider)
                    console.log("this.web3_read_provider",this.web3_read_provider)
                },
                sleep: function(milliseconds){
                    return new Promise(resolve => setTimeout(resolve, milliseconds))
                },  

                

                ////////////////////////////
                //// get contract information ////
                //////////////////////////////////
                async getCarContractInfo(_carID){
                    console.log("miau?")
                    let car_info=await this.car_collection_contract_read_only.Cars(_carID)
                    console.log("miau car_info", car_info)
                    let num_payments=await this.car_collection_contract_read_only.getLenPayments(_carID)
                    this.current_car.nft_info.Claimed=car_info.Claimed
                    this.current_car.nft_info.DetailingSessions=car_info.DetailingSessions.toNumber()
                    console.log("num_payments",num_payments)
                    let last_payment_info= undefined
                    
                    if (num_payments>0){
                        last_payment_info=await this.car_collection_contract_read_only.CarsPayments(_carID, num_payments-1)
                        console.log("last_payment_info",last_payment_info)
                        this.current_car.nft_info.PaymentClaimed=last_payment_info.Claimed
                        this.current_car.nft_info.PaymentToken=this.tokens_dict[last_payment_info.Coin].symbol
                        //this.current_car.nft_info.PaymentTokenAddress=last_payment_info.Coin
                        this.current_car.nft_info.payment_coin_contract= new ethers.Contract(last_payment_info.Coin, erc_20_abi["abi"], this.signer)
                        this.current_car.nft_info.allowance= await this.current_car.nft_info.payment_coin_contract.allowance(this.my_eth_address, AddressCarCollection)
                        //current_car.nft_info.payment_token_approved==true
                        console.log("BigNumber.from(0)",BigNumber.from(0))
                        if (this.current_car.nft_info.allowance._hex!=BigNumber.from(0)._hex){
                            this.current_car.nft_info.payment_token_approved=true
                        }
                        else{
                            this.current_car.nft_info.payment_token_approved=false
                        }
                        console.log("allowance", this.current_car.nft_info.allowance)
                        this.current_car.nft_info.RefundEmmited=last_payment_info.RefundEmmited
                        this.current_car.nft_info.RefundClaimed=last_payment_info.RefundClaimed
                        this.current_car.nft_info.AmountPaid=last_payment_info.Amount
                        this.current_car.nft_info.BuyerAddress=last_payment_info.Buyer
                    }
                    console.log("getCarContractInfo", car_info)
                    console.log("car_info", this.current_car)
                    this.$forceUpdate()
                    
                },
                async approveToken(amount){
                    this.approving=true
                    try{
                        if (amount==1){
                            amount=ethers.constants.MaxUint256
                    }
                    let result_Approve = await this.current_car.nft_info.payment_coin_contract.approve(AddressCarCollection, amount)
                    console.log("miau", result_Approve)
                    let correct, resultado= await this.handle_tx_confirmation(result_Approve)
                    this.getCarContractInfo(this.current_car.nft_id)
                    this.popToast("success", "Aprobado contrato para comprar vehículo con "+this.selected_token.symbol)
                    //await result_deposit.wait(1)
                    //await result_deposit.wait(1)
                    this.approving=false
                    this.current_car.nft_info.allowance= await this.current_car.nft_info.payment_coin_contract.allowance(this.my_eth_address, AddressCarCollection)
                        //current_car.nft_info.payment_token_approved==true
                        console.log("BigNumber.from(0)",BigNumber.from(0))
                        if (this.current_car.nft_info.allowance._hex!=BigNumber.from(0)._hex){
                            this.current_car.nft_info.payment_token_approved=true
                        }
                        else{
                            this.current_car.nft_info.payment_token_approved=false
                        }

                    }catch(error){
                        const serializedError = serializeError(error);
                        this.handle_metamask_rpc_error(serializedError)
                        this.approving=false
                    }
                },
                async claimCarPayment(){
                    this.claiming_payment=true
                    try{
                        let tx= await this.car_collection_contract.withdrawPayment(this.current_car.collection_id)
                        let correct, resultado= await this.handle_tx_confirmation(tx)
                        this.getCarContractInfo(this.current_car.collection_id)
                        this.claiming_payment=false
                        this.popToast("success", "Coche cobrado correctamente desde el smart contract")
                    }catch(error){
                        const serializedError = serializeError(error);
                        this.handle_metamask_rpc_error(serializedError)
                        this.claiming_payment=false
                    }
                },
                async createPaymentRefund(){
                    this.emiting=true
                    try{
                        let tx= await this.car_collection_contract.emmitRefund(this.current_car.collection_id)
                        let correct, resultado= await this.handle_tx_confirmation(tx)
                        this.getCarContractInfo(this.current_car.collection_id)
                        this.popToast("success", "Reembolso creado correctamente, ahora puedes emitir el pago del reembolso con otra transacción")
                        this.emiting=false
                    }catch(error){
                        const serializedError = serializeError(error);
                        this.handle_metamask_rpc_error(serializedError)
                        this.emiting=false
                    }
                },
                async sendRefund(){
                    this.sending_refund=true
                    try{
                        let tx= await this.car_collection_contract.payRefund(this.current_car.collection_id, this.reimbursement_fee)
                        let correct, resultado= await this.handle_tx_confirmation(tx)
                        this.getCarContractInfo(this.current_car.collection_id)
                        this.popToast("success", "Reembolso pagado correctamente")
                         this.sending_refund=false
                    }catch(error){
                        const serializedError = serializeError(error);
                        this.handle_metamask_rpc_error(serializedError)
                        this.sending_refund=false
                    }
                },
                changeInvoiceState(car_id){//arturo
                    this.changing_state=true
                    axios.post('/api/cars/invoices/switch_completed',{
                        "car_id":car_id
                    })
                      .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.current_invoice_info.completed=result.data.completed_status
                            console.log("current_invoice_info",this.current_invoice_info.completed)
                            this.changing_state=false
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.changing_state=false
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.changing_state=false
                    });
                },
                openInvoiceModal(car){        
                    console.log("car",car)
                    let vm=this
                    this.new_invoice={}
                    this.selected_car=car       
                    console.log('selected',this.selected_car)    
                    axios.post('/api/cars/invoices/get_info', { 
                        "car_id": car.id,                        
                    })  .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.getPaymentMethods()
                            if (result.data.invoice_info==undefined){
                                this.current_invoice_info={"invoice_notes":""}
                            }else{
                                this.current_invoice_info=result.data.invoice_info
                            }
                            console.log("current_invoice_info",this.current_invoice_info)
                            this.changing_state=false
                            this.$bvModal.show('invoiceModal')
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.changing_state=false
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.changing_state=false
                    });
                    
                },
                async downloadInvoice(car,current_invoice_info){
                    console.log('car',car)
                    this.downloading=true
                    this.idFacturaCargando=car.id
                    
                    axios.post('/api/cars/invoices/get', { 
                        "car_id": car.id,
                        "notes":current_invoice_info.invoice_notes
                    })
                        .then((response) => {
                            this.status=response.data
                            if (this.status.resultado == "Ok") {
                                let b64 = response.data.Invoice
                                //console.log(b64)
                                let link = document.createElement('a');
                                link.innerHTML = 'Descargar factura';
                                link.download = response.data.Filename;
                                link.href = 'data:application/octet-stream;base64,' + b64;
                                //document.body.appendChild(link);
                                link.click()                                
                                this.downloading=false
                                this.idFacturaCargando=""
                                this.$nextTick(() => this.$refs.vuetable.refresh())
                                //this.getAllCars()
                                this.popToast("success", this.status.mensaje, "Correcto")
                            }
                            else{
                                this.downloading=false
                                this.idFacturaCargando=""
                                this.popToast("danger", this.status.mensaje, "Error")
                            }
                            }).catch((error) => {
                                this.idFacturaCargando=""
                                this.downloading=false
                                console.log(error);
                        });
                },
                
                getPaymentMethods(){
                    axios.get('/api/payment_methods/get')
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.payment_methods=result.data.payment_methods
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },
                savePaymentMethod(payment_method,id){
                    this.editing=true
                    axios.post('/api/cars/invoices/set_payment_method', {
                        "car_id": id,
                        "new_payment_method":payment_method,
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },
                editCar(car){
                    this.editing=true
                    axios.post('/api/cars/edit_car_sold', {
                        "car_id": car.id,
                        "sold_date":car.sold_date,
                        "customer_id":car.buyer.id
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            //this.getAllCars()                                                
                            this.$nextTick(() => this.$refs.vuetable.refresh())
                            this.editing=false
                            this.$bvModal.hide('editCarModal')      
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.editing=false;
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.editing=false;
                    });
                },
           
                deleteWarranty(){
                    this.deleting=true
                    axios.post('/api/cars/warranty/remove', {
                        "car_id": this.selected_car.id,
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){            

                            this.popToast("success", result.data.mensaje, "Ok")
                            //this.getAllCars()         
                            this.$nextTick(() => this.$refs.vuetable.refresh())                                       
                            this.deleting=false
                            this.$bvModal.hide('warrantyModal')      
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.deleting=false;
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },
                deleteInvoice(car){
                    this.deleting=true
                    axios.post('/api/cars/invoices/delete', {
                        "car_id": car.id,
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){            
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.deleting=false
                            this.$nextTick(() => this.$refs.vuetable.refresh())
                            //this.getAllCars()
                            this.$bvModal.hide('invoiceModal')      
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.deleting=false;
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },
                     /*vue table function */

                onPaginationData (paginationData) {
                    this.$refs.pagination.setPaginationData(paginationData)
                    this.$refs.paginationInfo.setPaginationData(paginationData)
                    let pagina_Actual= (this.$refs.paginationInfo.tablePagination.current_page)
                    //añadimos eliminamos clase active para resalta pagina actual
                    this.sleep(100).then(() => {
                        let elemento_paginacion = document.getElementsByClassName("vue-pagination")
                        let tags = elemento_paginacion[0].getElementsByTagName("a")
                        this.sleep(2000)   

                        for (let item of tags) {
                            if (item.innerHTML == pagina_Actual) {
                                //console.log(item.innerHTML)
                                item.classList.add("active");
                            }
                            else{
                                item.classList.remove("active");
                            }
                            //console.log(item.innerHTML);
                        }
                    })
                },
                onChangePage (page) {
                this.$refs.vuetable.changePage(page)
                },

                onLoading() {
                    console.log('cargando')
                    this.sleep(2000)   

                    let spin=document.getElementById('spin');
                    let tablebody=document.getElementsByClassName('vuetable-body')[0];
                    spin.classList.remove('d-none'); //vuetable-body
                    tablebody.classList.add('opacity'); //vuetable-body
                    //console.log('Cargando .. show your spinner here')
                },
                onLoaded() {
                    console.log("cargado")
                    //this.loading=false
                    let spin=document.getElementById('spin');
                    let tablebody=document.getElementsByClassName('vuetable-body')[0];
                    tablebody.classList.remove('opacity');
                    spin.classList.add('d-none');
                    
                    //console.log('Cargado .. hide your spinner here')
                },
                search: _.debounce(function () {
                    this.moreParams = {
                        'filter': this.searchText,
                        "sold_status":true
                    };
                    this.$nextTick(() => this.$refs.vuetable.refresh())

                }, 400),

        }, 
        
        mounted(){
            this.getCustomers()
            this.stable_tokens=contracts_addresses.stable_tokens
            this.variable_tokens=contracts_addresses.tokens
            this.buildTokensList()
            this.connect_metamask()
            
        },
    }
    </script>
    <style scoped>
    .image{
        max-width: 300px;
        max-height: 200px;
    }

    </style>