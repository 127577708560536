<template>
  <div id="car">
    <NavBar/>
    <ToTop/>
          <div>
              <b-carousel
                  id="carousel-init"
                  :interval="6000"
                  controls
                  indicators
                   background="#fff"
                  >
                  <b-carousel-slide v-for="image in cars[0].images" :key="image">
                      <template #img>
                      <img :src="image"
                          class="img-init">
                      </template>
                      <!--<h1>Hello world!</h1>-->
                  </b-carousel-slide>

                 <!-- <b-carousel-slide >
                      <template #img>
                      <img src="../../public/car2.jpeg"
                          class="img-init">
                      </template>
                      <h1>Hello world!</h1>
                  </b-carousel-slide>
                 
                  <b-carousel-slide >
                      <template #img>
                          <img src="../../public/car4.jpeg" class="img-init" >
                      </template>
                      <h1>Hello world!</h1>
                  </b-carousel-slide>
                  <b-carousel-slide >
                      <template #img>
                          <img src="../../public/car3.jpeg" class="img-init" >
                      </template>
                      <h1>Hello world!</h1>
                  </b-carousel-slide>-->
              </b-carousel>
          </div>
   

          <div class="row justify-content-center mb-4">
            <div class="col-lg-10 col-md-11 col-sm-12 text-center">
                <h1 class="title-car" id="stock">{{cars[0].name}}</h1>
                <h1 class="title-car" id="stock">{{cars[0].features_summary}}</h1>
                  <!--<div class="col-lg-5 col-md-11 col-sm-12 text-center ">-->
                  <h1 class="title-price" id="stock">{{cars[0].price_str}}</h1>
                <!-- </div>-->
              
                  <a v-if="connected==false" class="bttn btn-primar" @click="connect_metamask()">Conectar Metamask</a> 
                  <a v-else class="bttn btn-btc"><h1> <font-awesome-icon :icon="['fab', 'bitcoin']"   class="mr-3"/>Comprar en cripto<font-awesome-icon :icon="['fab', 'ethereum']"   class="ml-3"/></h1></a> 
                    <!--<a class="bttn btn-blue" >{{address_owner_init}}...{{address_owner_end}}</a> -->
              
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-10 col-md-11 col-sm-12 jus">
                    <h2>{{cars[0].description_text}}</h2>
                </div>
              <div class="col-lg-10 col-md-11 col-sm-12 jus">
                <h1 class="tecnic-title mt-4">Descripción del vehículo</h1>
                <ul style="margin-left:40px" v-for="item in cars[0].description_list" :key="item">
                  <li class="tecnic-list">{{item}}</li>
                  <!--<li class="tecnic-list">Standheizung und -lüftung</li>
                  <li class="tecnic-list" >Panorama-Glasdach</li>
                  <li class="tecnic-list">MMI Navigation plus mit MMI touch response inkl. Audi virtual cockpit (plus)</li>
                  <li class="tecnic-list">HD-Matrix-LED-Scheinwerfer mit dynamischer Lichtinszenierung und dynamischem Blinklicht</li>
                  <li class="tecnic-list">Aluminiumoptik Exterieur</li>
                  <li class="tecnic-list">Dynamik-Allradlenkung adaptive air suspension allroad</li>
                  <li class="tecnic-list">Sitzbelüftung und Massage-Funktion vorn</li>-->
                </ul>
                  <h3></h3>
              </div>
          </div>
          <div class="row justify-content-center mt-4 mb-4">
              <div class="col-lg-10 col-md-11 col-sm-12 jus">
                <h1 class="tecnic-title">Conjunto de funciones</h1>
                <ul class="ul-tecnic" v-for="item in cars[0].features_list" :key="item">
                  <li class="tecnic-list">{{item}}</li>
                  <!--<li class="tecnic-list">Standheizung und -lüftung</li>
                  <li class="tecnic-list" >Panorama-Glasdach</li>
                  <li class="tecnic-list">MMI Navigation plus mit MMI touch response inkl. Audi virtual cockpit (plus)</li>
                  <li class="tecnic-list">HD-Matrix-LED-Scheinwerfer mit dynamischer Lichtinszenierung und dynamischem Blinklicht</li>
                  <li class="tecnic-list">Aluminiumoptik Exterieur</li>
                  <li class="tecnic-list">Dynamik-Allradlenkung adaptive air suspension allroad</li>
                  <li class="tecnic-list">Sitzbelüftung und Massage-Funktion vorn</li>-->
                </ul>
                  <h3></h3>
              </div>
          </div>
          <div class="row justify-content-center mt-4 mb-4">
              <div class="col-lg-10 col-md-11 col-sm-12 jus">
                <h1 class="tecnic-title">Extras</h1>
               <div class="row justify-content-center mt-4">
                    <div class="col-lg-4 col-md-5 col-sm-12 text-center mb-4" v-for="extra in cars[0].extras" :key="extra.name">
                        <a class="card card-shadow black extra-card">
                            <img class="extra-img" :src="extra.image" alt="car">
                            <div class="card-img-overlay overlay-extra extra-img">
                              <h2 class="overlay-text">{{extra.name}}</h2>                       
                            </div>
                        </a>
                    </div>
                    <!--<div class="col-lg-4 col-md-5 col-sm-12 text-center mb-4">
                        <a class="card card-shadow black extra-card">
                            <img class="extra-img" src="../../public/extra2.jpeg" alt="car">
                            <div class="card-img-overlay overlay-extra extra-img">
                              <h2 class="overlay-text">Luces <br> matrix</h2>                       
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-4 col-md-5 col-sm-12 text-center mb-4">
                        <a class="card card-shadow black extra-card">
                            <img class="extra-img" src="../../public/extra3.jpeg" alt="car">
                            <div class="card-img-overlay overlay-extra extra-img">
                              <h2 class="overlay-text">Asientos <br> calefactados</h2>                       
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-4 col-md-5 col-sm-12 text-center mb-4">
                        <a class="card card-shadow black extra-card">
                            <img class="extra-img" src="../../public/extra2.jpeg" alt="car">
                            <div class="card-img-overlay overlay-extra extra-img">
                              <h2 class="overlay-text">Asientos <br> calefactados</h2>                       
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-4 col-md-5 col-sm-12 text-center mb-4">
                        <a class="card card-shadow black extra-card">
                            <img class="extra-img" src="../../public/extra3.jpeg" alt="car">
                            <div class="card-img-overlay overlay-extra extra-img">
                              <h2 class="overlay-text">Asientos <br> calefactados</h2>                       
                            </div>
                        </a>
                    </div>
                    <div class="col-lg-4 col-md-5 col-sm-12 text-center mb-4">
                        <a class="card card-shadow black extra-card">
                            <img class="extra-img" src="../../public/extra1.jpeg" alt="car">
                            <div class="card-img-overlay overlay-extra extra-img">
                              <h2 class="overlay-text">Asientos <br> calefactados</h2>                       
                            </div>
                        </a>
                    </div>-->
                </div>
              </div>
          </div>
          <!--<div class="row justify-content-center">
            <h1 class="tecnic-title mt-4">Vista 360º exterior</h1>
            <div class="col-lg-12 col-md-11 col-sm-12 ">
              <vue-three-sixty
                      :amount='5'
                      :imagePath="images_view360_exterior_path"
                      :fileName="images_view360_exterior_name+{index}+'.jpeg'"
                      spinReverse
                      autoplay
                      scrollImage
                  >
                  </vue-three-sixty>
            </div> 
          </div>-->
          <div class="row justify-content-center mt-4 mb-4">
              <div class="col-lg-3 col-md-3 col-sm-12 jus">
                <h1 class="tecnic-title mt-4">Vista 360º exterior</h1>
                  <vue-three-sixty
                      :amount='16'
                      imagePath="./Bottle"
                      fileName="Bottle{index}.jpg"
                      spinReverse
                      autoplay
                      scrollImage
                  >
                     <!-- <template v-slot:header>
                          <div class="v360-header text-light bg-dark">
                              <span class="v360-header-title">36 Images - Autoplay (1 loop) - Reverse Spin</span>
                              <span class="v360-header-description"></span>
                          </div>
                      </template>-->
                  </vue-three-sixty>
              </div>
                <div class="col-lg-3 col-md-3 col-sm-12 jus">
                <h1 class="tecnic-title mt-4">Vista 360º interior</h1>
                  <vue-three-sixty
                      :amount='16'
                      imagePath="./test"
                      fileName="test{index}.jpg"
                      spinReverse
                      autoplay
                      scrollImage
                  >
                     <!-- <template v-slot:header>
                          <div class="v360-header text-light bg-dark">
                              <span class="v360-header-title">36 Images - Autoplay (1 loop) - Reverse Spin</span>
                              <span class="v360-header-description"></span>
                          </div>
                      </template>-->
                  </vue-three-sixty>
              </div>
            </div>
           <div class="row justify-content-center mt-4 mb-4">
              <div class="col-lg-10 col-md-11 col-sm-12 jus">
                <h1 class="tecnic-title">Política para compras en criptomonedas</h1>
              
                <h2>Ut quis volutpat velit, vel tristique mauris. Quisque in venenatis eros. Nulla pulvinar sapien id ipsum blandit tincidunt. Pellentesque nec dolor risus. Vestibulum venenatis fringilla commodo. Vestibulum libero turpis, volutpat id mauris interdum, iaculis pretium dui. In gravida dui sit amet purus lacinia auctor. Aenean laoreet porttitor suscipit. Nam nisi odio, commodo vel orci eget, rutrum iaculis magna. Cras vitae mattis ex. Sed consectetur feugiat ipsum, dignissim suscipit tellus dictum eget.</h2>
              </div>
              <div class="row justify-content-center" >
                  <a v-if="connected==false" class="bttn btn-primar" @click="connect_metamask()">Conectar Metamask</a> 
                  <a v-else class="bttn btn-btc"><h1> <font-awesome-icon :icon="['fab', 'bitcoin']"   class="mr-3"/>Comprar en cripto</h1></a> 
            </div>
            </div>
            <div class="row justify-content-center mt-4" >
                <h1 class="tecnic-title" id="contact"> ¿Te gusta lo que ves? </h1>
            </div>
            
            <!--<div class="row justify-content-center" >
               <div v-if="connected==false">
                    <a class="bttn btn-blue" @click="connect_metamask()">Conectar Metamask</a> 
                </div>
                <div v-else>
                    <a class="bttn btn-blue" >{{address_owner_init}}...{{address_owner_end}}</a> 
                </div>
            </div>-->
             
      
            <div class="row justify-content-center mt-4" style="margin-bottom:60px">
                <div class="col-lg-10 col-md-11 col-sm-12 text-center mb-4">
                    <h2>No dudes en ponerte en contacto con nosotros por teléfono, whatsapp, email o rellenando el formulario de contacto que hay a continuación.</h2>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12 text-center">
                  <font-awesome-icon :icon="['fab', 'whatsapp-square']" class="whatsapp" />
                  <h2>Presione sobre el icono para enviar un whatsapp.</h2>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12 text-center">
                    <font-awesome-icon icon="envelope" class="mb-2 icon" />
                    <h2>kartek@kartek.ad</h2>
                </div>
                <div class="col-lg-3 col-md-12 col-sm-12 text-center">
                    <font-awesome-icon icon="phone" class="mb-2 icon" />
                    <h2>+376 637 372 <br> +34 658658658</h2>
                </div>
                
            </div>

            <div class="row justify-content-center mb-4">
                <div class="col-lg-6 col-md-10 col-sm-10 text-center card card-shadow" >
                    <div class="card-body" style="margin-top: 30px">
                            <div class="row">
                                <div class="col-lg-6 col-md-11 mb-1 text-left">
                                        <h2 for="nombre-contact">Nombre</h2>
                                        <input class="form-input" type="text">
                                        <!-- {{ form.name(class="form-input", type="text", id="nombre-contact") }}-->
                                </div>
                                <div class="col-lg-6 col-md-11 mb-1 text-left">
                                    <h2  for="email-contact">Correo electrónico</h2>
                                    <input class="form-input" type="text">
                                    <!-- {{ form.email(class="form-input", type="email", id="email-contact") }}-->
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-lg-12 col-md-11 text-left">
                                    <div class="form-group ">
                                    <h2 for="mensaje-contact">Mensaje</h2>
                                    <textarea class="form-input" name="" id="" cols="30" rows="10"></textarea>
                                        <!--{{ form.message(class="form-input", rows="6", id="mensaje-contact") }}-->
                                    </div>
                                </div>
                            </div>
                            <div class="row" >
                                <div id="privacy" class="col-11  jus">
                                    <!--{{form.checkbox(class="", type="checkbox", style="width:18px; height:18px", id="checkbox-contact")}}
                                        <span>He leído la  <a href="{{url_for('core.privacy')}}" target="_blank" rel="noopener" class="text-primary pointer font-wieght-bold">política de privacidad</a> y acepto el tratamiento de mis datos para la resolución de dudas o consultas que realice.</span>-->
                                </div>
                            </div>
                            <div class="text-center mt-2">
                                <button v-if="sending==true" class="bttn btn-primar disabled"><font-awesome-icon icon="circle-notch" class="mr-2" spin  />Sending</button>
                                <button v-else class="bttn btn-primar"><h2 class="mb-0"><font-awesome-icon icon="envelope" class="mr-2" />
                                   Send</h2></button>
                        <!--{{ form.submit(class="btn btn-rojo", type="submit") }}-->
                            </div>
                        <!--</form>-->
                    </div>
                 
                </div>
            </div>
            <Foot/>
  </div>

</template>

<script>
/* eslint-disable */ 
import axios from 'axios'
const ethers=require('ethers')

//const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
//var Web3 = require('web3');

//var web3 = new Web3(Web3.givenProvider);
// const Notary=require('./contracts/NotaryContract.json');
import NavBar from '@/components/Navbar.vue'
import Foot from '@/components/Footer.vue'
import ToTop from '@/components/ToTop.vue'

export default {
  name: "Car",
  components: { 
    NavBar,
    Foot,
    ToTop
},
  data() {
    return {
      sending:false,
      w3: web3,
      address_owner_end:'',
      address_owner_init:'',
      wallet_manager:undefined,
      connected:false,
      deploying:false,
      claiming:false,
      creating:false,
      created:false,
      cars:[
          {
              name:'Audi A3',
              price: 80000,
              price_str: '80.000€',
              url:'car',
              features_summary:'features_summary',
              description_text:'Ut quis volutpat velit, vel tristique mauris. Quisque in venenatis eros. Nulla pulvinar sapien id ipsum blandit tincidunt. Pellentesque nec dolor risus. Vestibulum venenatis fringilla commodo. Vestibulum libero turpis, volutpat id mauris interdum, iaculis pretium dui. In gravida dui sit amet purus lacinia auctor. Aenean laoreet porttitor suscipit. Nam nisi odio, commodo vel orci eget, rutrum iaculis magna. Cras vitae mattis ex. Sed consectetur feugiat ipsum, dignissim suscipit tellus dictum eget.',
              extras:[
                {
                  name: 'Asientos calefactados',
                  image: '../extra1.jpeg'
                },
                {
                  name: 'Luces matrix',
                  image: '../extra2.jpeg'
                },
                {
                  name: 'Luces matrix',
                  image: '../extra2.jpeg'
                }
              ],
              images: ['../car1.jpeg','../car2.jpeg','../car3.jpeg','../car4.jpeg','../car5.jpeg']
             
          },   
          {
              name:'BMW Serie 3',
              price:60000,
              price_str: '60.000€',
              url:'car',
              features_summary:'features_summary',
              description_text:'description_text',
              description_list: ['description_list1','description_list2','description_list3'],
              features_list: ['features_list1','features_list2','features_list3'],
              extras:[
                {
                  name: 'Asientos calefactados',
                  image: '../extra1.jpeg'
                },
                {
                  name: 'Luces matrix',
                  image: '../extra2.jpeg'
                },
                {
                  name: 'Luces matrix',
                  image: '../extra2.jpeg'
                }
              ],
              images: ['../car2.jpeg','../car1.jpeg','../car3.jpeg','../car4.jpeg','../car5.jpeg'],
              images_view360_exterior: 'images_view360_exterior',
              images_view360_interior: 'images_view360_interior',
          },
          {
              name:'Audi RS5',
              price: 100000,
              price_str:'100.000€',
              url:'car',
              features_summary:'features_summary',
              description_text:'description_text',
              description_list: ['description_list1','description_list2','description_list3'],
              features_list: ['features_list1','features_list2','features_list3'],
              extras:[
                {
                  name: 'Asientos calefactados',
                  image: '../extra1.jpeg'
                },
                {
                  name: 'Luces matrix',
                  image: '../extra2.jpeg'
                },
                {
                  name: 'Luces matrix',
                  image: '../extra2.jpeg'
                }
              ],
              images: ['../car2.jpeg','../car1.jpeg','../car3.jpeg','../car4.jpeg','../car5.jpeg'],
              images_view360_exterior: 'images_view360_exterior',
              images_view360_interior: 'images_view360_interior',
          },
          {
              name:'Tesla model3',
              price: 70000,
              price_str:'70.000€',
              url:'car',
              features_summary:'features_summary',
              description_text:'description_text',
              description_list: ['description_list1','description_list2','description_list3'],
              features_list: ['features_list1','features_list2','features_list3'],
              extras:[
                {
                  name: 'Asientos calefactados',
                  image: '../extra1.jpeg'
                },
                {
                  name: 'Luces matrix',
                  image: '../extra2.jpeg'
                },
                {
                  name: 'Luces matrix',
                  image: '../extra2.jpeg'
                }
              ],
              images: ['../car2.jpeg','../car1.jpeg','../car3.jpeg','../car4.jpeg','../car5.jpeg'],
              images_view360_exterior: 'images_view360_exterior',
              images_view360_interior: 'images_view360_interior',
          }         
      ] 

    }
  },
  computed: {
     check_account_change: function(){
          console.log("checking change")
          window.ethereum.on('accountsChanged', function (accounts) {
              console.log("account changed")
        // Time to reload your interface with accounts[0]!
        })
      }
  },
  methods: {
    getCars() {
      const path = 'http://localhost:8080/cars_info';
      axios.get(path)
        .then((res) => {
          this.cars = res.data.cars;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    sleep: function(milliseconds){
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    },
    async connect_metamask() { 
            this.connecting=true
            if(window.ethereum) {
                await window.ethereum.enable();
            }
            this.web3_provider=new ethers.providers.Web3Provider(window.ethereum)
            //console.log("provider",this.web3_provider)
            this.signer=this.web3_provider.getSigner()
            //console.log("signer", this.signer)
            this.my_eth_address= await this.signer.getAddress()
            this.check_if_registered()
            console.log(this.my_eth_address)
            this.metamask_connected=true
            //return(this.web3_provider)
            
        },

    /*connect_metamask: async function getAccount() {
      this.accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      console.log(web3)
      let vm=this
      this.connected=true
      this.address_owner_init = this.accounts[0].substring(0,4)
      this.address_owner_end = this.accounts[0].slice(-4);
      web3.eth.net.getId().then(function(network_id){
          vm.notary_contract=new web3.eth.Contract(Notary["abi"], Notary["networks"][network_id]["address"])
          console.log("esto",vm.notary_contract)
      })
    },*/
    popToast(type, message) {
        this.$bvToast.toast(message, {
            variant: type,
            solid: true,
            noCloseButton: true,
            toaster: 'b-toaster-top-right',                
        })
    },    
  },
  mounted: function(){
    //this.getCars();
    /*if (typeof window.ethereum !== 'undefined') {
        console.log('MetaMask is installed!');
        this.connect_metamask()
    }
    else{
      console.log("Metmask not installed")
    }*/
  }
};
</script>
<style>
.title-car{
    color: #000;
    text-transform: uppercase;
    font-size: 4rem;
    text-align: center;
    font-weight:bold;
    margin: 1rem 0;
}
.title-price{
    color: #0064BF;
    text-transform: uppercase;
    font-size: 6rem;
    text-align: center;
    font-weight:bold;
    margin: 1rem 0;
}
.tecnic-title{
  font-size:2.6rem!important;
  margin-bottom: 20px;
  font-weight: bold;
  margin-top: 40px;
}

.tecnic-list{
  font-size:2rem!important;
  padding-left: 10px;
}


.ul-tecnic{
  list-style: none;
}

.ul-tecnic li:before {
  content: '✓';
  padding-right: 20px;
}

.extra-card{
  min-width: 300px;
  height: 200px;
}
.extra-img{
        width: 100%;
        max-height: 200px;
        display: block; /* remove extra space below image */
        object-fit: cover;
}
.overlay-extra{
  background-color: rgba(0, 0, 0, 0.5);
}
.overlay-extra:hover{
  background-color: rgba(0, 0, 0, 0.1); 
  transition: 0.4s;

}

.overlay-text{
  color: white;
  margin-top: 12%;
}


</style>

