<template>
    <div id="user">
      <SideBar/>
      <router-view ></router-view>
    </div>
</template>
  
<script>
  /* eslint-disable */ 
  const ethers=require('ethers')
  import axios from "axios"
  
  import SideBar from '@/components/SidebarUser.vue'
  
  export default {
      name: "Admin",
      components: { 
          SideBar
      },
      data() {
          return {
              w3: web3,
              address_owner_end:'',
              address_owner_init:'',
              //notary_contract:undefined,
              wallet_manager:undefined,
              connected:false,
              //whitelist_addresses:"",
              //whitelisted:false,
              deploying:false,
              claiming:false,
              //file_hash:"", 
              adding:false,
              creating:false,
              created:false,
         
          }        
      },
      computed: {
          check_account_change: function(){
            console.log("checking change")
            window.ethereum.on('accountsChanged', function (accounts) {
                console.log("account changed")
          // Time to reload your interface with accounts[0]!
          })
        }
      },
      methods: {

        
          sleep: function(milliseconds){
              return new Promise(resolve => setTimeout(resolve, milliseconds))
          },
        popToast(type, message) {
            this.$bvToast.toast(message, {
                variant: type,
                solid: true,
                noCloseButton: true,
                toaster: 'b-toaster-top-right',                
            })
        },
  
      
    },
    mounted: function(){
      if (typeof window.ethereum !== 'undefined') {
          console.log('MetaMask is installed!');
          //this.connect_metamask()
      }
      else{
          console.log("Metamask not installed")
      }
    }
  };
  </script>
  <style>
     
  .title-car{
      color: #000;
      text-transform: uppercase;
      font-size: 4rem;
      text-align: center;
      font-weight:bold;
      margin: 1rem 0;
  }
  .title-price{
      color: #0064BF;
      text-transform: uppercase;
      font-size: 6rem;
      text-align: center;
      font-weight:bold;
      margin: 1rem 0;
  }
  .tecnic-title{
    font-size:2.6rem!important;
    margin-bottom: 20px;
    font-weight: bold;
    margin-top: 40px;
  }

  </style>
  
  