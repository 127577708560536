<template>

    <div class="col-lg-2 d-none d-lg-block pl-0">
        <div class="sidebar1">
            <div class="lista" role="tablist" id="sidebar-container" >
                <a href="https://kartek.ad/" class="d-none d-md-block" style="margin-right:30px" title="volver a pagina web">
                    <img id="nav-logo"  alt="kartek logo" src="/logo.svg">
                </a>
                <a href="https://kartek.ad/" class="d-md-none" title="volver a pagina web">
                    <img id="nav-logo" src="/logo.svg" alt="kartek logo">
                </a>  
                <router-link  :to="{ name: 'marca'}" class="lista-group-item">
                    <div class="nav-name">
                        <!--<font-awesome-icon icon="calendar-alt" class="icon2 mr-2"  />-->
                        <span>Marca</span>
                    </div>
                </router-link>
                <hr class="m-0">
                <router-link  :to="{ name: 'modelo'}"    class="lista-group-item ">
                    <div class="nav-name profile">
                        <!--<font-awesome-icon icon="calendar-alt" class="icon2 mr-2"  />-->
                        <span>Modelo</span>
                    </div>
                </router-link>
                <hr class="m-0">
                <router-link  :to="{ name: 'motor'}"   class="lista-group-item">
                    <div class="nav-name">
                        <!--<font-awesome-icon icon="calendar-alt" class="icon2 mr-2"  />-->
                        <span>Motor</span>
                    </div>
                </router-link>
                <hr class="m-0">
                <router-link  :to="{ name: 'variante'}"   class="lista-group-item">
                    <div class="nav-name">
                        <!--<font-awesome-icon icon="calendar-alt" class="icon2 mr-2"  />-->
                        <span>Variante</span>
                    </div>
                </router-link> 
                <hr class="m-0">
                <router-link  :to="{ name: 'serie'}"   class="lista-group-item">
                    <div class="nav-name">
                        <!--<font-awesome-icon icon="calendar-alt" class="icon2 mr-2"  />-->
                        <span>Eq.de serie</span>
                    </div>
                </router-link>
                <hr class="m-0">
                <router-link  :to="{ name: 'extra'}"   class="lista-group-item">
                    <div class="nav-name">
                        <!--<font-awesome-icon icon="calendar-alt" class="icon2 mr-2"  />-->
                        <span>Eq.extra</span>
                    </div>
                </router-link>
                <hr class="m-0">
                <router-link  :to="{ name: 'user'}"    class="lista-group-item ">
                    <div class="nav-name profile">
                        <!--<font-awesome-icon icon="calendar-alt" class="icon2 mr-2"  />-->
                        <span>Panel usuario</span>
                    </div>
                </router-link>
               
                <hr class="m-0">
                <a @click="log_out"  class="lista-group-item mt-auto">
                    <div class="nav-name text-red" >
                        <font-awesome-icon icon="power-off" class="icon2 red "  />
                    </div>
                </a>
                <!-- <div class="flag ">
                    <LanguageSwitcher />
                </div>-->
            </div>
           
        </div>
    </div>

</template>

<script lang="ts">
// @ is an alias to /src
//import LanguageSwitcher from '@/components/LanguageSwitcher.vue'


export default {
    name: "sidebar",
    components:{
  //      LanguageSwitcher
    },
    data(){
        return{
        }
    },
    methods:{
        /*blacklist_access_token():void {
            return new Promise((resolve, reject) => {
            axios.post('/api/tokens/blacklist_access_token')
                    .then((response) => {
                        this.status = response.data
                        if (this.status.result == "Ok") {
                            resolve(true)
                            return(true)
                            }
                        else{
                            this.popToast("danger", this.status.message, "Error")
                            reject(false)
                            return(false)
                            
                        }
                        }).catch((error) => {
                        console.log(error);
                    });
            })
        },*/

        log_out: function(){
           /* let resultb1=this.blacklist_access_token()
            let resultb2=this.blacklist_refresh_token()
            Promise.all([resultb1, resultb2]).then(data => {
                resultb1 = data[0]
                resultb2 = data[1]
                //console.log(resultb1, resultb2)
                if (resultb1==true && resultb2==true){
                    this.$router.push({ path: '/login' })
                }
            })*/
        },
    },
}
</script>

<style scoped>

  .sidebar1 {
   /* height: 100%;*/
    position: fixed;
    z-index: 1;
   top: 0px;
    left: 0;
    /*transition: 0.5s;*/
    min-width: 210px;
    background-color: white;
    -webkit-box-shadow: 0 0 15px 2px #4776e516;
    -moz-box-shadow: 0 0 15px 2px #4776e516;
    /*box-shadow: 0 0 10px 2px rgba(0,0,0,0.1);*/
    box-shadow:0 0 15px 2px #4776e516;
    border-radius:0 0 24px 0;
    min-height:80%;
  }
  .lista{
      /*height: 80vh;*/
      overflow-y: auto;
  }
  
  .lista-group-item{
        /*border-style: solid; 
        border-color: lightgrey;
        border-width:1px 1px 0 0;*/
        position: relative;
        display: block;

        height: 67px;
        color: #424242;
        padding:20px 15px;
         text-decoration: none;
         font-size:20px;
         
  }
  .lista-group-item:hover{
        background-color: rgba(38, 103, 247, 0.1);
        text-decoration: none;
        
    }
  
  .nav-disabled{
      color: lightgray!important;
      cursor: arrow;
  }
  
  .nav-disabled:hover{
    background-color: white!important;
  }
   
  .border-expanded{
      border-radius:0 7px 7px 0!important;
     /* padding-left: 20px!important;*/
  }
  

  .nav-name svg{
        width: 1.286em;
        padding-left: 0.25rem;
  }
  .nav-marked{
      background-color: rgba(38, 103, 247, 0.2)!important;
      color: #2667F7 !important;
  }
  .sidebar-logo{
    padding: 20px;
  }
  .icon2{
    margin-right:10px;
    color:#5b5b5c;
    height: 25px!important;
    width: 25px!important;

  }
  .red{color:#E00!important;}

.flag{
    position:absolute;
    bottom:10px;
    left:25%;
    font-size:20px;
    padding:0 20px;
}
</style>

