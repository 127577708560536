<template>
    <div>

        <div class="row justify-content-center mt-4" >
            <h1 class="tecnic-title">Clientes</h1>
        </div>
        <div class="row justify-content-center mt-4" >
            <button  class="bttn btn-primar"  @click="openNewCustomer()"> <font-awesome-icon icon="plus" class="mr-2"/>Nuevo cliente</button>
        </div>
        <div class="row justify-content-center mt-4" >
            <input type="text" class="form-input mb-2" style="max-width: 300px" v-model="searchText" placeholder="Buscar..." @keyup="search" autocomplete="disable">
        </div>
        <div class="row justify-content-center">
            <div class="spinner-table"  id="spin">
                <font-awesome-icon class="" icon="circle-notch"  spin/>
                <!--<img src="/logo-spinner.svg" alt="spinner"  id="nav-logo">-->
            </div>
        </div>
        <div class="row m-4" style="justify-content:right">
            <div class="col-lg-10 col-md-12 col-sm-12 text-center card card-shadow">
                <vuetable style="overflow-x: auto" ref="vuetable"
                            :css="css.table"
                            api-url="/api/customers/table/get"
                            :fields="fields"
                            data-path="data"
                            pagination-path="links.pagination"
                            :sort-order="sortOrder"
                            :show-sort-icons="true"
                            :per-page="10"
                            :append-params="moreParams"
                            :http-fetch="solveAuthIssuesOtherComponentsGet"
                            @vuetable:pagination-data="onPaginationData"
                            @vuetable:loading="onLoading"
                            @vuetable:loaded="onLoaded"
                            >
                            <div slot="name" slot-scope="props">
                                    <p>{{props.rowData.name}} </p>
                            </div>
                            <div slot="email" slot-scope="props">
                                    <p>{{props.rowData.email }} </p>
                            </div>
                            <div slot="phone" slot-scope="props">
                                    <p>{{props.rowData.phone }} </p>
                            </div>                          
                            <div slot="country" slot-scope="props">
                                    <p>{{props.rowData.country }}</p>
                            </div>
                            <div slot="edit" slot-scope="props">
                                <a class="hand" @click="openEditCustomer(props.rowData.id)">
                                    <font-awesome-icon  icon="pen" class="mr-2" />                                
                                </a> 
                            </div>                          
                            <div slot="delete" slot-scope="props">
                                <a class="hand" @click="deleteCustomer(props.rowData.id)">
                                    <font-awesome-icon icon="trash" class="mr-2 red" />
                                </a> 
                            </div>                                                                     
                </vuetable>
                <vuetable-pagination-info ref="paginationInfo"
                :info-template="pagination_info_template"
                ></vuetable-pagination-info>

                <vuetable-pagination ref="pagination"
                :css="css.pagination"
                @vuetable-pagination:change-page="onChangePage"
                ></vuetable-pagination>
            </div>
        </div>                       
        <!--<div class="row justify-content-center m-4" >
            <div class="col-lg-9 col-md-12 col-sm-12 text-center card card-shadow">
                <div class="row justify-content-center pt-4" style="color: #2667F7;border-bottom: 1px solid #2667F7; font-weight: bold;">                  
                    <p class="col-lg-2 col-md-12 col-sm-12">Nombre</p>                                    
                    <p class="col-lg-2 col-md-12 col-sm-12">Email</p>
                    <p class="col-lg-2 col-md-12 col-sm-12">Teléfono</p>
                    <p class="col-lg-2 col-md-12 col-sm-12">Pais</p>
                    <p class="col-lg-2 col-md-12 col-sm-12">Editar/Info</p>
                    <p class="col-lg-2 col-md-12 col-sm-12">Eliminar</p>
                </div>        
                  <div v-for="customer in customers" v-bind:key="customer.name" class="row justify-content-center">
                        <p  class="col-lg-2 col-md-12 col-sm-12">{{customer.name}}</p> 
                        <p  class="col-lg-2 col-md-12 col-sm-12">{{customer.email}}</p> 
                        <p  class="col-lg-2 col-md-12 col-sm-12">{{customer.phone}}</p> 
                        <p  class="col-lg-2 col-md-12 col-sm-12">{{customer.country.name}}</p> 

                        <a class="col-lg-2 col-md-12 col-sm-12 hand" @click="openEditCustomer(customer)">
                            <font-awesome-icon  icon="pen" class="mr-2" />                                
                        </a> 
                        <a class="col-lg-2 col-md-12 col-sm-12 hand" @click="deleteCustomer(customer.id)">
                            <font-awesome-icon v-if="deleting==true && delete_input==customer.id" icon="circle-notch" class="mr-2" spin />
                            <font-awesome-icon v-else icon="trash" class="mr-2" />
                        </a> 
                    </div>  
                </div>  
            </div>-->
            <b-modal id="newCustomer"  size="lg" hide-footer scrollable centered>
                <template #modal-header>
                    <a class="x" @click="$bvModal.hide('newCustomer')"><font-awesome-icon icon="times" /></a> 
                    <div class="col text-center">
                        <h3 class="bold m-0">Nuevo cliente</h3>
                    </div>                   
                </template>
                <div class="row">
                    <div class="col-12">
                        <p class="modal-label">Nombre</p>
                        <input class="form-input" type="text"  v-model="new_customer.name">

                        <p  class="modal-label">Email</p>
                        <input class="form-input" type="text"  v-model="new_customer.email">
                    
                        <p for="default_edit" class="modal-label">Teléfono</p>
                        <input class="form-input" type="text" v-model="new_customer.phone">

                        <p for="default_edit" class="modal-label">Número de identidad</p>
                        <input class="form-input" type="text" v-model="new_customer.id_number">

                        <p for="default_edit" class="modal-label">Dirección</p>
                        <input class="form-input" type="text" v-model="new_customer.address">

                        <p for="default_edit" class="modal-label">Pais</p>
                        <v-select :options="countries" label="name" v-model="new_customer.country" >
                        </v-select>                         
                        
                        <p for="default_edit" class="modal-label">Nombre del representante</p>
                        <input class="form-input" type="text" v-model="new_customer.rep_name">

                        <p for="default_edit" class="modal-label">Dirección del representante</p>
                        <input class="form-input" type="text" v-model="new_customer.rep_address">

                        <p for="default_edit" class="modal-label">ID del representante</p>
                        <input class="form-input" type="text" v-model="new_customer.rep_id">
                    </div>
                </div>
                <div class="text-center mt-4">
                    <a class="bttn btn-primar ml-2"  @click="addCustomer()">Guardar</a>
                    <a class="bttn btn-grey ml-2"  @click="$bvModal.hide('newCustomer')">Cerrar</a>
                </div>
            </b-modal>
            <b-modal id="editCustomerModal"  size="lg" hide-footer scrollable centered>
                <template #modal-header>
                    <a class="x" @click="$bvModal.hide('editCustomerModal')"><font-awesome-icon icon="times" /></a> 
                    <div class="col text-center">
                        <h3 class="bold m-0">Información del cliente</h3>
                    </div>                   
                </template>
                <div class="row">
                    <div class="col-12">
                        <p for="default_edit" class="modal-label">Nombre</p>
                        <input class="form-input" type="text" v-model="current_customer.name">

                        <p for="default_edit" class="modal-label">Email</p>
                        <input class="form-input" type="text" v-model="current_customer.email">
                    
                        <p for="default_edit" class="modal-label">Teléfono</p>
                        <input class="form-input" type="text" v-model="current_customer.phone">

                        <p for="default_edit" class="modal-label">Número de identidad</p>
                        <input class="form-input" type="text" v-model="current_customer.id_number">

                        <p for="default_edit" class="modal-label">Dirección</p>
                        <textarea class="form-input" type="text" v-model="current_customer.address"  rows="2"></textarea>

                        <p for="default_edit" class="modal-label">Pais</p>
                        <v-select :options="countries" label="name" v-model="current_customer.country" >
                        </v-select> 

                        <p for="default_edit" class="modal-label">Dirección ETH</p>
                        <input class="form-input" type="text" v-model="current_customer.eth_address">

                        <p for="default_edit" class="modal-label">Nombre del representante</p>
                        <input class="form-input" type="text" v-model="current_customer.rep_name">

                        <p for="default_edit" class="modal-label">Dirección del representante</p>
                        <input class="form-input" type="text" v-model="current_customer.rep_address">

                        <p for="default_edit" class="modal-label">ID del representante</p>
                        <input class="form-input" type="text" v-model="current_customer.rep_id">
                    </div>
                </div>
                <div class="text-center mt-4">
                    <a class="bttn btn-primar ml-2"  @click="editCustomer()">Guardar</a>
                    <a class="bttn btn-grey ml-2"  @click="$bvModal.hide('editCustomerModal')">Cerrar</a>
                </div>
            </b-modal>    
</div>
</template>

<script>
    import axios from "axios"
    import _ from 'underscore'
    
    import Vuetable from 'vuetable-2'

    import VuetablePagination from 'vuetable-2/src/components/VuetablePagination.vue'
    import VuetablePaginationInfo from 'vuetable-2/src/components/VuetablePaginationInfo.vue'

        export default {
            name: "customers",
            components: {
                'vuetable':Vuetable,
                'vuetable-pagination': VuetablePagination,
                'vuetable-pagination-info':VuetablePaginationInfo
            },
            data() {
                return {
                    new_customer:{
                        'name':'',
                        'email':'',
                        'phone':'',
                        'id_number':'',
                        'address':'',
                        'country':'',
                        'rep_name':'',
                        'rep_address':'',
                        'rep_id':''
                    },
                    current_customer:{
                        'name':'',
                        'email':'',
                        'phone':'',
                        'id_number':'',
                        'address':'',
                        'country':'',
                        'id':undefined,
                        'eth_address':undefined, 
                        'rep_name':'',
                        'rep_address':'',
                        'rep_id':''
                    },
                    customers:[],
                    countries:[],
                    loading_customers:false,
                    editing:false,
                    adding:false,
                    
                    deleting:false,
                    delete_input:undefined,
                    pagination_info_template: "Mostrando coches del {from} a {to} de {total}",
                    moreParams: {},
                    searchText: "",
                    fields: [
                    {
                            name: 'name',
                            title: 'Nombre',
                            sortField: 'name'
                        },
                        {
                            name: 'email',
                            title: 'Email',
                            sortField: 'email'
                        },
                        {
                            name: "phone",
                            title: 'Teléfono',
                            sortField: 'phone'
                        },
                        {
                            name: "country",
                            title: 'Pais',
                            sortField: 'country'
                        },
                        {
                            name: "edit",
                            title: 'Editar/Info',
                        },                      
                        {
                            name: "delete",
                            title: 'Eliminar',
                        },
                    ],
                    sortOrder: [
                    {field: 'name', direction: 'desc'},
                        {field: 'email', direction: 'desc'},
                        {field: 'phone', direction: 'desc'},
                        {field: 'country', direction: 'desc'}
                    ],
                    css: {
                        table: {
                            tableClass: 'table table-hovered',
                            loadingClass: 'loadingContent',
                            ascendingIcon: 'fa fa-sort-up',
                            descendingIcon: 'fa fa-sort-down',
                            ascendingClass: 'sorted-asc',
                            descendingClass: 'sorted-desc',
                            sortableIcon: 'fa fa-sort',
                            handleIcon: 'fa fa-bars',
                        },
                        pagination: {
                            infoClass: 'pull-left',
                            wrapperClass: 'vue-pagination',
                            activeClass: 'active',
                            disabledClass: 'disabled',
                            pageClass: 'btn btn-page',
                            linkClass: 'btn btn-arrow ',
                            icons: {
                                first: '',
                                prev: '',
                                next: '',
                                last: '',
                            },
                        }
                    },
                    styles: {
                        backgroundColor: this.color,
                        width: this.width + 'px',
                        height: this.height + 'px',
                    },

                }
            },
            methods:{
               /* getCustomers(){
                    this.loading_customers=true
                    axios.post('/api/customers/get_all')
                        .then((result) => {
                            console.log("result",result)
                            if (result.data.resultado === "Ok"){
                                this.customers=result.data.customers
                                console.log("customers:",this.customers)
                                this.loading_customers=false
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                                this.loading_customers=false
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.loading_customers=false
                        });
                },*/
              
                getCountries(){
                    axios.get('/api/countries/get_all')
                        .then((result) => {
                            console.log("result",result)
                            if (result.data.resultado === "Ok"){
                                this.countries=result.data.countries
                                console.log("countries:",this.countries)
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                            }
                        }).catch((error) => {
                            console.log(error);
                        });
                },
                addCustomer(){
                    this.adding=true,
                    axios.post('/api/customers/add', {
                        "name":this.new_customer.name,
                        "phone":this.new_customer.phone,
                        "email":this.new_customer.email,
                        "id_number":this.new_customer.id_number,
                        "address":this.new_customer.address,
                        "country_id":this.new_customer.country.id,
                        "rep_name":this.new_customer.rep_name,
                        "rep_address":this.new_customer.rep_address,
                        "rep_id":this.new_customer.rep_id,
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.$nextTick(() => this.$refs.vuetable.refresh())
                            this.adding=false;
                            this.new_customer={
                                'name':'',
                                'email':'',
                                'phone':'',
                                'id_number':'',
                                'address':'',
                                'country':'',
                                'rep_name':'',
                                'rep_address':'',
                                'rep_id':''
                            }
                            this.$bvModal.hide('newCustomer')
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.adding=false;
                            
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },


                editCustomer(){
                    this.editing=true,
                    axios.post('/api/customers/edit', {
                        "customer_id": this.current_customer.id,
                        "new_name":this.current_customer.name,
                        "new_phone":this.current_customer.phone,
                        "new_email":this.current_customer.email,
                        "new_id_number":this.current_customer.id_number,
                        "new_address":this.current_customer.address,
                        "new_country_id":this.current_customer.country.id,
                        "new_eth_address":this.current_customer.eth_address,
                        "new_rep_name":this.current_customer.rep_name,
                        "new_rep_address":this.current_customer.rep_address,
                        "new_rep_id":this.current_customer.rep_id,
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.$nextTick(() => this.$refs.vuetable.refresh())
                            this.editing=false
                            this.$bvModal.hide('editCustomerModal')
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.editing=false;
                            
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                },

                deleteCustomer(customer_id){
                    this.deleting=true,
                    this.delete_input=customer_id
                    axios.post('/api/customers/delete', {
                        "customer_id": customer_id,
                    })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.$nextTick(() => this.$refs.vuetable.refresh())                          
                            this.deleting=false
                            this.delete_input=undefined
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.deleting=false;
                            
                        }
                    }).catch((error) => {
                        console.log(error);
                    });

                },
                openNewCustomer(){
                    //this.default_editing=extra_equip
                   // this.getCurrentCustomer()
                   this.new_customer={
                                'name':'',
                                'email':'',
                                'phone':'',
                                'id_number':'',
                                'address':'',
                                'country':'',
                                'rep_name':'',
                                'rep_address':'',
                                'rep_id':''
                            }
                    this.$bvModal.show('newCustomer')
                },

                openEditCustomer(customer_id){
                   this.loading_customer=true
                   axios.post('/api/customers/get', {
                        "customer_id":customer_id
                    })
                        .then((result) => {
                            console.log("result",result)
                            if (result.data.resultado === "Ok"){
                                this.current_customer=result.data.customer_info
                                this.loading_customer=false
                                console.log('current_customer',this.current_customer)
                                this.$bvModal.show('editCustomerModal')
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                                this.loading_customer=false
                            }
                        }).catch((error) => {
                            console.log(error);
                            this.loading_customer=false
                        });
                    
                    
                },
                onPaginationData (paginationData) {
                    this.$refs.pagination.setPaginationData(paginationData)
                    this.$refs.paginationInfo.setPaginationData(paginationData)
                    let pagina_Actual= (this.$refs.paginationInfo.tablePagination.current_page)
                    //añadimos eliminamos clase active para resalta pagina actual
                    this.sleep(100).then(() => {
                        let elemento_paginacion = document.getElementsByClassName("vue-pagination")
                        let tags = elemento_paginacion[0].getElementsByTagName("a")

                        for (let item of tags) {
                            if (item.innerHTML == pagina_Actual) {
                                //console.log(item.innerHTML)
                                item.classList.add("active");
                            }
                            else{
                                item.classList.remove("active");
                            }
                            //console.log(item.innerHTML);
                        }
                    })
                },
                onChangePage (page) {
                this.$refs.vuetable.changePage(page)
                },

                onLoading() {
                    console.log('cargando')
                    let spin=document.getElementById('spin');
                    let tablebody=document.getElementsByClassName('vuetable-body')[0];
                    spin.classList.remove('d-none'); //vuetable-body
                    tablebody.classList.add('opacity'); //vuetable-body
                    //console.log('Cargando .. show your spinner here')
                },
                onLoaded() {
                    console.log("cargado")
                    //this.loading=false
                    let spin=document.getElementById('spin');
                    let tablebody=document.getElementsByClassName('vuetable-body')[0];
                    tablebody.classList.remove('opacity');
                    spin.classList.add('d-none');                    
                    //console.log('Cargado .. hide your spinner here')
                },
                search: _.debounce(function () {
                    this.moreParams = {
                        'filter': this.searchText,
                        "sold_status":false
                    };
                    this.$nextTick(() => this.$refs.vuetable.refresh())

                }, 400),

                sleep: function(milliseconds){
                    return new Promise(resolve => setTimeout(resolve, milliseconds))
                },  
                popToast(type, message) {
                    this.$bvToast.toast(message, {
                        //title: title,
                        variant: type,
                        solid: true,
                        toaster: 'b-toaster-top-center',
                    })
                },
        }, 
        
        mounted(){
            //this.getCustomers()
            this.getCountries()
        },
    }
    </script>
    <style scoped>
    .image{
        max-width: 300px;
        max-height: 200px;
    }

    </style>