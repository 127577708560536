<template>
    <div id="admin">
        <div class="row justify-content-center mt-4" >
            <h1 class="tecnic-title" id="contact"> Añadir variante</h1>
        </div>
        <div class="row justify-content-center mt-4" >
            <div class="col-lg-4 col-md-6 col-sm-12 mb-1 text-center card card-shadow">
                <div class="card-body">                
                    <h3 for="marca"  class="text-left">Marca</h3>
                    <p v-if="loading_brands==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                    <v-select v-else :options="brands" label="name" :reduce="brands => brands.id" v-model="selected_brand" @input="getModels()">
                    </v-select> 

                    <h3 for="modelo"  class="text-left">Modelo</h3>
                    <p v-if="loading_models==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                    <v-select v-else :options="models" label="name" :reduce="models => models.id" v-model="selected_model" @input="getEngines()">
                    </v-select> 

                    <h3 for="modelo" class="text-left">Motor</h3>
                    <p v-if="loading_engines==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                    <v-select v-else :options="engines" label="name"  v-model="selected_engine" :reduce="engines => engines.id" @input="getVariants()">
                    </v-select>

                    <h3 for="variante" class="text-left">Variante</h3>
                    <p v-if="loading_variants==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                    <v-select v-else :options="filtered_variants" label="name" v-model="new_variant_name" @search="filter_variants">
                    </v-select> 
                    <h3 for="potencia" class="text-left">Potencia</h3>
                    <input class="form-input" type="text" id="potencia" name="potencia" v-model="new_horsepower">
                    
                    <h3 for="motor"  class="text-left">Caja de cambios</h3>
                    <!--<input class="form-input" type="text" id="motor" name="new_fueltype">-->
                    <p v-if="loading_gears==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                    <v-select :options="gear_boxes" label="name"  v-model="selected_gearbox">
                    </v-select>

                    <h3 for="motor"  class="text-left">Tracción</h3>
                    <!--<input class="form-input" type="text" id="motor" name="new_fueltype">-->
                    <p v-if="loading_traction==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                    <v-select :options="tractions" label="name" v-model="selected_traction">
                    </v-select>

                    <button v-if="adding" class="bttn btn-primar addbtn disabled"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Añadir</button>
                    <button v-else class="bttn btn-primar addbtn"  @click="addVariant()"> <font-awesome-icon icon="plus" class="mr-2"/> Añadir</button>
                </div>
            </div>
        </div>
        <div class="row justify-content-center mt-4" >
                <h1 class="tecnic-title">Variantes</h1>
            </div>
              
            <div class="row justify-content-center m-4" >
                <div class="col-lg-6 col-md-12 col-sm-12 text-center card card-shadow">
                    <div class="row justify-content-center pt-4" style="color: #2667F7;border-bottom: 1px solid #2667F7; font-weight: bold;">                  
                        <p class="col-lg-2 col-md-12 col-sm-12">Nombre</p>
                       
                        
                        <p class="col-lg-2 col-md-12 col-sm-12">Potencia</p>
                        <p class="col-lg-2 col-md-12 col-sm-12">Caja de cambios</p>
                        <p class="col-lg-2 col-md-12 col-sm-12">Tracción</p>
                        <p class="col-lg-2 col-md-12 col-sm-12">Editar</p>
                        <p class="col-lg-2 col-md-12 col-sm-12">Eliminar</p>
                    </div>        
                    <div v-for="variant in variants" v-bind:key="variant.id" class="row justify-content-center">                        
                        <div  class="col-lg-2 col-md-12 col-sm-12">
                            <p>{{variant.name}} </p> 
                        </div> 
                        <div  class="col-lg-2 col-md-12 col-sm-12">
                            <p>{{variant.horse_power}} </p> 
                        </div> 
                        <div  class="col-lg-2 col-md-12 col-sm-12">
                            <p>{{variant.gearbox.name}} </p> 
                        </div> 
                        <div  class="col-lg-2 col-md-12 col-sm-12">
                            <p>{{variant.traction.name}} </p> 
                        </div> 
                        <div  class="col-lg-2 col-md-12 col-sm-12">
                            <a  class="hand" @click="openEditModal(variant)">
                                <font-awesome-icon  icon="pen" class="mr-2" />                                
                            </a> 
                        </div> 
                        <div  class="col-lg-2 col-md-12 col-sm-12">
                            <a class="hand" @click="deleteVariant(variant.id)">
                                <font-awesome-icon v-if="deleting==true && delete_input==variant.id" icon="circle-notch" class="mr-2" spin />
                                <font-awesome-icon v-else icon="trash" class="mr-2" />
                            </a> 
                        </div> 
                    </div>  
                </div>
            </div>
            <b-modal id="editVariantModal"  size="lg" hide-footer scrollable centered>
                <template #modal-header>
                    <a class="x" @click="$bvModal.hide('editVariantModal')"><font-awesome-icon icon="times" /></a> 
                    <div class="col text-center">
                        <h3 class="bold m-0">Editar variante</h3>
                    </div>                   
                </template>
                <div class="row">
                    <div class="col-12">
                        <p class="modal-label">Nombre</p>
                        <input class="form-input" type="text" v-model="current_variant.name">

                        <p class="modal-label">Potencia</p>
                        <input class="form-input" type="text" v-model="current_variant.horse_power">

                        <p class="modal-label">Caja de cambios</p>
                        <p v-if="loading_gears==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                        <v-select :options="gear_boxes" label="name" :reduce="gear_boxes => gear_boxes.id" v-model="current_variant.gearbox">
                        </v-select>

                        <p class="modal-label">Tracción</p>
                        <p v-if="loading_traction==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                        <v-select :options="tractions" label="name" :reduce="tractions => tractions.id" v-model="current_variant.traction">
                        </v-select>
               
                    </div>
               
                </div>
                <div class="text-center mt-4">
                    <a class="bttn btn-primar ml-2"  @click="editVariant()">Guardar</a>
                    <a class="bttn btn-grey ml-2"  @click="$bvModal.hide('editVariantModal')">Cerrar</a>
                </div>
            </b-modal>    
    </div>
</template>

<script>
import _ from 'underscore'

    //import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
    //import ToTop from '@/components/ToTop.vue'
import axios from "axios"
    export default {
        name: "variante",
        components: {
            //LanguageSwitcher,
            //ToTop
        },
        data() {
            return {
                loading_brands:false,
                loading_models:false,
                loading_variants:false,
                loading_engines:false,
                loading_gears:false,
                loading_traction:false,
                adding:false,
                brands:[],
                models:[],
                engines:[],
                filtered_variants:[],
                variants:[],
                gear_boxes:[],
                tractions:[],
                selected_brand:{"name":"", "id":null},
                selected_model:{"name":"", "id":null},
                selected_engine:{"name":"", "id":null},
                selected_gearbox:{"name":"", "id":null},
                selected_traction:{"name":"", "id":null},
               current_variant:{
                'name':'',
                'horse_power':'',
                'gearbox':'',
                'traction':'',
                'id':null
               },

                editing:false,
                delete_input:undefined,
                deleting:false,
                new_variant_name:'',
                new_horsepower:''
            }
        },
        methods:{
            getBrands(){
                axios.get('/api/brands/get')
                .then((result) => {
                    console.log("result",result)
                    if (result.data.resultado === "Ok"){
                        this.brands=result.data.brands
                        console.log("brands:",this.brands)
                    }
                    else if (result.data.resultado === "Error"){
                        this.popToast("danger", result.data.mensaje, "Error")
                    }
                }).catch((error) => {
                    console.log(error);
                });
            },
            getModels(){
                this.selected_model={"name":"", "id":null}
                this.selected_engine={"name":"", "id":null}
                this.loading_models = true;         
                this.models=[]
                this.engines=[]
                this.variants=[]
                axios.post('/api/brands/get_models', {
                    "brand_id":this.selected_brand
                })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.models=result.data.models
                            console.log(result.data.models)
                            this.loading_models = false;
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.loading_models = false;
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.loading_models = false;                        
                    });
            },
            getEngines(){
                this.loading_engines = true;         
                axios.post('/api/models/get_engines', {
                    "model_id":this.selected_model
                })
                    .then((result) => {
                        console.log(result)
                        if (result.data.resultado === "Ok"){
                            this.engines=result.data.engines
                            console.log(result.data.engines)
                            this.loading_engines = false;
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.loading_engines = false;
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.loading_engines = false;                        
                    });
            },
            getVariants(){
                this.loading_variants = true;         
                axios.post('/api/engines/get_variants', {
                    "engine_id":this.selected_engine
                })
                .then((result) => {
                    console.log(result)
                    if (result.data.resultado === "Ok"){
                        this.variants=result.data.variants
                        this.filtered_variants=result.data.variants
                        console.log(result.data.variants)
                        this.loading_variants = false;
                    }
                    else if (result.data.resultado === "Error"){
                        this.popToast("danger", result.data.mensaje, "Error")
                        this.loading_variants = false;
                    }
                }).catch((error) => {
                    console.log(error);
                    this.loading_variants = false;                        
                });
            },
            getGearBoxes(){
                axios.get('/api/gear_boxes/get')
                .then((result) => {
                    console.log("result",result)
                    if (result.data.resultado === "Ok"){
                        this.gear_boxes=result.data.gear_boxes
                        console.log("gear_boxes:",this.gear_boxes)
                    }
                    else if (result.data.resultado === "Error"){
                        this.popToast("danger", result.data.mensaje, "Error")
                    }
                }).catch((error) => {
                    console.log(error);
                });
            },
            getTractions(){
                axios.get('/api/tractions/get')
                .then((result) => {
                    console.log("result",result)
                    if (result.data.resultado === "Ok"){
                        this.tractions=result.data.tractions
                        console.log("tractions:",this.tractions)
                    }
                    else if (result.data.resultado === "Error"){
                        this.popToast("danger", result.data.mensaje, "Error")
                    }
                }).catch((error) => {
                    console.log(error);
                });
            },
            filter_variants: _.debounce(function(search, loading_variants){
                //let vm=this
                this.new_variant={"name":search, "id":null}
                if (search!=""){
                    loading_variants(true)
                    axios.post('/api/variants/filter',{
                        'filter_by': search,
                        'engine_id':this.selected_engine
                    }) 
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                                this.filtered_variants=result.data.variants
                                this.filtered_variants.push({"name":search})
                                console.log(result.data.variants)
                                loading_variants(false)
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                                loading_variants(false)
                            }
                    }).catch((error) => {
                        console.log(error);
                        loading_variants(false)                   
                    });
                }
            }, 500),
            addVariant(){
                this.adding=true,
                axios.post('/api/variants/add', {
                    "horsepower":this.new_horsepower,
                    "variant_name":this.new_variant_name.name,
                    "engine_id":this.selected_engine,
                    "gearbox_id":this.selected_gearbox.id,
                    "traction_id":this.selected_traction.id,
                })
                .then((result) => {
                    if (result.data.resultado === "Ok"){
                        this.popToast("success", result.data.mensaje, "Ok")
                        this.getVariants()
                        this.adding=false;
                    }
                    else if (result.data.resultado === "Error"){
                        this.popToast("danger", result.data.mensaje, "Error")
                        this.adding=false;
                    }
                }).catch((error) => {
                    console.log(error);
                    this.adding=false;
                });
            },
            openEditModal(variant){
                this.new_variant=""
                this.new_horsepower=undefined
                this.current_variant=variant
                this.$bvModal.show('editVariantModal')
                console.log('click',  variant)                           
            },
            editVariant(){
               //this.end=false
                this.editing=1                            
                axios.post('/api/variants/edit', {
                    "new_variant_name":this.current_variant.name,
                    "variant_id":this.current_variant.id,
                    "new_horsepower":this.current_variant.horse_power,
                    "gearbox_id":this.current_variant.gearbox.id,
                    "traction_id":this.current_variant.traction.id,
                })
                .then((result) => {
                    console.log("result",result)
                    if (result.data.resultado === "Ok"){
                        this.popToast("success", result.data.mensaje, "Ok")
                        this.getVariants()
                        this.editing=2;
                        this.$bvModal.hide('editVariantModal')
                    }
                    else if (result.data.resultado === "Error"){
                        this.popToast("danger", result.data.mensaje, "Error")
                        this.editing=2;
                    }
                }).catch((error) => {
                    console.log(error);
                    this.editing=2;
                });
            

            },
            deleteVariant(id){
                    this.delete_input=id
                    this.deleting=true,
                    axios.post('/api/variants/delete', {
                        "variant_id":id
                    })
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.getVariants()
                            this.deleting=false;
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.deleting=false;
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.deleting=false;
                    });
                },
            popToast(type, message) {
                this.$bvToast.toast(message, {
                    variant: type,
                    solid: true,
                    toaster: 'b-toaster-top-center',
                })
            },
        }, 
        mounted(){
            this.getBrands()
            this.getGearBoxes()
            this.getTractions()
        },
    }
    </script>
<style scoped>

</style>