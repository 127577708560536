<template>
    <div id="admin">
        <div class="row justify-content-center mt-4" >
            <h1 class="tecnic-title" id="contact"> Añadir motor</h1>
        </div>
        <div class="row justify-content-center mt-4" >
            <div class="col-lg-4 col-md-6 col-sm-12 mb-1 text-center card card-shadow">
                <div class="card-body">                
                    <h3 for="marca" class="text-left">Marca</h3>
                    <p v-if="loading_brands==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                    <v-select v-else :options="brands" label="name" :reduce="brands => brands.id" v-model="selected_brand" @input="getModels()">
                    </v-select> 
                    <h3 for="modelo" class="text-left">Modelo</h3>
                    <p v-if="loading_models==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                    <v-select v-else :options="models" label="name" :reduce="models => models.id" v-model="selected_model"  @input="getEngines()">
                    </v-select>
                   
                    <h3 for="motor"  class="text-left">Motor</h3>
                    <p v-if="loading_engines==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                    <v-select v-else :options="filtered_engines" label="name" v-model="new_engine" @search="filter_engines">
                    </v-select> 
                    <h3 for="motor"  class="text-left">Tipo combustible</h3>
                    <!--<input class="form-input" type="text" id="motor" name="new_fueltype">-->
                    <p v-if="loading_fuels==true"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Cargando...</p>
                    <v-select :options="fuel_types" label="name" :reduce="fuel_types => fuel_types.id" v-model="selected_fueltype">
                    </v-select>

                    <button v-if="adding" class="bttn btn-primar addbtn disabled"><font-awesome-icon icon="circle-notch" class="mr-2" spin />Añadir</button>
                    <button v-else class="bttn btn-primar addbtn"  @click="addEngine()"> <font-awesome-icon icon="plus" class="mr-2"/> Añadir</button>
                </div>
            </div>
        </div>
        <div class="row justify-content-center mt-4" >
                <h1 class="tecnic-title">Motores</h1>
            </div>
              
            <div class="row justify-content-center m-4" >
                <div class="col-lg-6 col-md-12 col-sm-12 text-center card card-shadow">
                    <div class="row justify-content-center pt-4" style="color: #2667F7;border-bottom: 1px solid #2667F7; font-weight: bold;">                  
                        <p class="col-lg-4 col-md-12 col-sm-12">Motor</p>
                       
                        
                        <p class="col-lg-4 col-md-12 col-sm-12">Combustible</p>
                        <p class="col-lg-2 col-md-12 col-sm-12">Editar</p>
                        <p class="col-lg-2 col-md-12 col-sm-12">Eliminar</p>
                    </div>        
                    <div v-for="engine in engines" v-bind:key="engine.id" class="row justify-content-center">                        
                        <div  class="col-lg-4 col-md-12 col-sm-12">
                            <input v-if="edit_input==engine.id && editing==0" class="form-input text-center mb-0"   type="text" v-model="new_engine_name">
                            <p v-else-if="edit_input==engine.id && editing==1" class="form-input"><font-awesome-icon  icon="circle-notch" class="mr-2" spin /></p>                                                
                            <p v-else>{{engine.name}} </p> 
                        </div> 
                        <div  class="col-lg-4 col-md-12 col-sm-12">
                            <v-select  v-if="edit_input==engine.id && editing==0" :options="fuel_types" label="name" :reduce="fuel_types => fuel_types.id" v-model="new_fueltype">
                            </v-select>
                            <p v-else-if="edit_input==engine.id && editing==1" class="form-input"><font-awesome-icon  icon="circle-notch" class="mr-2" spin /></p>                                                
                            <p v-else >{{engine.fuel_type.name}} </p> 
                        </div> 
                        <div  class="col-lg-2 col-md-12 col-sm-12">
                            <a v-if="edit_input==engine.id && editing==0" class="hand" @click="editEngine(engine)">
                                <font-awesome-icon icon="check" class="mr-2 mt-2" style="color:green"/>                                
                            </a> 
                            <a v-else class="hand" @click="initEdit(engine)">
                                <font-awesome-icon v-if="edit_input==engine.id && editing==1" icon="circle-notch" class="mr-2" spin />
                                <font-awesome-icon v-else icon="pen" class="mr-2" />                                
                            </a> 
                        </div> 
                        <div  class="col-lg-2 col-md-12 col-sm-12">
                            <a class="hand" @click="deleteEngine(engine.id)">
                                <font-awesome-icon v-if="deleting==true && delete_input==engine.id" icon="circle-notch" class="mr-2" spin />
                                <font-awesome-icon v-else icon="trash" class="mr-2" />
                            </a> 
                        </div> 
                    </div>  
                </div>
            </div>  
    </div>
</template>
<script>

    //import LanguageSwitcher from '@/components/LanguageSwitcher.vue'
    //import ToTop from '@/components/ToTop.vue'
import axios from "axios"
import _ from 'underscore'

    export default {
        name: "motor",
        components: {
            //LanguageSwitcher,
            //ToTop
        },
        data() {
            return {
                loading_brands:false,
                loading_models:false,
                loading_fuels:false,
                loading_engines:false,
                
                adding:false,
                new_engine:'',
                deleting:false,
                editing:2,
               
                new_engine_name:'',
                new_fueltype:undefined,
                edit_input:undefined,
                edit_input_fuel:undefined,
                brands:[],
                models:[],
                engines:[],
                fuel_types:[],
                filtered_engines:[],
                selected_brand:{"name":"", "id":null},
                selected_model:{"name":"", "id":null},
                selected_fueltype:{"name":"", "id":null}
            //showCollapse: false
            }
        },
        methods:{
            getModels(){
                this.selected_model={"name":"", "id":null}
                axios.post('/api/brands/get_models', {
                    "brand_id":this.selected_brand
                })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.models=result.data.models
                            console.log(result.data.models)
                            this.loading = false;
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
            },
            getEngines(){
                this.loading_engines = true;
                //this.selected_engine=""
                axios.post('/api/models/get_engines', {
                    "brand_id":this.selected_brand,
                    "model_id":this.selected_model
                })
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                            this.engines=result.data.engines
                            this.filtered_engines=result.data.engines
                            console.log(result.data.engines)
                            this.loading_engines = false;
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.loading_engines = false;
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.loading_engines = false;
                    });
            },

            getBrands(){
                axios.get('/api/brands/get')
                .then((result) => {
                    console.log("result",result)
                    if (result.data.resultado === "Ok"){
                        this.engines=[]
                        this.brands=result.data.brands
                        console.log("brands:",this.brands)
                    }
                    else if (result.data.resultado === "Error"){
                        this.popToast("danger", result.data.mensaje, "Error")
                    }
                }).catch((error) => {
                    console.log(error);
                });
            },

            getFuels(){
                axios.get('/api/fuels/get')
                .then((result) => {
                    console.log("result",result)
                    if (result.data.resultado === "Ok"){
                        this.fuel_types=result.data.fuel_types
                        console.log("fuels:",this.fuel_types)
                    }
                    else if (result.data.resultado === "Error"){
                        this.popToast("danger", result.data.mensaje, "Error")
                    }
                }).catch((error) => {
                    console.log(error);
                });
            },

            addEngine(){
                this.adding=true,
                axios.post('/api/engines/add', {
                    "engine_name":this.new_engine.name,
                    "brand_id":this.selected_brand,
                    "model_id":this.selected_model,
                    "fuel_type_id":this.selected_fueltype
                })
                .then((result) => {
                    if (result.data.resultado === "Ok"){
                        this.popToast("success", result.data.mensaje, "Ok")
                        this.getEngines()
                        this.adding=false;
                    }
                    else if (result.data.resultado === "Error"){
                        this.popToast("danger", result.data.mensaje, "Error")
                        this.adding=false;
                    }
                }).catch((error) => {
                    console.log(error);
                    this.adding=false;
                });
            },
            filter_engines: _.debounce(function(search, loading_engines){
                //let vm=this
                if (search!=""){
                    loading_engines(true)
                    axios.post('/api/engines/filter',{
                        'filter_by': search,
                        'model_id':this.selected_model
                    }) 
                    .then((result) => {
                        if (result.data.resultado === "Ok"){
                                this.filtered_engines=result.data.engines
                                this.filtered_engines.push({"name":search})
                                console.log(result.data.engines)
                                loading_engines(false)
                            }
                            else if (result.data.resultado === "Error"){
                                this.popToast("danger", result.data.mensaje, "Error")
                                loading_engines(false)
                            }
                    }).catch((error) => {
                        console.log(error);
                        loading_engines(false)                   
                    });
                }
            }, 500),
            initEdit(engine){
                this.new_engine_name=""
                this.new_fueltype=undefined
                this.edit_input=engine.id
                this.editing=0  
                console.log('click',  engine)                           
            },
            editEngine(engine){
                this.editing=1
                if(this.new_fueltype==undefined && this.new_engine_name==''){
                    this.popToast("info", "Introduce al menos un nuevo valor")
                    this.editing=2;
                }
                else{
                    if (this.new_fueltype==undefined){
                    this.new_fueltype==engine.fuel_type.id
                    }
                    else if(this.new_engine_name==''){
                        this.new_engine_name==engine.name
                    }                            
             
                    axios.post('/api/engines/edit', {
                        "new_engine_name":this.new_engine_name,
                        "engine_id":engine.id,
                        "model_id":this.selected_model,
                        "fuel_type_id":this.new_fueltype
                    })
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.getEngines()
                            this.editing=2;
                            //this.end=true
                        
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.editing=2;
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.editing=2;
                    });
                }
            },
            deleteEngine(id){
                    this.delete_input=id
                    this.deleting=true,
                    axios.post('/api/engines/delete', {
                        "engine_id":id
                    })
                    .then((result) => {
                        console.log("result",result)
                        if (result.data.resultado === "Ok"){
                            this.popToast("success", result.data.mensaje, "Ok")
                            this.getEngines()
                            this.deleting=false;
                        }
                        else if (result.data.resultado === "Error"){
                            this.popToast("danger", result.data.mensaje, "Error")
                            this.deleting=false;
                        }
                    }).catch((error) => {
                        console.log(error);
                        this.deleting=false;
                    });
                },
            popToast(type, message) {
                this.$bvToast.toast(message, {
                    //title: title,
                    variant: type,
                    solid: true,
                    toaster: 'b-toaster-top-center',
                })
            },
        }, 
        mounted(){
            this.getBrands()
            this.getFuels()
        },
    }
    </script>
<style scoped>

</style>