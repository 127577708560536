import VueRouter from 'vue-router'
import Car from '../views/Car.vue'
import Admin from '../views/Admin.vue'
import User from '../views/User.vue'
import Login from '../views/Login.vue'

import Marca from '../views/Admin/Marca.vue'
import Modelo from '../views/Admin/Modelo.vue'
import Motor from '../views/Admin/Motor.vue'
import Variante from '../views/Admin/Variante.vue'
import Serie from '../views/Admin/EqSerie.vue'
import Especial from '../views/Admin/EqEspecial.vue'

import Proveedores from '../views/User/Proveedores.vue'
import Clientes from '../views/User/Clientes.vue'
import CochesStock from '../views/User/CochesStock.vue'
import CochesVendidos from '../views/User/CochesVendidos.vue'
import Empleados from '../views/User/Empleados.vue'


const routes = [
    {
        path: '/',
        redirect: 'login',
    }, 
    {
        path: '/car',
        component: Car,
        name: 'car',
    },
    {
        path: '/admin',
        component: Admin,
        name: 'admin',
        children: [
            {
              path: 'marca',
              component: Marca,
              name: 'marca'
            },
            {
                path: 'modelo',
                component: Modelo,
                name: 'modelo'
              },
              {
                path: 'motor',
                component: Motor,
                name: 'motor'
              },
              {
                path: 'variante',
                component: Variante,
                name: 'variante'
              },
              {
                path: 'extra',
                component: Especial,
                name: 'extra'
              },
              {
                path: 'serie',
                component: Serie,
                name: 'serie'
              },
        ]
    },
    {
      path: '/user',
      component: User,
      redirect:'user/coches_stock',
      name: 'user',
      children: [
          {
              path: 'proveedores',
              component: Proveedores,
              name: 'proveedores'
          },
          {
            path: 'clientes',
            component: Clientes,
            name: 'clientes'
          },
          {
            path: 'coches_stock',
            component: CochesStock,
            name: 'coches_stock'
          },
          {
            path: 'coches_vendidos',
            component: CochesVendidos,
            name: 'coches_vendidos'
          },
          {
            path: 'empleados',
            component: Empleados,
            name: 'empleados'
          }
        ]
    },
    {
        path: '/login',
        component: Login,
        name: 'login',
    }
    
   
]

const router = new VueRouter({
    mode: 'history',
    routes
  })
  
  export default router
  
